import { Avatar } from '@src/modules/shared/components/Avatar'
import { store } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Skeleton, Tooltip } from 'antd'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'
import { ReactComponent as ShareSVG } from '../assets/icons/table/Share.svg'
import { renderPath } from '@src/modules/spaces/components/Header/Header'
import { formatDateToMultiLang } from '@src/modules/tasks/utils/formateDateToMultiLang'

const TableSkeleton = ({ children, data }) =>
  data?.isSkeleton ? <Skeleton title={true} paragraph={false} /> : children

export const getDocsColumns = (
  t: TFunction<[string], undefined>,
  navigate: NavigateFunction,
  lang: string,
) => {
  const format = lang == 'ar' ? 'DD MMMM YYYY' : 'MMMM  DD , YYYY'
  return [
    {
      title: t('table.name'),
      key: 'name',
      sorter: true,
      render: (data) => {
        return (
          <TableSkeleton data={data}>
            <div className="document-name" onClick={() => navigate(`/spaces/document/${data.id}`)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5.55533 18.9738H14.4446C16.1775 18.9738 17.0396 18.0948 17.0396 16.3538V8.75342C17.0396 7.67342 16.9225 7.20484 16.2528 6.51842L11.6321 1.8227C10.9964 1.16949 10.4771 1.02734 9.5314 1.02734H5.55533C3.83104 1.02734 2.96033 1.91449 2.96033 3.65592V16.3538C2.96033 18.1031 3.83104 18.9738 5.55533 18.9738ZM5.62211 17.6263C4.75997 17.6263 4.30783 17.1656 4.30783 16.3288V3.68092C4.30783 2.85234 4.75997 2.37484 5.63068 2.37484H9.34711V7.23842C9.34711 8.29306 9.88283 8.81199 10.9207 8.81199H15.6921V16.3288C15.6921 17.1656 15.2482 17.6263 14.3778 17.6263H5.62211ZM11.0714 7.5477C10.745 7.5477 10.6107 7.41413 10.6107 7.07913V2.63449L15.4321 7.54806L11.0714 7.5477ZM13.0803 11.1138H6.72676C6.42568 11.1138 6.20818 11.3398 6.20818 11.6245C6.20818 11.9173 6.42604 12.1434 6.72711 12.1434H13.0803C13.1488 12.1445 13.2168 12.1319 13.2802 12.1062C13.3437 12.0805 13.4013 12.0423 13.4497 11.9939C13.4981 11.9455 13.5363 11.8878 13.562 11.8244C13.5877 11.7609 13.6004 11.6929 13.5993 11.6245C13.5993 11.3398 13.3732 11.1138 13.0803 11.1138ZM13.0803 14.0352H6.72676C6.42568 14.0352 6.20818 14.2695 6.20818 14.5623C6.20818 14.847 6.42604 15.0648 6.72711 15.0648H13.0803C13.3732 15.0648 13.5993 14.847 13.5993 14.5623C13.5993 14.2695 13.3732 14.0352 13.0803 14.0352Z"
                  fill="#374957"
                />
              </svg>
              {data.name}
            </div>
          </TableSkeleton>
        )
      },
    },
    {
      title: t('table.location'),
      key: 'location',
      sorter: false,
      className: 'dashboard-location-cell',
      render: (data) => {
        return (
          <TableSkeleton data={data}>
            <Tooltip title={renderPath(data?.path)} rootClassName="document-location">
              <div className="document-location">{renderPath(data?.path)}</div>
            </Tooltip>
          </TableSkeleton>
        )
      },
    },
    {
      title: t('table.sharing'),
      key: 'sharing',
      sorter: false,
      render: (data) => {
        return (
          <TableSkeleton data={data}>
            <div
              className="document-sharing"
              onClick={() =>
                store.dispatch(
                  openModal({ id: 'share-modal', data: { ...data, type: 'documentId' } }),
                )
              }
            >
              <ShareSVG />
            </div>
          </TableSkeleton>
        )
      },
    },
    // {
    //   title: t('table.contributors'),
    //   key: 'contributors',
    //   sorter: false,
    //   render: (data) => {
    //     return (
    //       <TableSkeleton data={data}>
    //         <div className="document-users">
    //           {data.contributors?.map((user) => {
    //             return <Avatar text={user?.name} image={user.avatar} color={null} />
    //           })}
    //         </div>
    //       </TableSkeleton>
    //     )
    //   },
    // },
    {
      title: t('table.owner'),
      key: 'owner',
      sorter: false,
      render: (data) => {
        return (
          <TableSkeleton data={data}>
            <Tooltip
              title={data.owner?.fullName || data.owner?.email}
              className="document-tooltip"
              placement="topLeft"
            >
              <div className="document-users">
                <Avatar text={data.owner?.fullName} image={data.owner?.avatar} color={null} />
              </div>
            </Tooltip>
          </TableSkeleton>
        )
      },
    },
    {
      title: t('table.created_on'),
      key: 'createdAt',
      sorter: true,
      render: (data) => {
        return (
          <TableSkeleton data={data}>
            <div className="document-date">
              {formatDateToMultiLang(data.createdAt, lang, format)}
            </div>
          </TableSkeleton>
        )
      },
    },
    {
      title: t('table.updated_on'),
      key: 'updatedAt',
      sorter: true,
      render: (data) => {
        return (
          <TableSkeleton data={data}>
            <div className="document-date">
              {formatDateToMultiLang(data.createdAt, lang, format)}
            </div>
          </TableSkeleton>
        )
      },
    },
  ]
}
