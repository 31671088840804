import { clearUsers } from '@src/modules/settings/data/membersSlice/membersSlice'
import { getUsers } from '@src/modules/settings/data/membersSlice/membersThunk'
import { createTeam, updateTeam } from '@src/modules/settings/data/teams/TeamsThunk'
import Avatar from '@src/modules/shared/components/Avatar/Avatar'
import Button from '@src/modules/shared/components/Button/Button'
import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import { FileUpload } from '@src/modules/shared/components/FileUpload'
import Input from '@src/modules/shared/components/Input/Input'
import Spinner from '@src/modules/shared/components/Spinner'
import useDebounce from '@src/modules/shared/hooks/useDebounce'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { getChangedValues } from '@src/modules/shared/utils/getChangedValuesFormik'
import { uploadFile } from '@src/modules/shared/utils/uploadFile'
import { Divider, message, Modal, Tooltip } from 'antd'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ReactComponent as CloseIcon } from '../../../assets/icons/teams/Back.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/teams/trash.svg'
import MemberRole from '../../MemberRole'
import UsersSelect from '../../UsersSelect/UserSelect'
export const colors = [
  '#A6A6A6',
  '#DC7400',
  '#FBAC54',
  '#FFE976',
  '#C1B332',
  '#97D856',
  '#3FABE8',
  '#96D9FF',
  '#B294FF',
]

export const fetchUserOptions = (search, users) => {
  const filteredUsers = search
    ? users?.filter((user) =>
        (user?.firstName || user?.email)?.toLowerCase().includes(search.toLowerCase()),
      )
    : users

  const formattedOptions = filteredUsers?.map((user) => ({
    label: user?.firstName || user?.email,
    value: user?.id,
    avatar: user?.avatar,
    email: user?.email,
    fullName: user?.fullName,
  }))

  return formattedOptions
}

const TeamsModal = ({ open, handleClose, id, data }: any) => {
  const { status } = useAppSelector((state) => state.teams)
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const [skip, setSkip] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [uploadedImage, setUploadedImage] = useState()
  const [previewImage, setPreviewImage] = useState<any | null>()
  const [selectedColor, setSelectedColor] = useState<any | null>(data?.color || '')

  const [selectedUsers, setSelectedUsers] = useState(
    data?.roles?.map((role) => ({
      title: {
        avatar: role?.user?.avatar,
        fullName: role?.user?.fullName || role?.user?.email,
        id: role?.user?.id,
        role_name: role?.name,
      },
      value: role?.user?.id,
      key: role?.user?.id,
    })) || [],
  )

  const [selectedRole, setSelectedRole] = useState(
    data?.roles.map((role) => ({ name: role?.name, user_id: role.userId })) || [],
  )

  const initialValues = {
    name: data?.name || '',
    roles: data?.roles || [],
    icon: data?.icon || '',
    color: data?.color || '',
  }

  useEffect(() => {
    if (skip === 1) dispatch(clearUsers())
    !search
      ? dispatch(
          getUsers({
            limit: 10,
            skip: skip,
          }),
        )
      : dispatch(
          getUsers({
            limit: 10,
            skip: skip,
            search,
          }),
        )
  }, [skip])

  useDebounce(
    async () => {
      dispatch(clearUsers())
      dispatch(
        getUsers({
          limit: 10,
          skip: skip,
          search,
        }),
      )
    },
    500,
    [search],
  )

  const handleFileChange = (file: any) => {
    const maxFileSize = 1 * 1024 * 1024

    if (file) {
      if (file.size > maxFileSize) {
        message.error(t('team.file_size_error'))
        return
      }

      setUploadedImage(file)

      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target) {
          const result = e.target.result as string
          setPreviewImage(result)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().matches(/\S/, t('team.invalid_name')).required(t('team.required_name')),
    }),

    onSubmit: async (values) => {
      values.roles = selectedRole
      values.color = selectedColor

      if (uploadedImage) {
        const fileUploadResponse = await uploadFile(uploadedImage)
        values.icon = fileUploadResponse?.location
      }
      const changedValues = getChangedValues(values, initialValues)
      const newRoles = []
      values?.roles?.map((role) => {
        newRoles?.push({
          name: role?.name,
          role_id: role?.role_id,
          user_id: role?.user_id,
        })
      })
      const isAdmin = changedValues?.roles?.some((role) => role?.dataId === 'managerTeam')
      changedValues.roles = newRoles
      changedValues.name = changedValues?.name?.trim()
      if (isAdmin) {
        if (data) {
          dispatch(updateTeam({ id: data?.id, query: changedValues }))
            .unwrap()
            .then(() => handleClose(id))
            .catch((error) => message.error(error?.message || t('team.something_wrong')))
        } else {
          dispatch(createTeam(changedValues))
            .unwrap()
            .then(() => {
              handleClose(id)
            })
            .catch((error) => message.error(error?.message || t('team.something_wrong')))
        }
      } else {
        message.error(t('team.select_manager'))
      }
    },
  })

  const handleDelete = (userIdToDelete) => {
    setSelectedRole((prevRoles) => prevRoles?.filter((item) => item.user_id !== userIdToDelete))
    setSelectedUsers((prevUsers) => prevUsers?.filter((item) => item.value !== userIdToDelete))
  }

  function getLabel(status, data) {
    if (status === 'loading') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '7px', color: '#fff' }}>
          <Spinner /> {data ? t('team.update_team') : t('team.create_team')}
        </div>
      )
    }

    return data ? t('team.update_team') : t('team.create_team')
  }
  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="team-modal"
      closable={false}
    >
      <form onSubmit={formik.handleSubmit} className="team-form">
        <div className="teams-modal-header">
          <CloseIcon onClick={() => handleClose(id)} className="close-icon" />

          <p className="teams-modal-title">
            {data ? t('team.update_team') : t('team.create_team')}
          </p>
        </div>

        <div>
          <Input
            name="name"
            label={t('team.team_name')}
            formik={formik}
            variant="filled"
            placeholder={t('team.team_placeholder')}
            type="text"
            required={false}
            disabled={false}
            status={formik.errors.name ? 'error' : 'success'}
          />
        </div>

        <Divider />
        <span className="style-title">{t('team.style')}</span>

        <div className="team-avatar-container">
          <div className="team-image-upload">
            <span>{t('team.choose_color')}</span>
            <ColorSelect
              colors={colors}
              disabled={false}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
            <span>{t('team.or')}</span>

            <FileUpload
              handleFile={handleFileChange}
              label={t('team.upload_image')}
              disabled={false}
            />
          </div>
          <Avatar
            text={formik?.values?.name.trim() || data?.name || 'workspace'}
            image={previewImage || data?.icon}
            color={selectedColor || data?.color || '#A6A6A6'}
          />
        </div>
        <span className="style-title">{t('team.members')}</span>

        <UsersSelect
          mode="multiple"
          value={selectedUsers}
          fetchOnMount={true}
          placeholder={t('team.add_member_placeholder')}
          setSkip={setSkip}
          skip={skip}
          search={search}
          setSearch={setSearch}
          setSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          style={{ width: '100%' }}
          listHeight={164}
        />
        <span className="required-manager-note">{t('team.note')}</span>

        <div className="selected-members-container">
          {selectedUsers.length > 0 && <p className="selected-user-paragraph">Persons added</p>}
          {selectedUsers?.map((user, index) => {
            return (
              <div className="selected-user-container" key={index}>
                <div className="user-info">
                  <Avatar
                    text={user?.title?.fullName || 'user'}
                    image={user?.title?.avatar}
                    color={'#A6A6A6'}
                  />
                  <Tooltip title={user?.title?.fullName}>
                    <span>
                      {user?.title?.fullName?.length > 16
                        ? user.title?.fullName?.slice(0, 16) + '...'
                        : user?.title?.fullName}
                    </span>
                  </Tooltip>
                </div>

                <div className="user-actions">
                  <MemberRole
                    role={user?.title?.role_name || user?.role_name || 'member'}
                    onClick={(role) =>
                      setSelectedRole((prevRoles) => {
                        const userExists = prevRoles?.some((item) => item?.user_id === user?.value)

                        if (userExists) {
                          return prevRoles?.map((item) =>
                            item?.user_id === user?.value
                              ? {
                                  ...item,
                                  name: role?.name,
                                  role_id: role?.id,
                                  dataId: role?.dataId,
                                }
                              : item,
                          )
                        } else {
                          return [
                            ...prevRoles,
                            {
                              name: role?.name,
                              user_id: user?.value,
                              role_id: role?.id,
                              dataId: role?.dataId,
                            },
                          ]
                        }
                      })
                    }
                    level={4}
                  />
                  <TrashIcon
                    onClick={() => handleDelete(user?.value)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <Divider style={{ backgroundColor: '#adadad94' }} />

        <div className="buttons-container">
          <Button
            children={t('team.cancel')}
            htmlType="button"
            onClick={() => handleClose(id)}
            disabled={false}
            className="cancel-button"
          />

          <Button
            children={getLabel(status, data)}
            htmlType="submit"
            className="create-button"
            disabled={status === 'loading'}
          />
        </div>
      </form>
    </Modal>
  )
}

export default TeamsModal
