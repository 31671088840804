import React, { useCallback, useState, useEffect, useMemo, forwardRef } from 'react'
import debounce from 'lodash/debounce'
import eyeOn from './eyeOn.svg'
import eyeOff from './eyeOff.svg'
import { InputProps, InputRef } from './@types'
import { getPrefixCls } from '../../utils/getPrefixCls'
import classNames from 'classnames'
import { hasAddon } from './utils/commonUtils'
import { FormikProps } from 'formik'

interface FormikInputProps<T = any> extends InputProps {
  formik?: FormikProps<T>
  name?: string
  type?: string
}

const InternalInput: React.ForwardRefRenderFunction<InputRef, FormikInputProps> = (props, ref) => {
  const {
    inputRef,
    as: Component = 'div',
    size = 'md',
    htmlSize,
    prefix,
    suffix,
    variant = 'outlined',
    allowClear,
    addonBefore,
    addonAfter,
    rounded = 'md',
    style,
    value,
    label,
    disabled,
    readOnly,
    onPressEnter,
    helper,
    direction = 'ltr',
    status,
    className,
    required,
    floatingLabelInput,
    formik,
    name,
    type,
    hidden,
    ...rest
  } = props

  const inputHasAddon = hasAddon(props)
  const [showPassword, setShowPassword] = useState(false)

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onPressEnter) {
        onPressEnter(event)
      }
    },
    [onPressEnter],
  )

  const debouncedValidateField = useCallback(
    debounce(() => {
      formik?.validateField(name)
    }, 500),
    [formik, name],
  )

  useEffect(() => {
    return () => {
      debouncedValidateField.cancel()
    }
  }, [debouncedValidateField])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik?.handleChange(event)
    debouncedValidateField()
  }

  const inputPrefixCls = useMemo(() => getPrefixCls('input'), [])
  const affixWrapperPrefixCls = useMemo(() => getPrefixCls('input-affix-wrapper'), [])

  const inputClasses = classNames(
    inputPrefixCls,
    `${inputPrefixCls}-${direction}`,
    {
      [`${inputPrefixCls}-size-${size}`]: size,
      [`${inputPrefixCls}-font-${size}`]: size,
    },
    className,
  )

  let element

  if (type === 'checkbox') {
    element = (
      <input
        id={name}
        disabled={disabled}
        readOnly={readOnly}
        className={inputClasses}
        ref={ref || inputRef}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        hidden={hidden}
        onBlur={formik?.handleBlur}
        onChange={handleChange}
        checked={value || formik?.values[name] || false}
        type="checkbox"
        {...rest}
      />
    )
  } else {
    element = (
      <input
        id={name}
        size={htmlSize}
        disabled={disabled}
        readOnly={readOnly}
        className={inputClasses}
        ref={ref || inputRef}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        onBlur={formik?.handleBlur}
        onChange={handleChange}
        value={value || formik?.values[name] || ''}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        {...rest}
      />
    )
  }

  const affixWrapperClasses = classNames(
    {
      [`${inputPrefixCls}-${variant}-disabled`]: disabled,
      [`${inputPrefixCls}-rounded-${rounded}`]: rounded,
      [`${inputPrefixCls}-required`]: required,
      [`${inputPrefixCls}-size-${size}`]: size,
      [`${inputPrefixCls}-${variant}`]: variant === 'label-inline',
      [`${inputPrefixCls}-${status}`]: status,
    },
    affixWrapperPrefixCls,
    `${inputPrefixCls}-${variant}`,
  )

  const suffixNode = (suffix || allowClear || type === 'password') && (
    <span className={`${inputPrefixCls}-suffix`}>
      {type === 'password' ? (
        <img
          src={showPassword ? eyeOn : eyeOff}
          alt="eye-icon"
          className="eye-icon"
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        suffix
      )}
    </span>
  )

  element = (
    <div className={affixWrapperClasses} hidden={hidden}>
      {prefix && <div className={`${inputPrefixCls}-prefix`}>{prefix}</div>}
      {element}
      {(suffix || type === 'password') && suffixNode}
    </div>
  )

  if (label) {
    const labelPrefixCls = getPrefixCls('input-label')
    const groupLabelPrefixCls = getPrefixCls('input-label-group')

    const groupLabelClasses = classNames(groupLabelPrefixCls, `${inputPrefixCls}-${direction}`, {
      [`${groupLabelPrefixCls}-${variant}`]: variant === 'label-inline',
      [`${inputPrefixCls}-size-${size}`]: size,
      [`${groupLabelPrefixCls}-${direction}`]: direction === 'rtl',
      [`${groupLabelPrefixCls}-${variant}-disabled`]: disabled,
      [`${inputPrefixCls}-${status}`]: status,
    })
    const labelClasses = classNames(labelPrefixCls, {
      [`${labelPrefixCls}-required`]: required,
      [`${labelPrefixCls}-${direction}`]: direction === 'rtl',
      [`${labelPrefixCls}-${variant}`]: variant === 'label-inline',
    })

    element =
      variant === 'label-inline' ? (
        <fieldset className={groupLabelClasses}>
          <legend className={labelClasses}>{label}</legend>
          {element}
        </fieldset>
      ) : (
        <div className={groupLabelClasses}>
          <p className={labelClasses}>{label}</p>
          {element}
        </div>
      )
  }

  if (helper) {
    const helperTextPrefixCls = getPrefixCls('input-helper')
    const helperTextClasses = classNames(helperTextPrefixCls)

    element = (
      <div className={`${helperTextClasses}-helper`}>
        {element}
        {helper}
      </div>
    )
  }

  if (inputHasAddon) {
    const groupPrefixCls = getPrefixCls('input-addons')
    const wrapperPrefixCls = `${groupPrefixCls}-wrapper`

    const wrapperClasses = classNames(wrapperPrefixCls)
    const addonClasses = `${wrapperPrefixCls}-addon`

    element = (
      <div className={wrapperClasses}>
        <div className={wrapperClasses}>
          {addonBefore && <div className={addonClasses}>{addonBefore}</div>}
          {element}
          {addonAfter && <div className={addonClasses}>{addonAfter}</div>}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="tam-input-container" hidden={hidden}>
        {React.cloneElement(element, {
          className: classNames(element.props?.className, rest.containerClassName) || null,
          style: {
            ...element.props?.style,
            ...style,
          },
          value: value || formik?.values[name] || null,
        })}
        {formik?.touched[name] && formik?.errors[name] ? (
          <p className="error-message">{String(formik.errors[name])}</p>
        ) : null}
      </div>
    </>
  )
}

export default forwardRef<InputRef, FormikInputProps>(InternalInput)
