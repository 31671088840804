import { useAppDispatch } from '@src/modules/shared/store'
import { TimerType } from '@src/modules/tasks/data/timerData/types'
import { Dropdown, Popover, message } from 'antd'
import React from 'react'
import EditIcon from '../../GlobalTimer/assets/icons/EditIcon'
import { deleteTimer } from '@src/modules/tasks/data/timerData/thunks'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import DeleteIcon from '../../GlobalTimer/assets/icons/DeleteIcon'
import { useGetTaskQuery } from '@src/modules/tasks/services/tasksApi'
import EditTimerModal from '../../GlobalTimer/components/GlobalTimersList/OnePostTimersList/TimerListItem/EditTimerModal'
import Button from '@src/modules/shared/components/Button/Button'

type Props = {
  timer: TimerType
  callBack?: Function
  task: TaskType
}

function TimerActionMenu({ timer, task }: Props) {
  const [dropdownVisible, setDropdownVisible] = React.useState<boolean>(false)
  const dispatch: Function = useAppDispatch()
  const { refetch } = useGetTaskQuery({ id: task.id } as any, { skip: !task?.id })
  const [editOpen, setEditOpen] = React.useState<boolean>(false)

  const handleDelete = () => {
    dispatch(deleteTimer({ id: timer.id as string })).then(() => {
      message.success('Timer deleted')
      setDropdownVisible(false)
      const counter = document.getElementById(`task-timer-${task.id}`)
      if (counter) counter.innerHTML = `${task.timers.length - 1}`
      const counter2 = document.getElementById(`task-timer-2-${task.id}`)
      if (counter2) counter2.innerHTML = `${task.timers.length - 1}`
      refetch()
    })
  }

  return (
    <Popover
      open={dropdownVisible}
      onOpenChange={(visible) => {
        !editOpen && setDropdownVisible(visible)
      }}
      trigger={['hover', 'click']}
      content={
        <div className="timer-list-item-manually-actions-list">
          <Dropdown
            placement="topRight"
            open={editOpen}
            onOpenChange={(visible) => setEditOpen(visible)}
            trigger={['hover', 'click']}
            overlay={<EditTimerModal timer={timer} setEditOpen={setEditOpen} />}
            overlayClassName="timer-list-item-manually-actions-list-edit"
          >
            <Button className="edit-button">
              <EditIcon />
              <span>Edit</span>
            </Button>
          </Dropdown>

          <Button
            onClick={() => {
              handleDelete()
            }}
            className="delete-button"
          >
            <DeleteIcon />
            <span>Delete</span>
          </Button>
        </div>
      }
      placement="bottomLeft"
    >
      <Button
        style={
          !timer?.manual
            ? {
                display: 'none',
                opacity: 0,
                pointerEvents: 'none',
              }
            : {}
        }
        className={'timer-list-item-manually-actions'}
      >
        <svg
          width="21"
          height="14"
          viewBox="0 0 21 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="6.17333"
            cy="6.99977"
            r="1.16332"
            transform="rotate(-90 6.17333 6.99977)"
            fill="#AFAFAF"
          />
          <circle
            cx="10.5"
            cy="6.99977"
            r="1.16332"
            transform="rotate(-90 10.5 6.99977)"
            fill="#AFAFAF"
          />
          <circle
            cx="14.8266"
            cy="6.99977"
            r="1.16332"
            transform="rotate(-90 14.8266 6.99977)"
            fill="#AFAFAF"
          />
        </svg>
      </Button>
    </Popover>
  )
}

export default TimerActionMenu
