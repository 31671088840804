import { getData } from '@src/modules/notifications/data/NotificationsSlice'
import { store } from '@src/modules/shared/store'
import { message } from 'antd'
import { io, Socket } from 'socket.io-client'
import { setSocketEvent } from '../../tasks/data/tasksSlice/tasksSlice'
import {
  addAnswerToAnswerList,
  addEmojiToAnswer,
  addEmojiToReply,
  addReplyToAnswer,
  deleteEmojiFromAnswer,
  deleteEmojiFromReply,
  deleteReplyToAnswer,
  updateAnswersPayload,
  updateEmojiInAnswer,
  updateEmojiReply,
  updateReplyToAnswer,
} from '@src/modules/dailyQuestions/data/answers/answersSlice'

class SocketManager implements ISocketManager {
  private socket: Socket | null
  token: string | null
  private connectedRoomId: string | null
  private connectedWorkSpaceId: string | null

  constructor() {
    this.socket = null
    this.connectedWorkSpaceId = null
    this.token = localStorage.getItem('access_token')
    this._connectSocket()
  }

  public _connectSocket(): void {
    this.connectedRoomId &&
      console.log('Should Leave from the previous room with ID', this.connectedRoomId)
    const token = localStorage.getItem('access_token')
    if (this.socket || !token) return

    const socket = io(import.meta.env.VITE_APP_SOCKET_BASE_URL, {
      extraHeaders: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })

    this.socket = socket
    socket.on('connect', () => {
      this.socket = socket
      this._membersManagement()
      this._versionManagment()
    })

    socket.on('error', (e) => {
      console.error('socket error', e)
    })
  }

  public _joinRoom(listId: string): void {
    if (!this.socket) return

    this.socket.emit('joinRoom', listId)
    this.connectedRoomId = listId

    this._versionManagment()

    this.socket.on('notifRoom', (update, dataX) => {
      this.connectedRoomId = listId
      if (typeof update !== 'string') return

      let index = update.indexOf('//')
      if (index !== -1) {
        let type = update.substr(0, index)
        let data = update.substr(index + 2)
        if (type === 'DELETED') {
          store.dispatch(
            setSocketEvent({ updateType: 'deletedTask', updateEvent: JSON.parse(data) }),
          )
        } else if (type === 'CREATED') {
          store.dispatch(
            setSocketEvent({ updateType: 'createdTask', updateEvent: JSON.parse(data) }),
          )
        } else if (type === 'UPDATED') {
          store.dispatch(
            setSocketEvent({ updateType: 'updatedTask', updateEvent: JSON.parse(data) }),
          )
        } else if (type === 'BULKUPDATED') {
          store.dispatch(
            setSocketEvent({ updateType: 'bulkUpdateTask', updateEvent: JSON.parse(data) }),
          )
        } else if (type === 'updatedMany') {
          store.dispatch(
            setSocketEvent({ updateType: 'updatedMany', updateEvent: JSON.parse(data) }),
          )
        } else if (type === 'CREATE') {
          let dateQuery: string
          const endDate = new Date(dataX?.createdAnswer?.date)
          endDate.setDate(endDate.getDate() + 1)
          dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
          const key = `${dataX?.questionId}-${dateQuery}`
          store.dispatch(
            addAnswerToAnswerList({
              key,
              payload: {
                ...dataX?.createdAnswer,
                owner: {
                  ...dataX.owner,
                },
                actions: dataX?.actions,
              },
            }),
          )
        } else if (type === 'UPDATE') {
          const questionId: string = update?.split('//')[1]
          let dateQuery: string
          const endDate = new Date(dataX?.date)
          endDate.setDate(endDate.getDate() + 1)
          dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
          const key = `${questionId}-${dateQuery}`
          store.dispatch(
            updateAnswersPayload({
              key,
              body: dataX?.updatedAnswer?.body,
              payloadId: dataX?.answerId || '',
            }),
          )
        } else if (type === 'DELETE_REACTION') {
          let dateQuery: string
          const endDate = new Date(dataX?.date)
          endDate.setDate(endDate.getDate() + 1)
          dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
          const key = `${dataX?.questionId}-${dateQuery}`
          store.dispatch(
            deleteEmojiFromAnswer({
              key,
              emojiIdToDelete: dataX?.reactionExist.id,
              answerId: dataX?.answerId,
            }),
          )
        } else if (type === 'CREATE_REACTION') {
          let dateQuery: string
          const endDate = new Date(dataX?.date)
          endDate.setDate(endDate.getDate() + 1)
          dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
          const key = `${dataX?.questionId}-${dateQuery}`
          store.dispatch(
            addEmojiToAnswer({ emoji: dataX?.response, key, answerId: dataX?.answerId }),
          )
        } else if (type === 'CREATE_REACTION_FOR_COMMENT') {
          store.dispatch(
            addEmojiToReply({ emoji: dataX?.NewReaction, key: dataX?.NewReaction?.answerId }),
          )
        } else if (type === 'DELETE_REACTION_FOR_COMMENT') {
          store.dispatch(
            deleteEmojiFromReply({
              emojiIdToDelete: dataX?.reactionExist,
              key: dataX?.answerId,
              commentId: dataX?.reactionExist?.commentId,
            }),
          )
        } else if (type === 'UPDATE_REACTION_FOR_COMMENT') {
          store.dispatch(
            updateEmojiReply({
              updatedEmoji: dataX?.NewReaction,
              key: dataX?.NewReaction?.answerId,
              commentId: dataX?.NewReaction?.commentId,
            }),
          )
        } else if (type === 'UPDATE_REACTION') {
          let dateQuery: string
          const endDate = new Date(dataX?.date)
          endDate.setDate(endDate.getDate() + 1)
          dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
          const key = `${dataX?.newData?.questionId}-${dateQuery}`
          store.dispatch(
            updateEmojiInAnswer({
              updatedEmoji: dataX?.newData,
              key,
              answerId: dataX?.newData?.answerId,
            }),
          )
        } else if (type === 'CREATE_REPLY') {
          store.dispatch(addReplyToAnswer(dataX))
        } else if (type === 'DELETE_REPLY') {
          store.dispatch(deleteReplyToAnswer(dataX))
        } else if (type === 'UPDATE_REPLY') {
          store.dispatch(updateReplyToAnswer(dataX))
        }
      } else {
        console.error("No occurrence of '//' found in the string.")
      }
    })
  }

  private _versionManagment() {
    if (!this.socket) return
    this.socket.on('VERSION', (version) => {
      const currentVersion = localStorage.getItem('version')
      if (!version) {
        message.error('no version come from socket!!')
      } else if (currentVersion !== version) {
        localStorage.setItem('version', version)
        if (currentVersion) window.location.reload()
      }
    })
  }

  public joinWorkSpace(workspaceId: string): void {
    if (!this.socket || !workspaceId || this.connectedWorkSpaceId === workspaceId) return
    this.socket.emit('joinWorkspace', workspaceId)
    this.connectedWorkSpaceId = workspaceId
    this._getNotifications()
  }

  public _updateDocument(payload: any) {
    this.socket.emit('updateDocument', payload)
  }

  public _membersManagement(): void {
    if (!this.socket) return
    this.socket.on('userActive', () => {})
  }

  public _getNotifications(): void {
    if (!this.socket) return
    this.socket.on('notifWorkspace', (data) => {
      store.dispatch(getData(data))
    })
  }

  public _getSocket(): Socket {
    if (this.socket) return this.socket
    else this._connectSocket()
  }

  public disconnect(): void {
    // if (this.socket) this.socket.emit('disconnect')
  }
}

const socketManager = new SocketManager()

export default socketManager

interface ISocketManager {
  token: string | null
  _connectSocket(): void
  _joinRoom(listId: string): void
  joinWorkSpace(workspaceId: string): void
  _updateDocument(payload: any): void
  _getSocket(): Socket
}
