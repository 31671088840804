import { SVGProps } from 'react'

function TimerPauseSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.0218506" y="0.627441" width="19.4355" height="17.7455" rx="7.20088" fill="white" />
      <rect x="5.73962" y="5.5" width="8" height="8" rx="2" fill="#9747FF" />
    </svg>
  )
}

export default TimerPauseSVG
