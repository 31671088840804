import React from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import AutoGloablTimer from './AutoGlobalTimer'
import ManualGloablTimer from './ManualGloablTimer'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'

const TimerButton = () => {
  const { t } = useTranslation(['tasks'])

  return (
    <Button className="global-timer-timer-button">
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.25 18.9583C5.88331 18.9583 2.33331 15.4083 2.33331 11.0417C2.33331 6.675 5.88331 3.125 10.25 3.125C14.6166 3.125 18.1666 6.675 18.1666 11.0417C18.1666 15.4083 14.6166 18.9583 10.25 18.9583ZM10.25 4.375C6.57498 4.375 3.58331 7.36667 3.58331 11.0417C3.58331 14.7167 6.57498 17.7083 10.25 17.7083C13.925 17.7083 16.9166 14.7167 16.9166 11.0417C16.9166 7.36667 13.925 4.375 10.25 4.375Z"
          fill="white"
        />
        <path
          d="M10.25 11.4582C9.90833 11.4582 9.625 11.1748 9.625 10.8332V6.6665C9.625 6.32484 9.90833 6.0415 10.25 6.0415C10.5917 6.0415 10.875 6.32484 10.875 6.6665V10.8332C10.875 11.1748 10.5917 11.4582 10.25 11.4582Z"
          fill="white"
        />
        <path
          d="M12.75 2.2915H7.75C7.40833 2.2915 7.125 2.00817 7.125 1.6665C7.125 1.32484 7.40833 1.0415 7.75 1.0415H12.75C13.0917 1.0415 13.375 1.32484 13.375 1.6665C13.375 2.00817 13.0917 2.2915 12.75 2.2915Z"
          fill="white"
        />
      </svg>
      <span>{t('Timer')}</span>
    </Button>
  )
}

const ManualButton = () => {
  const { t } = useTranslation(['tasks'])

  return (
    <Button className="global-timer-timer-button">
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.75 18.9582H7.75002C3.22502 18.9582 1.29169 17.0248 1.29169 12.4998V7.49984C1.29169 2.97484 3.22502 1.0415 7.75002 1.0415H9.41669C9.75835 1.0415 10.0417 1.32484 10.0417 1.6665C10.0417 2.00817 9.75835 2.2915 9.41669 2.2915H7.75002C3.90835 2.2915 2.54169 3.65817 2.54169 7.49984V12.4998C2.54169 16.3415 3.90835 17.7082 7.75002 17.7082H12.75C16.5917 17.7082 17.9584 16.3415 17.9584 12.4998V10.8332C17.9584 10.4915 18.2417 10.2082 18.5834 10.2082C18.925 10.2082 19.2084 10.4915 19.2084 10.8332V12.4998C19.2084 17.0248 17.275 18.9582 12.75 18.9582Z"
          fill="white"
        />
        <path
          d="M7.33336 14.7415C6.82503 14.7415 6.35836 14.5581 6.01669 14.2248C5.60836 13.8165 5.43336 13.2248 5.52503 12.5998L5.88336 10.0915C5.95003 9.60814 6.26669 8.98314 6.60836 8.64147L13.175 2.0748C14.8334 0.416471 16.5167 0.416471 18.175 2.0748C19.0834 2.98314 19.4917 3.90814 19.4084 4.83314C19.3334 5.58314 18.9334 6.31647 18.175 7.06647L11.6084 13.6331C11.2667 13.9748 10.6417 14.2915 10.1584 14.3581L7.65003 14.7165C7.54169 14.7415 7.43336 14.7415 7.33336 14.7415ZM14.0584 2.95814L7.49169 9.5248C7.33336 9.68314 7.15003 10.0498 7.11669 10.2665L6.75836 12.7748C6.72503 13.0165 6.77503 13.2165 6.90003 13.3415C7.02503 13.4665 7.22503 13.5165 7.46669 13.4831L9.97503 13.1248C10.1917 13.0915 10.5667 12.9081 10.7167 12.7498L17.2834 6.18314C17.825 5.64147 18.1084 5.15814 18.15 4.70814C18.2 4.16647 17.9167 3.59147 17.2834 2.9498C15.95 1.61647 15.0334 1.99147 14.0584 2.95814Z"
          fill="white"
        />
        <path
          d="M16.7917 8.19173C16.7333 8.19173 16.675 8.1834 16.625 8.16673C14.4333 7.55006 12.6917 5.8084 12.075 3.61673C11.9833 3.2834 12.175 2.94173 12.5083 2.84173C12.8417 2.75006 13.1833 2.94173 13.275 3.27506C13.775 5.05006 15.1833 6.4584 16.9583 6.9584C17.2917 7.05006 17.4833 7.40006 17.3917 7.7334C17.3167 8.01673 17.0667 8.19173 16.7917 8.19173Z"
          fill="white"
        />
      </svg>

      <span>{t('Manual')}</span>
    </Button>
  )
}

const App: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <TimerButton />,
      children: <AutoGloablTimer />,
    },
    {
      key: '2',
      label: <ManualButton />,
      children: <ManualGloablTimer />,
    },
  ]

  return (
    <Tabs
      onClick={(e) => e.stopPropagation()}
      className="global-timer-tab"
      defaultActiveKey="1"
      items={items}
      // onChange={onChange}
    />
  )
}

export default App
