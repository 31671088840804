import Calendar from '../../Calendar'
import TaskPriority from '../../TaskPriority'
import TaskUsers from '../../TaskUsers'
import dayjs from 'dayjs'
import { ReactComponent as DateSVG } from '../../../assets/icons/fields/date.svg'
import { ReactComponent as CreatedBySVG } from '../../../assets/icons/fields/created-by.svg'
import { ReactComponent as AssigneeSVG } from '../../../assets/icons/fields/assignee.svg'
import { ReactComponent as NameSVG } from '../../../assets/icons/fields/name.svg'
import { ReactComponent as PriorityVG } from '../../../assets/icons/fields/priority.svg'
import { Avatar } from '@src/modules/shared/components/Avatar'
import { Tooltip } from 'antd'
import TextField from '../../CustomFields/TextField'
import NumberField from '../../CustomFields/NumberField'
import FileField from '../../CustomFields/FileField'
import WebsiteField from '../../CustomFields/WebsiteField'
import DropdownField from '../../CustomFields/DropdownField'
import CheckboxField from '../../CustomFields/CheckboxField/CheckboxField'
import RateField from '../../CustomFields/RateField'
import DateField from '../../CustomFields/DateField'
import LabelField from '../../CustomFields/LabelField'

export const getCustomFieldValue = (task, field) => {
  return task?.customFieldsValues?.find((f) => f?.customFieldId === field?.id)?.value
}

const CreatedBy = ({ task }) => {
  const user = task?.owner

  return (
    <Tooltip title={user?.fullName}>
      <div className="list-string-field">
        <Avatar text={user?.fullName} image={user?.avatar} color={user?.color || '#A6A6A6'} />
      </div>
    </Tooltip>
  )
}

const DateComponent = ({ task, field }) => {
  const taskFieldValue = task?.[field?.value]

  const taskDate = dayjs(taskFieldValue)
  const currentDate = dayjs()

  const isToday = taskDate.isSame(currentDate, 'day')

  return (
    <div className="list-string-field">
      {taskFieldValue ? (isToday ? 'Today' : taskDate.format('D/M/YY')) : '-'}
    </div>
  )
}

export const fieldComponentMap = {
  users: {
    component: TaskUsers,
    props: (_, task, canOnlyView, updateTask, localUpdateTask) => ({
      displayAddButton: !task?.users?.length,
      userNumber: 3,
      disable: canOnlyView,
      members: task?.users || [],
      onClick: (e) => e.stopPropagation(),
      setAssignedMembersList: (m) => {
        updateTask({
          id: task?.id,
          users: m?.filter((user) => user?.email)?.map((user) => user?.id) || null,
          teams: m?.filter((user) => user?.name) || null,
        })
        localUpdateTask('users', m)
      },
    }),
  },

  dueDate: {
    component: Calendar,
    props: (_, task, canOnlyView, updateTask, localUpdateTask) => ({
      disable: canOnlyView,
      defEnd: task?.dueDate,
      defStart: task?.startDate,
      setStart: (date) => {
        localUpdateTask('startDate', date)
        updateTask({ id: task?.id, startDate: date })
      },
      setEnd: (date) => {
        localUpdateTask('dueDate', date)
        updateTask({ id: task?.id, dueDate: date })
      },
      onClick: (e) => e.stopPropagation(),
      isInTaskList: true,
    }),
  },

  priority: {
    component: TaskPriority,
    props: (_, task, canOnlyView, __, localUpdateTask) => ({
      disable: canOnlyView,
      showLabel: false,
      activePriorityCode: task?.priority,
      task: task,
      onSelect: (p) => localUpdateTask('priority', p),
    }),
  },

  owner: {
    component: CreatedBy,
    props: (_, task, canOnlyView, __, localUpdateTask) => ({
      disable: canOnlyView,
      showLabel: false,
      activePriorityCode: task?.priority,
      task: task,
      onSelect: (p) => localUpdateTask('priority', p),
    }),
  },

  text: {
    component: TextField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  checkbox: {
    component: CheckboxField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  label: {
    component: LabelField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  number: {
    component: NumberField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  files: {
    component: FileField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  rating: {
    component: RateField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  staticDate: {
    component: DateComponent,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  // phone: {
  //   component: PhoneField,
  //   props: (field, task) => ({
  //     task,
  //     field,
  //   }),
  // },

  dropdown: {
    component: DropdownField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  website: {
    component: WebsiteField,
    props: (field, task) => ({
      task,
      field,
    }),
  },

  date: {
    component: DateField,
    props: (field, task) => ({
      task,
      field,
    }),
  },
}

export const allFields = [
  { field: 'name', type: 'text', fieldName: 'Name', disbaled: true, icon: NameSVG, id: 1 },
  {
    field: 'dueDate',
    type: 'dueDate',
    fieldName: 'Due Date',
    disbaled: false,
    icon: DateSVG,
    id: 2,
  },
  {
    field: 'priority',
    type: 'priority',
    fieldName: 'Priority',
    disbaled: false,
    icon: PriorityVG,
    id: 3,
  },
  { field: 'text', value: 'id', fieldName: 'Task ID', disbaled: false, icon: NameSVG, id: 4 },
  {
    value: 'createdAt',
    field: 'date',
    fieldName: 'Created At',
    disbaled: false,
    icon: CreatedBySVG,
    id: 5,
  },
  {
    value: 'updatedAt',
    field: 'date',
    fieldName: 'Updated At',
    disbaled: false,
    icon: CreatedBySVG,
    id: 6,
  },
  {
    field: 'users',
    type: 'users',
    fieldName: 'Assignee',
    disbaled: false,
    icon: AssigneeSVG,
    id: 7,
  },
  {
    field: 'owner',
    type: 'owner',
    fieldName: 'Created by',
    disbaled: false,
    icon: CreatedBySVG,
    id: 8,
  },
  {
    value: 'startDate',
    field: 'date',
    fieldName: 'Start Date',
    disbaled: false,
    icon: DateSVG,
    id: 9,
  },
]
