import { useGetTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { useParams } from 'react-router-dom'
import GloablTimerSearchTasks from '../GloablTimerSearchTasks'
import NoteInput from '../NoteInput'
import MinHrInputs from './MinHrInputs'
import ManualTimerActions from './ManualTimerActions'
import InputSkeleton from '@src/modules/shared/assets/icons/components/input-skeleton.svg'
function ManualGloablTimer() {
  const { listId } = useParams<{ listId: string }>()

  const { data: tasks, isLoading } = useGetTasksQuery(
    {
      fileId: listId,
      limit: 10,
    },
    {
      skip: !listId,
    },
  )

  return (
    <div className="manual-gloabl-timer">
      {isLoading ? (
        <img
          style={{
            width: '100%',
            height: 'auto',
          }}
          src={InputSkeleton}
        />
      ) : (
        <GloablTimerSearchTasks tasks={tasks?.payload?.payload} />
      )}
      <MinHrInputs />
      <NoteInput />
      <ManualTimerActions />
    </div>
  )
}

export default ManualGloablTimer
