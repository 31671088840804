import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'

//@ts-ignore
const endOfHour = (date: Date): Date => addHours(startOfHour(date))

//@ts-ignore

interface eventsType extends TaskType {
  isEvent: boolean
}

export const reformulateEvents = (events: eventsType[]) => {
  return removeDuplicatesById(
    events?.map((event) => {
      return event?.isEvent
        ? event
        : {
            title: event.name,
            start: new Date(event.startDate),
            end: new Date(event.dueDate),
            allDay: false,
            isEvent: true,
            id: event?.id,
            task: event,
          }
    }),
  )
}
