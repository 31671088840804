import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { formatTime } from '@src/modules/tasks/components/Timer/AutoTimer/AutoTimer'
import {
  setGlobalNote,
  setGlobalTimeHrs,
  setGlobalTimeInSeconds,
  setGlobalTimeMins,
  setSelectedTaskId,
} from '@src/modules/tasks/data/globalTimerData/slices'
import { addManualTimer, getTimers } from '@src/modules/tasks/data/timerData/thunks'
import { useGetTaskQuery, useGetTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { Tooltip } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'

function ManualTimerActions() {
  const { t } = useTranslation(['tasks'])
  const dispatch: Function = useAppDispatch()

  const { listId } = useParams<{ listId: string }>()
  const [loading, setLoading] = useState<boolean>(false)

  const { globalTimeInSeconds, currentGlobalTask, globalNote, status } = useAppSelector(
    (state) => state.globalTimer,
  )

  const { refetch } = useGetTasksQuery(
    {
      fileId: listId,
      limit: 10,
    },
    {
      skip: !listId,
    },
  )

  const { refetch: refetchTasks } = useGetTaskQuery(
    {
      id: currentGlobalTask?.id,
    },
    { skip: !currentGlobalTask },
  )

  const reset = () => {
    dispatch(setGlobalTimeInSeconds(0))
    dispatch(setGlobalNote(''))
    dispatch(setGlobalTimeHrs(0))
    dispatch(setGlobalTimeMins(0))
    dispatch(setSelectedTaskId(undefined))
  }

  const handleCancel = () => {
    reset()
  }

  const handleSave = () => {
    setLoading(true)
    const newDate = new Date()
    const endDate = new Date(newDate.getTime())
    endDate.setSeconds(newDate.getSeconds() + globalTimeInSeconds)
    const data = {
      taskId: currentGlobalTask?.id,
      startTime: newDate.toISOString(),
      endTime: endDate.toISOString(),
    }
    if (globalNote) data['description'] = globalNote
    dispatch(addManualTimer(data)).then(() => {
      currentGlobalTask?.id && dispatch(getTimers({ taskId: currentGlobalTask?.id }))
      refetch()
      refetchTasks()
      const timerCounter = document.getElementById(`task-timer-${currentGlobalTask.id}`)
      if (timerCounter) timerCounter.innerHTML = (parseInt(timerCounter.innerHTML) + 1).toString()
      const formatedTimeSeconds = document.getElementById(`format-time-${currentGlobalTask.id}`)
      if (formatedTimeSeconds)
        formatedTimeSeconds.innerHTML = formatTime(
          currentGlobalTask.totalTime + globalTimeInSeconds,
        )
      reset()
      setLoading(false)
    })
  }

  return (
    <div className="manual-timer-actions">
      <Button className="cancel-timer" onClick={handleCancel}>
        {t('Cancel')}
      </Button>

      <Tooltip
        title={
          !currentGlobalTask?.id
            ? t('Please select a task')
            : !globalTimeInSeconds
              ? t('Please set a time')
              : ''
        }
      >
        <Button
          className="save-timer"
          disabled={
            !currentGlobalTask?.id || status === 'loading' || globalTimeInSeconds === 0 || loading
          }
          loading={loading}
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      </Tooltip>
    </div>
  )
}

export default ManualTimerActions
