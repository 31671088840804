import { SVGProps } from 'react'

type Props = {}

function StartTimer(props: SVGProps<SVGSVGElement & Props>) {
  return (
    <svg
      {...props}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.521851" y="0.627441" width="19.4355" height="17.7455" rx="7.20088" fill="white" />
      <g clipPath="url(#clip0_2686_52978)">
        <path
          d="M13.2296 8.08095L9.87134 5.61841C9.60904 5.42634 9.29867 5.31065 8.97465 5.28417C8.65062 5.25769 8.32559 5.32145 8.03559 5.46839C7.74558 5.61532 7.50193 5.83969 7.33163 6.11663C7.16134 6.39356 7.07105 6.71224 7.07079 7.03735V11.9649C7.07028 12.2903 7.16009 12.6095 7.33023 12.8869C7.50036 13.1643 7.74414 13.3891 8.03443 13.5361C8.32472 13.6832 8.65013 13.7469 8.97444 13.72C9.29875 13.6931 9.60924 13.5767 9.87134 13.3838L13.2296 10.9213C13.4524 10.7578 13.6336 10.5441 13.7585 10.2975C13.8834 10.0509 13.9485 9.7784 13.9485 9.502C13.9485 9.2256 13.8834 8.95309 13.7585 8.70651C13.6336 8.45994 13.4524 8.24624 13.2296 8.08271V8.08095Z"
          fill="#9747FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2686_52978">
          <rect
            width="8.45024"
            height="8.45024"
            fill="#9747FF"
            transform="translate(6.0145 5.2749)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StartTimer
