import Input from './Input'
import { InputRef, SearchProps } from './@types'
import React, { useState, useRef, useMemo, useEffect } from 'react'
import { getPrefixCls } from '../../utils/getPrefixCls'
import classNames from 'classnames'
import { Button } from '../Button'
// import { ReactComponent as CloseIcon } from '@src/modules/shared/assets/icons/controlBar/close.svg';
import { ReactComponent as SearchIcon } from '@src/modules/tasks/assets/icons/filter/search-icon.svg'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

const Search: React.ForwardRefRenderFunction<HTMLInputElement, SearchProps> = (props) => {
  const {
    inputPrefixCls: customizeInputPrefixCls,
    className,
    containerClassName: customizeClassNames,
    size: customizeSize,
    enterButton = false,
    placeholder,
    loading,
    disabled = false,
    onSearch: customOnSearch,
    onChange: customOnChange,
    onCompositionStart,
    onCompositionEnd,
    value,
    style,
    prefix,
    detached,
    variant = 'outlined',
    ...rest
  } = props

  const [query, setQuery] = useState<string | number>(value || '')
  const inputRef = useRef<InputRef>(null)
  const isSearchable = !loading && !disabled
  const { t } = useTranslation(['header'])

  const debouncedSearch = useMemo(() => {
    return debounce((searchQuery: string) => {
      if (customOnSearch) {
        customOnSearch(searchQuery.replace(' ', '%20'))
      }
    }, 500)
  }, [customOnSearch])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    if (customOnChange) {
      customOnChange(e)
    }
    debouncedSearch(e.target.value)
  }

  const onMouseDown: React.MouseEventHandler<HTMLElement> = (e) => {
    if (document.activeElement === inputRef.current) {
      e.preventDefault()
    }
  }

  const onSearch = () => {
    if (!isSearchable) {
      return
    }
    debouncedSearch(query.toString())
  }

  const onPressEnter = () => {
    if (!isSearchable) {
      return
    }
    onSearch()
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const inputSearchPrefixCls = useMemo(() => getPrefixCls('input-search'), [])
  const detachedIconPrefixCls = useMemo(() => getPrefixCls('input-search-icon-detached'), [])

  const inputSearchIconClasses = classNames(`${inputSearchPrefixCls}-icon`, {
    [`${inputSearchPrefixCls}-icon-disabled`]: !isSearchable,
    [`${inputSearchPrefixCls}-icon-detached`]: query,
  })

  const detachedIconClasses = classNames(detachedIconPrefixCls, {})

  const element = (
    <Input
      type="search"
      variant={variant}
      disabled={!isSearchable}
      inputRef={inputRef}
      onPressEnter={onPressEnter}
      onChange={onChange}
      value={query}
      size={customizeSize || 'md'}
      style={style}
      containerClassName={customizeClassNames}
      placeholder={placeholder || t('search')}
      prefix={
        prefix
          ? prefix
          : !detached && (
              <div
                key="enterButton"
                onMouseDown={onMouseDown}
                onClick={onSearch}
                className={inputSearchIconClasses}
              >
                <SearchIcon className="enterButton" />
              </div>
            )
      }
      addonAfter={
        detached && (
          <Button
            key="enterButton"
            onMouseDown={onMouseDown}
            onClick={onSearch}
            className={detachedIconClasses}
            disabled={!isSearchable}
            type="primary"
            icon={<SearchIcon />}
          />
        )
      }
      {...rest}
    />
  )

  return element
}

export default Search
