import { combineReducers } from '@reduxjs/toolkit'
import authReducer from '../../auth/data/authSlice'
import importExportReducer from '../../settings/data/ImportExport/ImportExportSlice'
import integrationsReducer from '../../settings/data/Integrations/IntegrationsSlice'
import usersReducer from '../../settings/data/membersSlice/membersSlice'
import profileReducer from '../../settings/data/Profile/ProfileSlice'
import teamsReducer from '../../settings/data/teams/TeamsSlice'
import workspaceSettingReducer from '../../settings/data/workspaceSlice/workspaceSlice'
import spacesReducer from '../../spaces/data/spaceSlice/spacesSlice.ts'
import documentReducer from '../../document/data/documentSlice/documentSlice'
import tasksReducer from '../../tasks/data/tasksSlice/tasksSlice'
import editorReducer from '../components/BlockNote/Data/slices.ts'
import { api } from '../services/api'
import notificationReducer from './../../notifications/data/NotificationsSlice'
import filesReducer from '../../spaces/data/fileSlice/fileSlice.ts'
import foldersReducer from '../../spaces/data/folderSlice/folderSlice.ts'
import statusReducer from '../../spaces/data/statusSlice/statusSlice.ts'
import commentReducer from './../../tasks/data/commentsData/slices'
import globalTimerReducer from './../../tasks/data/globalTimerData/slices'
import timerReducer from './../../tasks/data/timerData/slices'
import filterReducer from './slices/filter/filterSlice'
import modalsReducer from './slices/modals/modalsSlice'
import permissionsReducer from './slices/permissions/permissionsSlice'
import settingsReducer from './slices/settings/settingsSlice'
import themeReducer from './slices/theme/themeSlice'
import trashReducer from '../../settings/data/trash/TrashSlice'
import documentCommentsReducer from '../../document/data/commentSlice/commentSlice'
import answersReducer from '@src/modules/dailyQuestions/data/answers/answersSlice'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  filter: filterReducer,
  theme: themeReducer,
  permissions: permissionsReducer,
  auth: authReducer,
  settings: settingsReducer,
  spaces: spacesReducer,
  modals: modalsReducer,
  status: statusReducer,
  files: filesReducer,
  folders: foldersReducer,
  workspaceSetting: workspaceSettingReducer,
  notification: notificationReducer,
  importExport: importExportReducer,
  tasks: tasksReducer,
  profile: profileReducer,
  timer: timerReducer,
  teams: teamsReducer,
  globalTimer: globalTimerReducer,
  editor: editorReducer,
  comment: commentReducer,
  users: usersReducer,
  integrations: integrationsReducer,
  documents: documentReducer,
  trash: trashReducer,
  documentComments: documentCommentsReducer,
  answers: answersReducer,
})

export default rootReducer
