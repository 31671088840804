import OneTaskTimersListBodyIcon from '@src/modules/shared/assets/icons/components/timer-list-skeleton-body.svg'
import OneTaskTimersListHeadIcon from '@src/modules/shared/assets/icons/components/timers-list-skeleton-head.svg'

function OneTaskTimersListSkeleton() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        src={OneTaskTimersListHeadIcon}
      />
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        src={OneTaskTimersListBodyIcon}
      />
    </div>
  )
}

export default OneTaskTimersListSkeleton
