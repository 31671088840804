import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Avatar, Badge } from 'antd'

export interface memberType {
  fullName: string
  email: string
  'last-active': string
  id: string
  roles: any
  name?: string
  isTeam: boolean
  isInvited: boolean
  icon?: string
  isOwner?: boolean
  isActive: boolean
  avatar: string
}

interface MemberNameProps {
  data: memberType
}

const MemberName = ({ data }: MemberNameProps) => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state) => state.auth)
  let { avatar, fullName, isActive, id } = data || {}

  isActive = user?.user?.id === id || isActive

  return (
    <>
      <div
        className="member-name"
        onClick={() => dispatch(openModal({ id: 'user-profile-drawer', data }))}
      >
        {fullName ? (
          <>
            <div className="member-avatar">
              <Badge dot color={isActive ? '#12B76A' : '#FF8C22'}>
                <Avatar size={'small'} src={avatar || ''}>
                  {fullName && fullName[0].toUpperCase()}
                </Avatar>
              </Badge>
            </div>
            <p>{fullName}</p>
          </>
        ) : (
          '--'
        )}
      </div>
    </>
  )
}

export default MemberName
