import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import ViewLayout from '@src/modules/shared/components/ViewLayout/ViewLayout'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { store, useAppDispatch } from '@src/modules/shared/store/index'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Avatar, Dropdown, Table, message, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterIcon } from '../../assets/icons/integrations/filter.svg'
import actions from '../../assets/icons/trash/actions.svg'
import pDocument from '../../assets/icons/trash/pDocument.svg'
import pFolder from '../../assets/icons/trash/pFolder.svg'
import pList from '../../assets/icons/trash/pList.svg'
import pSpace from '../../assets/icons/trash/pSpace.svg'
import pTask from '../../assets/icons/trash/pTask.svg'
import redTrash from '../../assets/icons/trash/red-trash.svg'
import restore from '../../assets/icons/trash/restore.svg'
import trash from '../../assets/icons/trash/trash.svg'
import Header from '../../components/Header/Header'
import TrashFilterTime from '../../components/TrashFilterTime'
import TrashFilterType from '../../components/TrashFilterType'
import TrashFilterUsers from '../../components/TrashFilterUsers/TrashFilterUsers'
import { filterType, restoreDeletedElem } from '../../data/trash/TrashThunk'
import Button from '@src/modules/shared/components/Button/Button'
import { useGetTrashsQuery } from '../../services/trashWorkspace'
import Search from '@src/modules/shared/components/Input/search'

const Trash = () => {
  const { t } = useTranslation(['trash'])
  useTitle(t('Settings - Trash'))
  const dispatch = useAppDispatch()
  const headerProps = { title: t('Trash') }
  const locale = {
    emptyText: t('no_data'),
  }
  const [filtersCount, setFiltersCount] = useState<string[]>([])
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [filter, setFilter] = useState<filterType | any | string>({})
  // INFO : EXAMPEL OF FILTER DATA
  // where: {
  //   createdAt: {
  //     operation: '$between',
  //     value: [startDate, endDate],
  //   },
  //   type: {
  //     operation: '$in',
  //     value: selectedTypes,
  //   },
  //   userId: {
  //     operation: '$in',
  //     value: selectedUsers,
  //   },
  // },
  //   useEffect(() => {
  //     dispatch(getTrash({ filter, page }))
  //       .unwrap()
  //       .then((res) => {
  //         setTrashData(res?.payload?.payload)
  //       })
  //   }, [filter, isCleared])

  const {
    data: trashList,
    isLoading,
    isFetching,
  } = useGetTrashsQuery({
    limit: 15,
    skip: page,
    options: filter,
  })

  const [trashListData, setTrashListData] = useState(trashList)
  useEffect(() => {
    if (trashList) {
      setTrashListData(trashList)
    }
  }, [trashList])

  useEffect(() => {
    if (!selectedTypes || !selectedUsers || (!startDate && !endDate)) {
      setFiltersCount([])
    }
  }, [selectedTypes, selectedUsers, startDate, endDate])

  useEffect(() => {
    let newFilter: any = { where: {} }

    if (selectedTypes.length > 0) {
      newFilter.where.type = {
        operation: '$in',
        value: selectedTypes,
      }

      setPage(1)
      if (!filtersCount.includes('type')) {
        setFiltersCount((prev) => [...prev, 'type'])
      }
    } else {
      setFiltersCount((prev) => prev.filter((item) => item !== 'type'))
    }

    if (selectedUsers.length > 0) {
      newFilter.where.userId = {
        operation: '$in',
        value: selectedUsers,
      }
      setPage(1)

      if (!filtersCount.includes('users')) {
        setFiltersCount((prev) => [...prev, 'users'])
      }
    } else {
      setFiltersCount((prev) => prev.filter((item) => item !== 'users'))
    }

    // if (startDate && endDate) {
    //   newFilter.where.createdAt = {
    //     operation: '$between',
    //     value: [startDate, endDate],
    //   }
    if (endDate) {
      const adjustedStartDate =
        startDate || new Date(new Date(endDate).setHours(0, 0, 0, 0)).toISOString()
      newFilter.where.createdAt = {
        operation: '$between',
        value: [adjustedStartDate, endDate],
      }

      setPage(1)

      if (!filtersCount.includes('date')) {
        setFiltersCount((prev) => [...prev, 'date'])
      }
    } else {
      setFiltersCount((prev) => prev.filter((item) => item !== 'date'))
    }
    if (Object.keys(newFilter.where).length === 0) {
      Object.keys(filter).length !== 0 && setFilter({})
    } else {
      setFilter(JSON.stringify(newFilter))
    }
  }, [selectedTypes, selectedUsers, startDate, endDate])
  //   }, [selectedTypes, selectedUsers, startDate, endDate, filtersCount])

  const [dropDownState, setDropDownState] = useState('')

  useEffect(() => {
    if (!trashListData) return
    if (trashListData?.metadata > trashListData?.payload?.length) return

    const handleScroll = (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      const scrollHeightTrunc = Math.trunc(scrollHeight - scrollTop)

      const bottom =
        scrollHeightTrunc === clientHeight ||
        scrollHeightTrunc + 1 === clientHeight ||
        scrollHeightTrunc - 1 === clientHeight

      if (bottom && trashListData?.metadata?.last_page > page) {
        setPage((prev) => prev + 1)
      }
    }
    const scrollerElement = document.querySelector('.ant-table-body')
    scrollerElement.addEventListener('scroll', handleScroll)
    return () => {
      scrollerElement.removeEventListener('scroll', handleScroll)
    }
  }, [trashListData])
  const resetFilter = () => {
    setFiltersCount([])
    setStartDate(null)
    setEndDate(null)
    setSelectedTypes([])
    setSelectedUsers([])
    // setFilter({})
  }

  const typeMap = {
    document: {
      icon: pDocument,
      getName: (elem) => elem?.file?.name,
    },
    list: {
      icon: pList,
      getName: (elem) => elem?.file?.name || elem?.list?.name,
    },
    space: {
      icon: pSpace,
      getName: (elem) => elem?.space?.name,
    },
    task: {
      icon: pTask,
      getName: (elem) => elem?.task?.name,
    },
    default: {
      icon: pFolder,
      getName: (elem) => elem?.folder?.name,
    },
  }
  const getIconSource = (type) => {
    return typeMap[type]?.icon || typeMap.default.icon
  }

  const getName = (elem) => {
    const type = typeMap[elem.type] || typeMap.default
    return type.getName(elem)
  }

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, elem) => (
        <div className="trash_name">
          <img src={getIconSource(elem?.type)} alt={elem?.type} />
          <span>{getName(elem)}</span>
        </div>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      render: (_, elem) => <span className="trash_type">{elem?.type}</span>,
    },
    //TODO
    // {
    //   title: t('Location'),
    //   dataIndex: 'location',
    //   key: 'location',
    //   render: (_, elem) => <span className="trash_location">{console.log(elem)}</span>,
    // },
    {
      title: t('Deleted on'),
      key: 'deletedOn',
      dataIndex: 'deletedOn',
      render: (_, elem) => (
        <span className="trash_deleted_on">{reformulateDate(elem?.createdAt)}</span>
      ),
    },
    {
      title: t('Deleted by'),
      key: 'deletedBy',
      dataIndex: 'deletedBy',
      render: (_, elem) => (
        <div className="trash_deleted_by">
          <Avatar src={elem?.deletedBy?.avatar}>{elem?.deletedBy?.fullName.slice(0, 1)}</Avatar>
          <span>{elem?.deletedBy?.fullName}</span>
        </div>
      ),
    },
    {
      title: t('Action'),
      key: 'actions',
      dataIndex: 'actions',
      render: (_, elem) => (
        <Dropdown
          className="trash_actions_dropdown"
          open={dropDownState == elem.id}
          onOpenChange={() =>
            dropDownState == elem.id ? setDropDownState('') : setDropDownState(elem.id)
          }
          trigger={['click']}
          dropdownRender={() => (
            <div className="filter_items">
              <Can I="update" a="trash">
                <div
                  className="filter_item"
                  onClick={() => {
                    store
                      .dispatch(restoreDeletedElem(elem?.id))
                      .unwrap()
                      .then((res) => {
                        setTrashListData((prev) => ({
                          ...prev,
                          payload: prev?.payload?.filter((elemData) => elemData?.id !== elem?.id),
                        }))
                        message.success(res?.message || t('Element restored with success'))
                      })
                  }}
                >
                  <img src={restore} />
                  <span>{t('Restore')}</span>
                </div>
              </Can>

              <Can I="update" a="trash">
                <div
                  className="filter_item delete_item"
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: 'delete-permanently-modal',
                        data: {
                          elem: elem,
                          onDelete: () =>
                            setTrashListData((prev) => ({
                              ...prev,
                              payload: prev?.payload?.filter(
                                (elemData) => elemData?.id !== elem?.id,
                              ),
                            })),
                        },
                      }),
                    )
                  }}
                >
                  <img src={trash} />
                  <span>{t('Delete permanently')}</span>
                </div>
              </Can>
            </div>
          )}
        >
          <img src={actions} className="trash_actions" />
        </Dropdown>
      ),
    },
  ]

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="trash_container">
        <div className="trash_header">
          <div className="trash_header_left">
            <Search
              size="sm"
              style={{ width: '100%', maxWidth: '370px' }}
              containerClassName="trash_header-search"
            />

            <Dropdown
              className="filter_dropdown"
              trigger={['click']}
              dropdownRender={() => (
                <div className="filter_items">
                  <TrashFilterType
                    selectedTypes={selectedTypes}
                    setSelectedTypes={setSelectedTypes}
                  />

                  <TrashFilterTime setStartDate={setStartDate} setEndDate={setEndDate} />

                  <Can I="delete" a="trash">
                    <TrashFilterUsers
                      selectedUsers={selectedUsers}
                      setSelectedUsers={setSelectedUsers}
                    />
                  </Can>
                </div>
              )}
            >
              <div className="filter_btn">
                <FilterIcon />
                <span>
                  {t('Filter')}
                  <span className="filters-count">{filtersCount.length}</span>
                </span>
              </div>
            </Dropdown>

            {filtersCount.length > 0 && (
              <Tooltip title={t('Clear Filter')}>
                <img src={redTrash} className="clear-filter" onClick={() => resetFilter()} />
              </Tooltip>
            )}
          </div>

          <div className="trash_header_right">
            <span className="trash_message">{t('Note')}</span>

            {(trashListData?.payload?.length > 0 || startDate || endDate) && (
              <Can I="delete" a="trash">
                <Button
                  className="clear_trash"
                  onClick={() => {
                    dispatch(openModal({ id: 'clear-tash-modal', data: setTrashListData }))
                  }}
                >
                  <img src={redTrash} /> {t('Clear trash')}
                </Button>
              </Can>
            )}
          </div>
        </div>

        <div className="trash_content">
          <Table
            dataSource={trashListData && trashListData?.payload}
            columns={columns}
            loading={isLoading || isFetching}
            className="trash_table"
            pagination={false}
            scroll={{ y: 600 }}
            locale={locale}
          />
        </div>
      </div>
    </ViewLayout>
  )
}

export default Trash
