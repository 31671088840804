import { SVGProps } from 'react'

type Props = {}

function StopIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.782227"
        y="0.627441"
        width="19.4355"
        height="17.7455"
        rx="7.20088"
        fill="#9747FF"
      />
      <rect x="5.92847" y="5.5" width="9.14286" height="8" rx="3.04288" fill="#FEFEFE" />
    </svg>
  )
}

export default StopIcon
