import Avatar from '@src/modules/shared/components/Avatar/Avatar'
import Button from '@src/modules/shared/components/Button/Button'
import ColorSelect from '@src/modules/shared/components/ColorSelect/ColorSelect'
import FileUpload from '@src/modules/shared/components/FileUpload/FileUpload'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import instructor from '../../../assets/icons/instructor.svg'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { message } from 'antd'
interface Props {
  colors: string[]
  formik: any
  selectedColor: any
  setUploadedImage: any
  confirmAvatar: boolean
  setConfirmAvatar: any
  setSelectedColor: any
  confirmWorkspaceName: boolean
}
const WorkspaceAvatar: React.FC<Props> = ({
  confirmWorkspaceName,
  formik,
  colors,
  confirmAvatar,
  setUploadedImage,
  selectedColor,
  setSelectedColor,
  setConfirmAvatar,
}) => {
  const { t } = useTranslation(['workspaces'])
  const [previewImage, setPreviewImage] = useState<any | null>()
  const { width } = useWindowSize()

  const handleFileChange = (file: any) => {
    const maxSizeInBytes = 2 * 1024 * 1024

    if (file && file.size <= maxSizeInBytes) {
      setUploadedImage(file)

      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target) {
          const result = e.target.result as string
          setPreviewImage(result)
        }
      }

      reader.readAsDataURL(file)
    } else message.error(t('File size exceeds 2MB'))
  }

  return (
    <>
      <h1>{t('Customize your Workspace avatar')}</h1>

      <div className="icon-container">
        <FileUpload
          handleFile={handleFileChange}
          label={t('Upload an image')}
          disabled={confirmWorkspaceName ? false : true}
          isCreateWorkSpace={true}
        />

        {width > 850 && (
          <div className="divider-container">
            <span className="divider"></span>

            <span>{t('Or')}</span>

            <span className="divider"></span>
          </div>
        )}

        <div className="select-avatar-container">
          {width > 850 && (
            <Avatar
              text={formik?.values?.workspaceName || t('workspace')}
              image={previewImage}
              color={selectedColor || '#A6A6A6'}
            />
          )}

          <ColorSelect
            colors={colors}
            disabled={confirmWorkspaceName ? false : true}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </div>
      </div>

      {width <= 850 && <img src={instructor} />}

      <Button
        children={t('Next')}
        className="workspace-btn next-btn"
        htmlType="button"
        onClick={() => setConfirmAvatar(!confirmAvatar)}
        disabled={confirmWorkspaceName ? false : true}
      />
    </>
  )
}

export default WorkspaceAvatar
