import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import TimerClockSVG from './TimerClockSVG'
import TimerPauseSVG from './TimerPauseSVG'
import { formatTime } from '../../../components/Timer/AutoTimer/AutoTimer'
import { ReactComponent as SVG } from '@src/modules/shared/assets/icons/components/start-timer-svg.svg'
import GlobalTimer from '@src/modules/tasks/components/GlobalTimer'
import React, { useEffect, useState } from 'react'
import { Popover } from 'antd'
import {
  setCurrentGlobalTask,
  setCurrentGlobalTimer,
  setGlobalNote,
  setGlobalTimer,
  setIsRunning as setGlobalIsRunning,
  setSelectedTaskId,
} from '@src/modules/tasks/data/globalTimerData/slices'
import { closeGlobalTimer, startGlobalTimer } from '@src/modules/tasks/data/globalTimerData/thunks'
import { useGetTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { useParams } from 'react-router-dom'
import {
  handleCounterProcess,
  setCurrentTimer,
  setTimer,
  startTimerThunk,
  stopTimerThunk,
} from '@src/modules/tasks/data/timerData/slices'
import { closeTimer, getTimers } from '@src/modules/tasks/data/timerData/thunks'
import Button from '@src/modules/shared/components/Button/Button'

function FloatingTimer() {
  const [isGlobalTimerOpen, setIsGlobalTimerOpen] = useState(false)
  const {
    currentGlobalTask,
    isRunning: isGlobalRunning,
    globalNote,
    activeGlobalTimer,
    selectedTaskId,
    globalTimer,
    currentGlobalTimer,
  } = useAppSelector((state) => state.globalTimer)
  const [localTimer, setLocalTimer] = useState(globalTimer)
  const [_, setCurrentLocalTimer] = useState(currentGlobalTimer)
  const dispatch: Function = useAppDispatch()
  const { isRunning, timer, currentTimer, currentTask, timers, activeTimer } = useAppSelector(
    (state) => state.timer,
  )

  const { listId } = useParams<{ listId: string }>()
  const { refetch } = useGetTasksQuery(
    {
      fileId: listId,
      limit: 10,
    },
    {
      skip: !listId,
    },
  )
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleGlobalTimer = () => {
    dispatch(setGlobalIsRunning(!isGlobalRunning))
    const data = { taskId: currentGlobalTask?.id }
    if (globalNote) data['description'] = globalNote
    if (!isGlobalRunning) {
      dispatch(setGlobalTimer(localTimer))
      dispatch(setCurrentGlobalTimer(0))
      setCurrentLocalTimer(0)
      setLocalTimer(0)
      dispatch(startGlobalTimer(data))
    } else {
      dispatch(
        closeGlobalTimer({
          timerId: activeGlobalTimer?.id,
        }),
      ).then(() => {
        const counter = document.getElementById(`task-timer-${currentGlobalTask.id}`)
        const formatedTimeSeconds = document.getElementById(`format-time-${currentGlobalTask.id}`)
        if (formatedTimeSeconds) {
          dispatch(
            setCurrentGlobalTask({
              ...currentGlobalTask,
              timers: [...currentGlobalTask.timers, activeGlobalTimer],
              totalTime: currentGlobalTask.totalTime + currentGlobalTimer,
            }),
          )
          counter.innerHTML = `${currentGlobalTask.timers.length + 1}`
          formatedTimeSeconds.innerHTML = formatTime(
            currentGlobalTask.totalTime + currentGlobalTimer,
          )
          dispatch(setGlobalIsRunning(false))
          dispatch(setCurrentGlobalTimer(0))
          dispatch(setGlobalTimer(0))
          dispatch(setGlobalNote(''))
          dispatch(setSelectedTaskId(null))
          refetch()
        }
      }),
        dispatch(setCurrentGlobalTimer(0))
    }
  }

  useEffect(() => {
    if (!isRunning) {
      dispatch(setGlobalIsRunning(false))
      dispatch(setCurrentGlobalTimer(0))
      dispatch(setCurrentGlobalTask(null))
      dispatch(setGlobalTimer(0))
      dispatch(setGlobalNote(''))
      dispatch(setSelectedTaskId(null))
    }
  }, [isRunning])

  const isFirstRenderRef = React.useRef(true)

  useEffect(() => {
    if (currentTask && timers && currentTimer !== 0 && isRunning && isFirstRenderRef.current) {
      dispatch(setTimer(timer + currentTimer || 0))
      dispatch(setCurrentTimer(currentTimer))
      dispatch(startTimerThunk(currentTask.id))
    }
    isFirstRenderRef.current = false
  }, [currentTask, timers, dispatch, setGlobalIsRunning])

  const handleStopTaskDetailTimer = () => {
    dispatch(handleCounterProcess())
    // sync with global time

    dispatch(setGlobalIsRunning(false))
    dispatch(setCurrentGlobalTimer(0))
    dispatch(setCurrentGlobalTask(null))
    dispatch(setGlobalTimer(0))
    dispatch(setGlobalNote(''))
    dispatch(setSelectedTaskId(null))

    // sync with global time
    if (activeTimer) {
      dispatch(closeTimer({ timerId: activeTimer.id })).then(() => {
        dispatch(getTimers({ taskId: currentTask.id }))
      })
    }
    dispatch(stopTimerThunk())
  }

  const handleStartClick = (e) => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true)
      e.stopPropagation()
      if (!isRunning) {
        handleGlobalTimer()
      } else {
        handleStopTaskDetailTimer()
      }

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 2000)
    }
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        setIsGlobalTimerOpen(!isGlobalTimerOpen)
      }}
      className={`timer-counter ${
        isGlobalRunning ? 'timer-counter-running' : 'timer-counter-stopped'
      }`}
    >
      <div className="timer-counter-container">
        <Popover
          open={isGlobalTimerOpen}
          onOpenChange={(visible) => setIsGlobalTimerOpen(visible)}
          content={<GlobalTimer />}
          trigger={['click']}
          className="global-timer-popover"
          overlayClassName="global-timer-popover"
        >
          <TimerClockSVG fill={isGlobalRunning ? '#fff' : '#374957'} className="clock-svg" />
        </Popover>

        <Popover title={!currentGlobalTask || (!selectedTaskId && 'Select a task to start timer')}>
          <Button
            onClick={(e) => handleStartClick(e)}
            disabled={!currentGlobalTask || !selectedTaskId}
            style={{
              outline: 'none',
              border: 'none',
              background: 'transparent',
              cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
              margin: '0',
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: !currentGlobalTask || !selectedTaskId ? 'none' : 'auto',
            }}
          >
            {isGlobalRunning ? <TimerPauseSVG /> : <SVG />}
          </Button>
        </Popover>

        <div className={`time counter ${isGlobalRunning ? 'stop-time' : ''}`}>
          {timer && isRunning
            ? formatTime(isRunning ? currentTimer : timer)
            : formatTime(isGlobalRunning ? currentGlobalTimer : globalTimer)}
        </div>
      </div>
    </div>
  )
}

export default FloatingTimer
