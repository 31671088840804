import Tab from './components/Tab'
import GlobalTimersList from './components/GlobalTimersList'
import { useGetTasksQuery } from '../../services/tasksApi'
import { useParams } from 'react-router-dom'

function GlobalTimer() {
  const { listId } = useParams<{ listId: string }>()
  const { data: tasks, isLoading } = useGetTasksQuery({
    fileId: listId,
    options: JSON.stringify({
      where: [
        {
          fileId: { operation: '$Equal', value: listId },
        },
      ],
      order: { statusCount: 'ASC' },
    }),
  })

  return (
    <div className="global-timer">
      <Tab />
      <GlobalTimersList tasks={tasks?.payload?.payload} isLoading={isLoading} />
    </div>
  )
}

export default GlobalTimer
