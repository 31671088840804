import { useState } from 'react'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import Input from '@src/modules/shared/components/Input/Input'

const NumberField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const [isInput, setIsInput] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState(false)

  const taskFieldValue = field?.custom ? customFieldValue : task?.[field?.value]

  const fieldClickHandler = (e) => {
    if (!field.custom) return
    e.stopPropagation()
    setIsInput(true)
    setInputValue(taskFieldValue || '')
  }

  const focusOut = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsInput(false)
      setError(false)
      saveCustomFieldValue(+inputValue)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!error) {
        saveCustomFieldValue(+inputValue)
        setIsInput(false)
      }
    } else if (e.key === 'Escape') setIsInput(false)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    if (/^-?\d*\.?\d*$/.test(value)) {
      setError(false)
      setInputValue(value)
    } else {
      setError(true)
    }
  }

  return (
    <div
      className="list-string-field list-number-field"
      onClick={fieldClickHandler}
      tabIndex={0}
      onBlur={focusOut}
      onKeyDown={handleKeyDown}
    >
      {isInput ? (
        <Input
          autoFocus
          type="text"
          size="xs"
          value={inputValue}
          onChange={handleInputChange}
          variant="label-inline"
          className={error ? 'input-error' : ''}
        />
      ) : (
        <OverflowTooltip>{taskFieldValue || '-'}</OverflowTooltip>
      )}
    </div>
  )
}

export default NumberField
