import { ReactComponent as NumberIcon } from '@src/modules/customFields/assets/number-icon.svg'
import { ReactComponent as CheckBoxIcon } from '@src/modules/customFields/assets/checkbox-icon.svg'

import { ReactComponent as LabelIcon } from '@src/modules/customFields/assets/label-icon.svg'

import { ReactComponent as DataIcon } from '@src/modules/customFields/assets/data-icon.svg'
// TODO
// import { ReactComponent as LocationIcon } from '@src/modules/customFields/assets/location-icon.svg'
// import { ReactComponent as MoneyIcon } from '@src/modules/customFields/assets/money-icon.svg'
import { ReactComponent as PeopleIcon } from '@src/modules/customFields/assets/peopel-icon.svg'
// import { ReactComponent as PhoneIcon } from '@src/modules/customFields/assets/phone-icon.svg'
// import { ReactComponent as ProgressIcon } from '@src/modules/customFields/assets/progress-icon.svg'
import { ReactComponent as RatingIcon } from '@src/modules/customFields/assets/rating-icon.svg'
// import { ReactComponent as RelationshipIcon } from '@src/modules/customFields/assets/relationship-icon.svg'
// import { ReactComponent as TaskIcon } from '@src/modules/customFields/assets/task-icon.svg'

import { ReactComponent as WebsiteIcon } from '@src/modules/customFields/assets/website-icon.svg'
import { ReactComponent as TextIcon } from '@src/modules/customFields/assets/text-icon.svg'
import { ReactComponent as DropDownIcon } from '@src/modules/customFields/assets/drop-down-icon.svg'

import { ReactComponent as FileIcon } from '@src/modules/customFields/assets/file-icon.svg'
import { ReactNode } from 'react'

export interface ICustomField {
  key: string
  label: string
  field: string
  icon: ReactNode
  color: string
  populare: boolean
}

export const CustomFieldsIcons: ICustomField[] = [
  { key: '1', label: 'Date', field: 'date', icon: <DataIcon />, color: '#0D8C38', populare: true },
  {
    key: '2',
    label: 'Label',
    field: 'label',
    icon: <LabelIcon />,
    color: '#FFAB00',
    populare: true,
  },
  {
    key: '3',
    label: 'Files',
    field: 'files',
    icon: <FileIcon />,
    color: '#0D8C38',
    populare: false,
  },
  //   {
  //     key: '4',
  //     label: 'Money',
  //     field: 'money',
  //     icon: <MoneyIcon />,
  //     color: '#DB6161',
  //     populare: false,
  //   },
  {
    key: '5',
    label: 'Number',
    field: 'number',
    icon: <NumberIcon />,
    color: '#DB6161',
    populare: true,
  },
  {
    key: '6',
    label: 'People',
    field: 'people',
    icon: <PeopleIcon />,
    color: '#46ADCC',
    populare: false,
  },
  //   {
  //     key: '7',
  //     label: 'Phone',
  //     field: 'phone',
  //     icon: <PhoneIcon />,
  //     color: '#46ADCC',
  //     populare: false,
  //   },
  //   {
  //     key: '8',
  //     label: 'Progress (Auto)',
  //     field: 'progress',
  //     icon: <ProgressIcon />,
  //     color: '#FFAB00',
  //     populare: false,
  //   },
  // {
  //   key: '9',
  //   label: 'Progress',
  //   field: 'progress',
  //   icon: <ProgressIcon />,
  //   color: '#FFAB00',
  //   populare: true,
  // },
  {
    key: '10',
    label: 'Rating',
    field: 'rating',
    icon: <RatingIcon />,
    color: '#FFAB00',
    populare: false,
  },
  //   {
  //     key: '11',
  //     label: 'Relationship',
  //     field: 'relationship',
  //     icon: <RelationshipIcon />,
  //     color: '#6F6EBA',
  //     populare: false,
  //     },
  //TODO
  // {
  //   key: '12',
  //   label: 'Rollup',
  //   field: 'rollup',
  //   icon: <RollupIcon />,
  //   color: '#6F6EBA',
  //   populare: false,
  // },
  //   {
  //     key: '13',
  //     label: 'Task',
  //     field: 'tasks',
  //     icon: <TaskIcon />,
  //     color: '#6F6EBA',
  //     populare: false,
  //   },
  {
    key: '14',
    label: 'Website',
    field: 'website',
    icon: <WebsiteIcon />,
    color: '#46ADCC',
    populare: false,
  },
  { key: '15', label: 'Text', field: 'text', icon: <TextIcon />, color: '#794413', populare: true },
  {
    key: '16',
    label: 'DropDown',
    field: 'dropdown',
    icon: <DropDownIcon />,
    color: '#A10CB9',
    populare: true,
  },

  // { key: '13', label: 'Rollup', field: 'rollup', icon: <RollupIcon /> },
  //   {
  //     key: '11',
  //     label: 'Task',
  //     field: 'tasks',
  //     icon: <TaskIcon />,
  //     color: '#6F6EBA',
  //     populare: false,
  //   },
  {
    key: '15',
    label: 'CheckBox',
    field: 'checkbox',
    icon: <CheckBoxIcon />,
    color: '#A10CB9',
    populare: false,
  },
]
