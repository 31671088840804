import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import React, { useEffect, useState } from 'react'

import Button from '@src/modules/shared/components/Button/Button'
import useDebounce from '@src/modules/shared/hooks/useDebounce'
import { useGetTaskQuery } from '@src/modules/tasks/services/tasksApi'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  handleCounterProcess,
  setCurrentTask,
  setTimer,
  startTimerThunk,
  stopTimerThunk,
} from '../../../data/timerData/slices'
import StartIcon from '../components/icons/StartIcon'
import StopIcon from '../components/icons/StopIcon'

interface Props {
  showButtonLabel: boolean
  handleStart: () => void
  handleStop: () => void
  timerCounterColor?: string
}

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.floor(time % 60)
  return `${hours}:${minutes.toString().padStart(2, '00')}:${seconds.toString().padStart(2, '00')}`
}

const AutoTimer: React.FC<Props> = ({ showButtonLabel, handleStop, handleStart }) => {
  const { isRunning, currentTimer, activeTaskTimer, activeTimer, timers } = useAppSelector(
    (state) => state.timer,
  )
  const { t } = useTranslation(['tasks'])
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const { data: task, refetch } = useGetTaskQuery({ id: taskId as string })
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  let sum = 0
  timers?.map((el) => {
    sum += +el?.duration
  })

  useEffect(() => {
    if (taskId) dispatch(setCurrentTask(taskId))
  }, [])

  useDebounce(
    () => {
      if (taskId) {
        dispatch(setCurrentTask(taskId))
        if (isRunning && activeTaskTimer === taskId) {
          dispatch(startTimerThunk(taskId))
        } else if (!isRunning && activeTaskTimer === taskId) {
          dispatch(stopTimerThunk())
          refetch().then(() => {
            dispatch(setTimer(task?.totalTime || 0))
          })
        }
      }
    },
    500,
    [isRunning, dispatch, taskId, task, refetch, activeTaskTimer],
  )

  const handleStartClick = () => {
    if (!isButtonDisabled) {
      handleStopClick()
      setIsButtonDisabled(true)
      dispatch(handleCounterProcess())
      handleStart && handleStart()
      dispatch(startTimerThunk(taskId))

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 500)
    }
  }

  const handleStopClick = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true)
      dispatch(handleCounterProcess())
      handleStop && handleStop()
      dispatch(stopTimerThunk())

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 500)
    }
  }

  return (
    <div className="auto-timer-container">
      {isRunning && activeTaskTimer === taskId && (
        <Button
          style={{
            width: showButtonLabel ? '70px' : 'auto',
          }}
          onClick={handleStopClick}
          className="stop-button"
        >
          <StopIcon /> {showButtonLabel && <span>Stop</span>}
        </Button>
      )}
      {(!isRunning || activeTaskTimer !== taskId) && (
        <Button
          onClick={handleStartClick}
          className="start-button"
          style={{
            width: showButtonLabel ? '70px' : 'auto',
          }}
        >
          <StartIcon />
          {showButtonLabel && <span>{t('Play')}</span>}
        </Button>
      )}
      <span className="auto-timer-counter auto-timer-counter-black">
        {formatTime(
          isRunning && activeTaskTimer === taskId && !activeTimer?.endTime ? currentTimer : 0,
        )}
      </span>
    </div>
  )
}

export default AutoTimer
