import SVGSkeleton from '@src/modules/shared/assets/icons/components/timer-list-skeleton.svg'

function TimersListSkeleton() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {new Array(4).fill(0).map((_, i) => (
        <img key={i} src={SVGSkeleton} />
      ))}
    </div>
  )
}

export default TimersListSkeleton
