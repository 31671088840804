import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import CloseTimer from '../../icons/CloseTimer'
import { useAppDispatch } from '@src/modules/shared/store'
import {
  setCurrentGlobalTask,
  setCurrentGlobalTimer,
  setGlobalTimer,
} from '@src/modules/tasks/data/globalTimerData/slices'
import Button from '@src/modules/shared/components/Button/Button'

type Props = {
  task: TaskType
  setSelectedTaskId: Function
}

function TaskSelectedLabel({ task, setSelectedTaskId }: Props) {
  const dispatch: Function = useAppDispatch()
  const clearSelectedTask = () => {
    dispatch(setCurrentGlobalTask(null))
    dispatch(setSelectedTaskId(undefined))
    dispatch(setGlobalTimer(0))
    dispatch(setCurrentGlobalTimer(0))
  }
  if (!task) return null
  return (
    <div
      key={task.id}
      className="task-search-label"
      style={{
        paddingInline: '10px',
        paddingBlock: '20px',
      }}
    >
      <div className="left-side">
        <div className="status" style={{ backgroundColor: task.status.color }}></div>
        <div className="task-title">{task.name}</div>
      </div>

      <Button id="reset-active-task-in-timer" className="users" onClick={clearSelectedTask}>
        <CloseTimer />
      </Button>
    </div>
  )
}

export default TaskSelectedLabel
