import auth from './auth.json'
import dailyQuestion from './dailyQuestion.json'
import dashboard from './dashboard.json'
import document from './document.json'
import documents from './documents.json'
import global from './global.json'
import header from './header.json'
import importExport from './importExport.json'
import integrations from './integrations.json'
import layout from './layout.json'
import members from './members.json'
import modals from './modals.json'
import months from './months.json'
import notFound from './notFound.json'
import notifications from './notifications.json'
import profile from './profile.json'
import settings from './settings.json'
import sidebar from './sidebar.json'
import taskDetails from './taskDetails.json'
import tasks from './tasks.json'
import teams from './teams.json'
import translation from './translation.json'
import trash from './trash.json'
import workspaces from './workspaces.json'
import customFields from './customFields.json'
import theme from './theme.json'
import richText from './richText.json'

export default {
  auth,
  dailyQuestion,
  document,
  documents,
  header,
  richText,
  importExport,
  integrations,
  layout,
  members,
  modals,
  notifications,
  settings,
  notFound,
  taskDetails,
  tasks,
  teams,
  translation,
  customFields,
  trash,
  workspaces,
  profile,
  sidebar,
  global,
  dashboard,
  months,
  theme,
}
