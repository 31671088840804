import { SVGProps } from 'react'

type Props = {}

function DeleteIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9999 4.98681C13.9866 4.98681 13.9666 4.98681 13.9466 4.98681C10.4199 4.63348 6.89994 4.50015 3.41328 4.85348L2.05328 4.98681C1.77328 5.01348 1.52661 4.81348 1.49994 4.53348C1.47328 4.25348 1.67328 4.01348 1.94661 3.98681L3.30661 3.85348C6.85328 3.49348 10.4466 3.63348 14.0466 3.98681C14.3199 4.01348 14.5199 4.26015 14.4933 4.53348C14.4733 4.79348 14.2533 4.98681 13.9999 4.98681Z"
        fill="#944242"
      />
      <path
        d="M5.66663 4.3135C5.63997 4.3135 5.6133 4.3135 5.57997 4.30683C5.3133 4.26016 5.12663 4.00016 5.1733 3.7335L5.31997 2.86016C5.42663 2.22016 5.5733 1.3335 7.12663 1.3335H8.8733C10.4333 1.3335 10.58 2.2535 10.68 2.86683L10.8266 3.7335C10.8733 4.00683 10.6866 4.26683 10.42 4.30683C10.1466 4.3535 9.88663 4.16683 9.84663 3.90016L9.69997 3.0335C9.60663 2.4535 9.58663 2.34016 8.87997 2.34016H7.1333C6.42663 2.34016 6.4133 2.4335 6.3133 3.02683L6.15997 3.8935C6.11997 4.14016 5.90663 4.3135 5.66663 4.3135Z"
        fill="#944242"
      />
      <path
        d="M10.14 15.6667H5.85997C3.53331 15.6667 3.43997 14.3801 3.36664 13.3401L2.93331 6.62672C2.91331 6.35338 3.12664 6.11338 3.39997 6.09338C3.67997 6.08005 3.91331 6.28672 3.93331 6.56005L4.36664 13.2734C4.43997 14.2867 4.46664 14.6667 5.85997 14.6667H10.14C11.54 14.6667 11.5666 14.2867 11.6333 13.2734L12.0666 6.56005C12.0866 6.28672 12.3266 6.08005 12.6 6.09338C12.8733 6.11338 13.0866 6.34672 13.0666 6.62672L12.6333 13.3401C12.56 14.3801 12.4666 15.6667 10.14 15.6667Z"
        fill="#944242"
      />
      <path
        d="M9.10672 12H6.88672C6.61339 12 6.38672 11.7733 6.38672 11.5C6.38672 11.2267 6.61339 11 6.88672 11H9.10672C9.38005 11 9.60672 11.2267 9.60672 11.5C9.60672 11.7733 9.38005 12 9.10672 12Z"
        fill="#944242"
      />
      <path
        d="M9.66671 9.3335H6.33337C6.06004 9.3335 5.83337 9.10683 5.83337 8.8335C5.83337 8.56016 6.06004 8.3335 6.33337 8.3335H9.66671C9.94004 8.3335 10.1667 8.56016 10.1667 8.8335C10.1667 9.10683 9.94004 9.3335 9.66671 9.3335Z"
        fill="#944242"
      />
    </svg>
  )
}

export default DeleteIcon
