import Button from '@src/modules/shared/components/Button/Button'
import { store, useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { ListType } from '@src/modules/spaces/components/List/List'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { resetSocketEvents } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { getSubtasks } from '@src/modules/tasks/data/tasksSlice/tasksThunk'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useGetTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { Avatar, Checkbox, Rate, Tooltip } from 'antd'
import React, { Fragment, memo, useEffect, useState } from 'react'
import { Tree } from 'react-complex-tree'
import { concatStatusFilter } from '../../Board/StatusCard/StatusCard'
import { TaskSkeleton } from '../utils/renderItem'
import { ReactComponent as PlusIcon } from './../../../assets/icons/status/status-card-button.svg'
import { ReactComponent as ArrowIcon } from './../../../assets/icons/task/Arrow.svg'
import { ReactComponent as ArrowHorizentalIcon } from './../../../assets/icons/task/Arrow_hori.svg'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import { Flag } from '../../TaskPriority/TaskPriority'
import { useTranslation } from 'react-i18next'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import { getTasksChildrenIds } from '../utils/listViewManagment'
import ListHeader from '../ListHeader'
import { isArray } from 'lodash'
import { parseBoolean } from '@src/modules/shared/utils/parseBoolean'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { fetchFavicon, getOriginFromUrl } from '../../CustomFields/WebsiteField/WebsiteField'

const userNumber = 3

export const tasksColumnHeader = (t) => ({
  priority: (data, total_items) => (
    <div className="board-header-priority card-title">
      <Flag color={data?.color} stroke={data?.color || '#B8B6B6'} /> {data?.name}{' '}
      <div className="status-info">{total_items || 0}</div>
    </div>
  ),
  users: (data, total_items) => (
    <div className="board-header-users card-title">
      {isArray(data?.value) ? (
        <Avatar.Group>
          {data?.value?.map((user, index: number) =>
            index < userNumber ? (
              <Tooltip title={user?.username} key={index}>
                <Avatar src={user?.avatar}>
                  {user?.username && user?.username[0]?.toUpperCase()}
                </Avatar>
              </Tooltip>
            ) : index === userNumber ? (
              <React.Fragment key={index}>
                <span className="members-rest">+{data?.value?.length - userNumber}</span>
              </React.Fragment>
            ) : null,
          )}
        </Avatar.Group>
      ) : (
        <div>{data?.value}</div>
      )}

      {isArray(data?.value) && (
        <div className="assigned-users">
          {data?.value?.map((user, i: number) =>
            i < userNumber ? (
              <Fragment key={i}>
                {user?.username || 'user not exist'}
                {i + 1 < userNumber && ', '}
              </Fragment>
            ) : (
              i === userNumber && <> ...</>
            ),
          )}
        </div>
      )}
      <div className="status-info">{total_items || 0}</div>
    </div>
  ),
  status: (data, total_items) => (
    <div className="card-title" style={{ background: `${data?.color}50` }}>
      <div
        className="status-color"
        style={{
          background: data?.color,
        }}
      ></div>
      <Tooltip title={data.name}>
        <div className="status-label">
          <OverflowTooltip>{data?.name}</OverflowTooltip>
        </div>
      </Tooltip>
      <div className="status-info">{total_items || 0}</div>
    </div>
  ),
  checkbox: (data, total_items) => {
    return (
      <div className="card-title">
        <Checkbox checked={parseBoolean(data?.value)} />
        <div className="status-info">{total_items || 0}</div>
      </div>
    )
  },
  website: (data, total_items) => {
    const value = data?.value
    const website = {
      url: data?.value,
      domain: getOriginFromUrl(value),
      faviconUrl: fetchFavicon(value),
    }

    return (
      <div className="card-title">
        {website?.domain && <img src={website.faviconUrl} alt="favicon" />}
        <OverflowTooltip style={{ maxWidth: '300px' }}>
          {website.domain || t('no-domain')}
        </OverflowTooltip>
        <div className="status-info">{total_items || 0}</div>
      </div>
    )
  },
  label: (data, total_items) => {
    return (
      <div className="card-title labels-header">
        <div className="labels-list">
          {isArray(data?.value) && data?.value?.length > 0
            ? data?.value?.map((label, index) => (
                <div key={index} className="label">
                  {label}
                </div>
              ))
            : t('no-labels')}
        </div>
        <div className="status-info">{total_items || 0}</div>
      </div>
    )
  },
  rating: (data, total_items) => {
    return (
      <div className="card-title">
        <Rate value={data?.value} />
        <div className="status-info">{total_items || 0}</div>
      </div>
    )
  },
  dropdown: (data, total_items) => {
    return (
      <div className="card-title dropdown-header">
        <div className="dropdown-content">{data?.value}</div>
        <div className="status-info">{total_items || 0}</div>
      </div>
    )
  },
})

export const getTasksChildren = async (taskId: string, listId: string) => {
  const { response } = await store
    .dispatch(
      getSubtasks({
        fileId: listId,
        limit: 200,
        skip: 1,
        options: JSON.stringify({
          where: {
            parentId: { operation: '$Equal', value: taskId },
          },
          order: { subtaskCount: 'ASC' },
        }),
      }),
    )
    .unwrap()
  return response.payload
}

export const checkCanOnlyView = (list) => {
  const userId = store.getState()?.auth?.user?.user?.id
  const targetPermission = list?.permissions?.find((per) => per?.userId === userId)
  const targetRole = targetPermission?.role?.permissions?.find(
    (per) => per?.resourceName === 'list',
  )
  return targetRole?.actions ? targetRole?.actions[0] === 'READ' : false
}

interface StatusTreeProps {
  status: statusType & { value: { id: string }[]; notAssigned?: boolean }
  list: ListType
  search: string
  groupBy: {
    id: string
    custom: boolean
    type: GroupByFields
  }
  isDisabled: boolean
  filterByMe: boolean
  setTasks: (tasks: TaskType[], treeId?: string) => void
  expandedItems: string[]
  removeSkeletons: (id: string) => void
  addSkeletons: (item) => void
  setSubtasks: (subtasks: TaskType[], parentTask, treeId?: string) => void
  tree: any
  unSelectAll: () => void
  collapseTree: () => void
  isOpen: boolean
  isLoading: boolean
  selectAll: () => void
  totalItems: number
  setTasksCount: (e: any) => void
  currentPage: number
  addTask: (t: boolean, b: boolean) => void
}

const StatusTree = ({
  status,
  list,
  setTasks,
  setSubtasks,
  expandedItems,
  addSkeletons,
  removeSkeletons,
  tree,
  selectAll,
  unSelectAll,
  isDisabled,
  filterByMe,
  search,
  isLoading: isGettingTasks,
  isOpen,
  collapseTree,
  currentPage = 1,
  totalItems,
  setTasksCount,
  addTask,
  groupBy,
}: StatusTreeProps) => {
  const { t } = useTranslation(['tasks'])
  const [selftLoading, setSelfLoading] = useState<boolean>(false)
  const { user } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const { headers } = useAppSelector((state) => state.tasks)
  const [isAllSelected, setSelectAll] = useState<boolean>(false)
  const { createdTask, clearSelected } = useAppSelector((state) => state.tasks) //socket updates "socket events"
  const { tasks: tasksFilter } = useAppSelector((state) => state.filter)
  const showAddButton = !tree['fake-task']

  const filter = concatStatusFilter(
    {
      ...(groupBy?.type === GroupByFields.STATUS
        ? { status: { operation: '$Equal', value: status.id } }
        : {}),
      ...(search ? { name: { operation: '$contains', value: search } } : {}),
      ...(groupBy?.type === GroupByFields.PRIORITY
        ? { priority: { operation: '$Equal', value: status.id } }
        : {}),
      ...(groupBy?.type === GroupByFields.ASSIGNEE
        ? {
            assigned_to: {
              operation: status?.notAssigned ? '$Equal' : '$Assigned',
              value:
                status?.id !== 'empty' ? status?.value?.map((user) => user.id).join(',') : null,
            },
          }
        : {}),
      ...(groupBy?.custom
        ? {
            customFieldsValues:
              status?.id === 'empty'
                ? {
                    operation: '$in',
                    value: [],
                  }
                : {
                    operation: '$elemMatch',
                    value: {
                      customFieldId: {
                        operation: '$Equal',
                        value: groupBy?.id,
                      },
                      value: { operation: '$jsonbEquals', value: status?.value },
                    },
                  },
          }
        : {}),
      parentId: { operation: '$Equal', value: null },
      ...(filterByMe && user?.user?.id
        ? { assigned_to: { operation: '$in', value: [user.user.id] } }
        : {}),
    },
    tasksFilter,
    list?.id,
    headers,
    groupBy,
  )

  const expandManagement = (tree) => {
    expandedItems?.forEach(async (itemId) => {
      const item = tree[itemId]
      const { totalSubtask } = item?.content || {}
      const totalCurrentChildren = getTasksChildrenIds(item)?.length
      if (
        ((totalCurrentChildren || totalSubtask === 0) && totalCurrentChildren === totalSubtask) ||
        item?.isLoading
      )
        return

      addSkeletons(item)
      const response = await getTasksChildren(itemId, list?.id)
      removeSkeletons(item?.id)
      setSubtasks(response?.payload, item)
    })
  }

  const selectHandler = () => {
    if (totalItems === 0) return
    setSelectAll(!isAllSelected)
    if (isAllSelected) {
      unSelectAll()
    } else selectAll()
  }

  const addTaskHandler = () => {
    if (!isOpen) collapseTree()
    addTask(true, false)
  }

  const { data, isLoading, refetch } = useGetTasksQuery(
    {
      fileId: list?.id,
      skip: 1,
      options: filter,
      limit: currentPage * 20,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const { metadata, payload: tasks } = data?.payload || {}

  useEffect(() => {
    setSelfLoading(true)

    //Need to fix
    setTimeout(() => {
      setSelfLoading(false)
    }, 2000)
  }, [filterByMe, filter, search])

  useEffect(() => {
    if (clearSelected) setSelectAll(false)
  }, [clearSelected])

  useEffect(() => {
    expandManagement(tree)
  }, [expandedItems, data])

  //socket update
  useEffect(() => {
    const syncData = (task) => {
      if (task && task?.status && task?.fileId === list?.id) {
        //TODO need to improve this logic
        const treeId =
          task?.parentId ||
          (groupBy?.type === GroupByFields.ASSIGNEE
            ? task[groupBy?.type]
                ?.map(({ id }) => id)
                .reverse()
                .join('&') || 'not_assigned_users'
            : groupBy?.type === GroupByFields.STATUS
              ? task[groupBy?.type]?.id
              : groupBy?.type === GroupByFields.PRIORITY
                ? task[groupBy?.type]
                : groupBy?.custom
                  ? task?.customFieldsValues?.find((field) => field.customFieldId === groupBy?.id)
                      ?.value || 'empty'
                  : null)

        const item = tree[task?.parentId || treeId]
        const oldChildrens = getTasksChildrenIds(item)
          ?.map((id: string) => tree[id]?.content)
          .filter((item: TaskType) => !!item && task?.id !== item?.id)

        if (task?.parentId) setSubtasks([...(oldChildrens || []), task], item)
        else if (status?.id === item?.index) {
          let { isBottom } = tree['fake-task'] || {}
          if (task?.isBottom !== undefined && !tree['fake-task']) isBottom = task?.isBottom
          setTasksCount({ ...metadata, total_items: totalItems + 1, loadedTasks: currentPage * 20 })
          if (isBottom) setTasks([...(oldChildrens || []), task])
          else setTasks([task, ...(oldChildrens || [])])
        }
      }
    }

    if (isArray(createdTask)) {
      createdTask.forEach((task) => {
        const alreadyCreated = tree[task?.id]
        if (!alreadyCreated) {
          syncData(task)
        }
      })
    } else {
      const alreadyCreated = tree[createdTask?.id]
      if (!alreadyCreated) syncData(createdTask)
    }

    if (tasksFilter || filterByMe || search)
      refetch()
        .unwrap()
        .then(() => setTasksHandler())
    dispatch(resetSocketEvents())
  }, [createdTask])

  useEffect(() => {
    if (!isOpen) return
    refetch()
  }, [filter, filterByMe])

  const setTasksHandler = () => {
    if (!tasks) return
    setTasks(tasks)
    setTasksCount({ ...metadata, loadedTasks: currentPage * 20 })
    removeSkeletons(status?.id)
  }

  useEffect(() => {
    if (tasks) {
      setTasksHandler()
      setSelfLoading(false)
      if (isAllSelected) selectAll()
    }
  }, [tasks])

  return (
    <div
      className="status-list"
      onDragOver={() => !isOpen && collapseTree()}
      style={{ display: isDisabled ? 'none' : 'block' }}
    >
      <div className="status-list-header">
        <div className="col1">
          <div onClick={selectHandler}>
            <Checkbox
              className="tree-checkbox"
              style={isAllSelected ? { opacity: 1 } : totalItems === 0 ? { opacity: 0 } : {}}
              checked={isAllSelected}
            />
          </div>

          <RTLStyleDecorator disableRTLAdjustments>
            <div className="colapse-button-container">
              <Button className="colapse-button" onClick={collapseTree}>
                {isOpen ? <ArrowIcon /> : <ArrowHorizentalIcon />}
              </Button>
            </div>
          </RTLStyleDecorator>

          {tasksColumnHeader(t)?.[groupBy?.type]?.(status, totalItems) || (
            <div>{t('not-found')}</div>
          )}

          {showAddButton && (
            <Button
              disabled={isLoading}
              className="status-list-button"
              style={{ background: status?.color }}
              onClick={addTaskHandler}
            >
              <PlusIcon />
            </Button>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="list-header">
          <ListHeader />
        </div>
      )}

      <div
        className="list-tree"
        onDragOver={(e) =>
          totalItems === 0 ? e.currentTarget.classList.add('drag-over-tree') : null
        }
        onDragLeave={(e) => e.currentTarget.classList.remove('drag-over-tree')}
        onDrop={(e) => e.currentTarget.classList.remove('drag-over-tree')}
        style={{ display: isOpen && !isLoading && !selftLoading ? 'block' : 'none' }}
      >
        <Tree treeId={status.id} rootItem={status.id} treeLabel="Tree Example" />

        {!isGettingTasks && showAddButton && totalItems > 0 ? (
          <div className="add-bottom-task" onClick={() => addTask(false, true)}>
            <p>{t('Add task')}</p>
          </div>
        ) : null}
      </div>
      {isGettingTasks || isLoading || selftLoading ? (
        <div className="tree-skeletons">
          {Array(getLeftTasks(totalItems, tasks?.length || 0))
            .fill('')
            .map((_, i) => (
              <TaskSkeleton depth={-1} key={i} />
            ))}
        </div>
      ) : null}
    </div>
  )
}

export default memo(StatusTree)

const getLeftTasks = (totalItems: number, loadedTasks: number = 0) => {
  if (totalItems - loadedTasks > 10) {
    return 10
  } else if (totalItems) return Math.abs(totalItems - loadedTasks)
  else if (!totalItems) return 10
  return 0
}
