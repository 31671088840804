/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { addManualTimer, startTimer, closeTimer, deleteTimer, getTimers, editTimer } from './thunks'
import { message } from 'antd'
import { TaskType } from '../tasksSlice/tasksTypes'
import { ITimer } from '@softylines/tam-types'

export interface Pagination {
  total_items: number
  rows_per_page: number
  current_page: number
  last_page: number
  next: {
    page: number
    limit: number
  }
  prev: {
    page: number
    limit: number
  }
}

export interface TimerState {
  error: any
  status: string
  activeTimer: null | ITimer
  timers: ITimer[]
  timersMeta?: Pagination
  // FOR THE TIMER COMPONENT
  timer: number
  isRunning: boolean
  startDate: string
  timeInSeconds: number
  currentTimer: number
  currentTask: TaskType | null
  activeTaskTimer: string
  isRestored: boolean
}

const initialState: TimerState = {
  error: null,
  status: 'idle',
  activeTimer: null,
  timers: [],
  timersMeta: null,
  //M FOR THE TIMER COMPONENT
  timer: 0,
  isRunning: false,
  activeTaskTimer: null,
  startDate: '',
  timeInSeconds: 0,
  currentTimer: 0,
  currentTask: null,
  isRestored: false,
}

let intervalId

const startTimerThunk = (taskId) => (dispatch, getState) => {
  if (!intervalId) {
    intervalId = setInterval(() => {
      const { timer, currentTimer, activeTaskTimer } = getState().timer
      if (taskId === activeTaskTimer) {
        dispatch(setIsRunning(true))
        dispatch(setTimer(timer + 1))
      }
      dispatch(setCurrentTimer(currentTimer + 1))
    }, 1000)
  }
}

const stopTimerThunk = () => (dispatch) => {
  clearInterval(intervalId)
  intervalId = null
  dispatch(setIsRunning(false))
  dispatch(setCurrentTimer(0))
}

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimer: (state, action) => {
      state.timer = action.payload
    },
    setIsRunning: (state, action) => {
      state.isRunning = action.payload
    },
    setIsActiveTaskTimer: (state, action) => {
      state.activeTaskTimer = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setTimeInSeconds: (state, action) => {
      state.timeInSeconds = action.payload
    },
    setCurrentTimer: (state, action) => {
      state.currentTimer = action.payload
    },
    handleCounterProcess: (state) => {
      if (state.isRunning) {
        state.isRunning = false
      } else {
        state.isRunning = true
      }
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload
    },
    setRestored: (state, action) => {
      state.isRestored = action.payload
    },
  },

  extraReducers: (builder) => {
    // ADD MANUAL TIMER
    builder.addCase(addManualTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(addManualTimer.fulfilled, (state, action: PayloadAction<any>) => {
      state.timers = [...state.timers, action.payload]
      state.status = 'idle'
    })
    builder.addCase(addManualTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
      message.error(action?.payload)
    })
    // START TIMER
    builder.addCase(startTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(startTimer.fulfilled, (state, action: PayloadAction<ITimer>) => {
      state.activeTimer = action.payload
      state.status = 'idle'
    })
    builder.addCase(startTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    // CLOSE TIMER
    builder.addCase(closeTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(closeTimer.fulfilled, (state) => {
      state.activeTimer = null
      state.status = 'idle'
    })
    builder.addCase(closeTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(deleteTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(deleteTimer.fulfilled, (state, action: PayloadAction<any>) => {
      state.activeTimer = action.payload
      state.status = 'idle'
    })
    builder.addCase(deleteTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    // GET TIMERS
    builder.addCase(getTimers.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getTimers.fulfilled, (state, action: PayloadAction<any>) => {
      state.timers = action.payload.payload
      state.timersMeta = action.payload.metadata
      state.status = 'idle'
    })
    builder.addCase(getTimers.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    // EDIT TIMER
    builder.addCase(editTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(editTimer.fulfilled, (state, action: PayloadAction<any>) => {
      state.timers = state.timers.map((timer) => {
        if (timer.id === action.payload.id) {
          return {
            ...timer,
            duration: action.payload.duration,
          }
        }
        return timer
      })
      state.status = 'idle'
    })

    builder.addCase(editTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {
  setTimer,
  setIsRunning,
  setIsActiveTaskTimer,
  setStartDate,
  setTimeInSeconds,
  setCurrentTimer,
  handleCounterProcess,
  setCurrentTask,
  setRestored,
} = timerSlice.actions // eslint-disable-line
export default timerSlice.reducer
export { startTimerThunk, stopTimerThunk }
