import { LoadingOutlined } from '@ant-design/icons'
import {
  getRedirectUrl,
  importDataFromCsv,
  importDataProcess,
} from '@src/modules/settings/data/ImportExport/ImportExportThunk'
import { useAppDispatch } from '@src/modules/shared/store'
import { useAppSelector } from '@src/modules/shared/store/index'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { Modal, Spin, message } from 'antd'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BaseCampSVG } from '../../../../assets/icons/importExport/Basecamp.svg'
import { ReactComponent as ClickUpSVG } from '../../../../assets/icons/importExport/ClickUp.svg'
import { ReactComponent as ActiveClickUpSVG } from '../../../../assets/icons/importExport/ClickUpActive.svg'
import { ReactComponent as TrelloSVG } from '../../../../assets/icons/importExport/Trello.svg'
import { ReactComponent as WrikeSVG } from '../../../../assets/icons/importExport/Wrike.svg'
import { ReactComponent as AsanaSVG } from '../../../../assets/icons/importExport/asana.svg'
import { ReactComponent as ActiveCsvSVG } from '../../../../assets/icons/importExport/csv-active.svg'
import { ReactComponent as CsvSVG } from '../../../../assets/icons/importExport/csv.svg'
import { ReactComponent as ActiveJiraSVG } from '../../../../assets/icons/importExport/jira-active.svg'
import { ReactComponent as JiraSVG } from '../../../../assets/icons/importExport/jira.svg'
import { ReactComponent as MondaySVG } from '../../../../assets/icons/importExport/monday.svg'
import { ReactComponent as NotionSVG } from '../../../../assets/icons/importExport/notion.svg'
import { ReactComponent as TodoistSVG } from '../../../../assets/icons/importExport/todoist.svg'
import CloseIcon from '../icons/CloseIcon'
import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'

type Props = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
}

const importFromList = [
  {
    icon: ClickUpSVG,
    label: 'click up',
    id: '1',
    importedFile: null,
    allowed: true,
    activeIcon: ActiveClickUpSVG,
  },
  {
    icon: CsvSVG,
    label: 'csv',
    id: '2',
    importedFile: null,
    allowed: true,
    activeIcon: ActiveCsvSVG,
  },
  { icon: AsanaSVG, label: 'asana', id: '3', importedFile: null, allowed: false },
  { icon: BaseCampSVG, label: 'baseCamp', id: '4', importedFile: null, allowed: false },
  { icon: MondaySVG, label: 'monday', id: '5', importedFile: null, allowed: false },
  { icon: TrelloSVG, label: 'trello', id: '6', importedFile: null, allowed: false },
  { icon: WrikeSVG, label: 'wrike', id: '7', importedFile: null, allowed: false },
  {
    icon: JiraSVG,
    label: 'jira',
    id: '8',
    importedFile: null,
    allowed: false,
    activeIcon: ActiveJiraSVG,
  },
  { icon: TodoistSVG, label: 'todoist', id: '9', importedFile: null, allowed: false },
  { icon: NotionSVG, label: 'notion', id: '10', importedFile: null, allowed: false },
]

function ImportModal({ isModalOpen, handleOk, handleCancel }: Props) {
  const { t } = useTranslation(['modals'])
  const { status } = useAppSelector((state) => state.importExport)
  const [importDataFrom, setImportDataFrom] = useState(importFromList)
  const lang = localStorage.getItem('i18nextLng')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  let newUrl: string

  const ModalFooter = () => {
    return (
      <div className="import-modal-footer">
        <Button className="cancel" onClick={handleCancel}>
          {t('import.cancel')}
        </Button>

        <Button className="ok" onClick={importDataHandler} disabled={status === 'loading'}>
          {status === 'loading' ? (
            <>
              {t('import.loading')}
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 20,
                      color: '#fff',
                    }}
                    spin
                  />
                }
              />
            </>
          ) : (
            t('import.start_import')
          )}
        </Button>
      </div>
    )
  }

  const importDataHandler = () => {
    importDataFrom.forEach((data) => {
      if (!data.importedFile) return
      dispatch(importDataFromCsv({ file: data.importedFile }))
        .unwrap()
        .then((res) => {
          window.localStorage.setItem('fileKey', res?.data[0]?.key)

          dispatch(importDataProcess({ key: res?.data[0]?.key, repair: 'test' })).then(() => {
            message.success(t('import.data_created'))
            navigate(
              updateURL({
                label: 'view',
                value: 'inSpace',
              }),
            )
            handleOk()
          })
        })
        .catch(() => message.error(t('import.cannot_import')))
    })
  }

  const setImportedFile = (event, id) => {
    const file = event.target.files[0]
    if (file)
      setImportDataFrom((prevData) => {
        return prevData.map((data) => (data.id === id ? { ...data, importedFile: file } : data))
      })
  }

  return (
    <div className="import-modal">
      <Modal
        centered
        title={<h1 className="import-modal-title">{t('import.import_data')}</h1>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<ModalFooter />}
        closeIcon={false}
        className="import-modal"
      >
        <CloseIcon className={`close-import-modal ${lang === 'ar' && 'ar-close-import-modal'}`} />

        <div className="import-modal-container">
          <p>{t('import.from_where')}</p>

          <div className="icons">
            {importDataFrom.map((data, i) => {
              const Icon = !data.allowed ? data?.icon : data?.activeIcon || Fragment

              return (
                <label htmlFor={`input-${data.id}`} key={i} className="import-data-input">
                  <Icon
                    className="icon"
                    style={!data.allowed ? { cursor: 'not-allowed' } : {}}
                    onClick={() => {
                      if (data?.label === 'click up') {
                        dispatch(getRedirectUrl())
                          .unwrap()
                          .then((res: any) => {
                            newUrl = res?.replace(
                              /(redirect_uri=)[^&]+/,
                              'redirect_uri=' + window.location.origin + '/settings/import-export',
                            )
                            window.location.href = newUrl
                          })
                      }
                    }}
                  />

                  <Input
                    type="file"
                    id={`input-${data.id}`}
                    onChange={(e) => setImportedFile(e, data.id)}
                    disabled={!data?.allowed || data?.label === 'click up'}
                    accept=".csv"
                    variant="label-inline"
                    containerClassName="import-input"
                  />
                </label>
              )
            })}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ImportModal
