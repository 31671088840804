/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  addManualGlobalTimer,
  startGlobalTimer,
  closeGlobalTimer,
  deleteGlobalTimer,
  getGlobalTimers,
} from './thunks'
import { GlobalTimerType } from './types'
import { message } from 'antd'
import { TaskType } from '../tasksSlice/tasksTypes'

export interface Pagination {
  total_items: number
  rows_per_page: number
  current_page: number
  last_page: number
  next: {
    page: number
    limit: number
  }
  prev: {
    page: number
    limit: number
  }
}

export interface GlobalTimerState {
  error: any
  status: string
  activeGlobalTimer: null | GlobalTimerType
  globalTimers: GlobalTimerType[]
  globalTimersMeta?: Pagination
  globalTimer: number
  isRunning: boolean
  startDate: string
  globalTimeInSeconds: number
  globalTimeMins: number
  globalTimeHrs: number
  currentGlobalTimer: number
  currentGlobalTask: TaskType | null
  globalNote: string
  selectedTaskId?: string | null
}

const initialState: GlobalTimerState = {
  error: null,
  status: 'idle',
  activeGlobalTimer: null,
  globalTimers: [],
  globalTimersMeta: null,
  globalTimer: 0,
  isRunning: false,
  startDate: '',
  globalTimeInSeconds: 0,
  globalTimeMins: 0,
  globalTimeHrs: 0,
  currentGlobalTimer: 0,
  currentGlobalTask: null,
  globalNote: '',
  selectedTaskId: undefined,
}

let intervalId

const startGlobalTimerThunk =
  (dontRunIt = false) =>
  (dispatch, getState) => {
    if (!intervalId) {
      intervalId = setInterval(() => {
        !dontRunIt && dispatch(setIsRunning(true))
        const { globalTimer, currentGlobalTimer } = getState().globalTimer
        dispatch(setGlobalTimer(globalTimer + 1))
        dispatch(setCurrentGlobalTimer(currentGlobalTimer + 1))
      }, 1000)
    }
  }

const stopGlobalTimerThunk = () => (dispatch) => {
  clearInterval(intervalId)
  intervalId = null
  dispatch(setIsRunning(false))
  dispatch(setCurrentGlobalTimer(0))
  dispatch(setCurrentGlobalTask(null))
  dispatch(setGlobalTimer(0))
  dispatch(setGlobalNote(''))
  dispatch(setSelectedTaskId(null))
}

const GlobaltimerSlice = createSlice({
  name: 'Globaltimer',
  initialState,
  reducers: {
    setGlobalTimer: (state, action) => {
      state.globalTimer = action.payload
    },
    setIsRunning: (state, action) => {
      state.isRunning = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setGlobalTimeInSeconds: (state, action) => {
      state.globalTimeInSeconds = action.payload
    },
    setCurrentGlobalTimer: (state, action) => {
      state.currentGlobalTimer = action.payload
    },
    handleGlobalCounterProcess: (state) => {
      if (state.isRunning) {
        state.isRunning = false
      } else {
        if (state.globalTimer === 0) state.globalTimer = 1
        state.isRunning = true
      }
    },
    setCurrentGlobalTask: (state, action) => {
      state.currentGlobalTask = action.payload
    },
    setGlobalNote: (state, action) => {
      state.globalNote = action.payload
    },
    setGlobalTimeHrs: (state, action) => {
      state.globalTimeHrs = action.payload
    },
    setGlobalTimeMins: (state, action) => {
      state.globalTimeMins = action.payload
    },
    setSelectedTaskId: (state, action) => {
      state.selectedTaskId = action.payload
    },
  },

  extraReducers: (builder) => {
    // ADD MANUAL GlobalTIMER
    builder.addCase(addManualGlobalTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(addManualGlobalTimer.fulfilled, (state, action: PayloadAction<any>) => {
      state.globalTimers = [...state.globalTimers, action.payload]
      state.status = 'idle'
    })
    builder.addCase(addManualGlobalTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
      message.error(action?.payload)
    })
    // START GlobalTIMER
    builder.addCase(startGlobalTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(startGlobalTimer.fulfilled, (state, action: PayloadAction<GlobalTimerType>) => {
      state.activeGlobalTimer = action.payload
      state.status = 'idle'
    })
    builder.addCase(startGlobalTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    // CLOSE GlobalTIMER
    builder.addCase(closeGlobalTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(closeGlobalTimer.fulfilled, (state) => {
      state.activeGlobalTimer = null
      state.status = 'idle'
    })
    builder.addCase(closeGlobalTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(deleteGlobalTimer.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(deleteGlobalTimer.fulfilled, (state) => {
      // state.activeGlobalTimer =
      state.status = 'idle'
    })
    builder.addCase(deleteGlobalTimer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    // GET GlobalTIMERS
    builder.addCase(getGlobalTimers.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getGlobalTimers.fulfilled, (state, action: PayloadAction<any>) => {
      state.globalTimers = action.payload.payload
      state.globalTimersMeta = action.payload.metadata
      state.status = 'idle'
    })
    builder.addCase(getGlobalTimers.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {
  setGlobalTimer,
  setIsRunning,
  setStartDate,
  setGlobalTimeInSeconds,
  setCurrentGlobalTimer,
  handleGlobalCounterProcess,
  setCurrentGlobalTask,
  setGlobalNote,
  setGlobalTimeHrs,
  setGlobalTimeMins,
  setSelectedTaskId,
} = GlobaltimerSlice.actions // eslint-disable-line
export default GlobaltimerSlice.reducer
export { startGlobalTimerThunk, stopGlobalTimerThunk }
