import { Empty, Popover } from 'antd'

import OnePostTimersList from './OnePostTimersList'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { formatTime } from '../../../Timer/AutoTimer/AutoTimer'
import { formatDate } from '@src/modules/shared/utils/date'
import TimersListSkeleton from './OnePostTimersList/TimersListSkeleton'
import { Tooltip } from 'antd'

interface Props {
  tasks: TaskType[]
  isLoading?: boolean
}

function GlobalTimersList({ tasks, isLoading }: Props) {
  if (isLoading) return <TimersListSkeleton />
  if (tasks.length === 0)
    return (
      <div
        style={{
          minHeight: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Empty />
      </div>
    )
  if (!tasks) return null
  return (
    <div className="global-timers-list">
      {tasks.map((task) => (
        <Popover
          trigger={'click'}
          key={task.id}
          placement="leftTop"
          title={null}
          content={<OnePostTimersList task={task} />}
          overlayClassName="global-timer-list-popover"
        >
          <div
            className="global-timer-item"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <svg
              width="36"
              height="37"
              viewBox="0 0 36 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.498535" width="36" height="36" rx="18" fill="#CCD2E3" />
              <path
                d="M22.6037 18.1453L16.5215 14.4283C15.8551 14.0211 15 14.5007 15 15.2816V22.7155C15 23.4964 15.8551 23.976 16.5215 23.5688L22.6037 19.8518C23.2418 19.4619 23.2418 18.5352 22.6037 18.1453Z"
                fill="white"
              />
            </svg>

            <div className="timer-infos">
              <div className="top">
                <div className="status" style={{ backgroundColor: task?.status?.color }}></div>

                <Tooltip title={task?.name}>
                  <div className="title">
                    {task?.name?.length > 10 ? task?.name?.slice(0, 10) + '...' : task?.name}
                  </div>
                </Tooltip>
              </div>
              <div className="footer">
                <div id={`format-time-${task.id}`} className="time">
                  {formatTime(task?.totalTime || 0)}
                </div>
                <div className="date">{formatDate(task.createdAt?.toString())}</div>
              </div>
            </div>

            <div id={`task-timer-${task.id}`} className="count-timers">
              {task?.timers?.length || 0}
            </div>
          </div>
        </Popover>
      ))}
    </div>
  )
}

export default GlobalTimersList
