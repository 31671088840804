import { SVGProps } from 'react'

type Props = {}

function StartIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.782227"
        y="0.627441"
        width="19.4355"
        height="17.7455"
        rx="7.20088"
        fill="#9747FF"
      />
      <g clipPath="url(#clip0_4151_25822)">
        <path
          d="M13.4899 8.08095L10.1316 5.61841C9.86931 5.42634 9.55894 5.31065 9.23492 5.28417C8.91089 5.25769 8.58586 5.32145 8.29586 5.46839C8.00585 5.61532 7.7622 5.83969 7.5919 6.11663C7.42161 6.39356 7.33132 6.71224 7.33106 7.03735V11.9649C7.33055 12.2903 7.42036 12.6095 7.5905 12.8869C7.76063 13.1643 8.00441 13.3891 8.2947 13.5361C8.58499 13.6832 8.9104 13.7469 9.23471 13.72C9.55902 13.6931 9.86951 13.5767 10.1316 13.3838L13.4899 10.9213C13.7127 10.7578 13.8939 10.5441 14.0188 10.2975C14.1437 10.0509 14.2088 9.7784 14.2088 9.502C14.2088 9.2256 14.1437 8.95309 14.0188 8.70651C13.8939 8.45994 13.7127 8.24624 13.4899 8.08271V8.08095Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4151_25822">
          <rect
            width="8.45024"
            height="8.45024"
            fill="white"
            transform="translate(6.2749 5.2749)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StartIcon
