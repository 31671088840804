import { AddListItems, PermissionesAction, items } from '@src/modules/spaces/utils/spaceActions'
import { Avatar, Dropdown, message } from 'antd'
import { ReactComponent as SettingsIcon } from './../../../../spaces/assets/icons/space/settings.svg'
import { ReactComponent as PlusIcon } from './../../../../spaces/assets/icons/space/plus.svg'
import { ReactComponent as LockIcon } from './../../../../spaces/assets/icons/space/lock.svg'
import { setSpacesSocketEvent, spaceType } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { store, useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useEffect, useState } from 'react'
import { updateSpace } from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { setActiveSpace } from '@src/modules/shared/utils/activeSpace'
import { createDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { useTranslation } from 'react-i18next'
import Toggle from '../Toggle'
import OverflowTooltip from '../../OverflowTooltip'
import { isValidImageUrl } from '@src/modules/shared/utils/isValidURL'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '@src/modules/shared/components/Input/Input'

const SpaceRow = ({
  isExpanded,
  data,
  arrow,
  toggleExpand,
  select,
  readOnly,

  isActive,
  isImport,
  isSelected,
  halfSelect,
  multiple,
}) => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t('Invalid name')),
  })

  const formik = useFormik({
    initialValues: { name: data.name || '' },
    validationSchema,
    onSubmit: (values) => {
      if (values.name !== data.name) {
        dispatch(updateSpace({ id: data.id || '', name: values.name }))
          .unwrap()
          .then(() =>
            dispatch(
              setSpacesSocketEvent({
                updateType: 'updatedItem',
                updateEvent: { ...data, name: values.name },
              }),
            ),
          )
          .catch((err) => {
            message.error(err.message || t('Error while updating space name'))
          })
          .finally(() => setRename(false))
      } else {
        setRename(false)
      }
    },
  })

  const actions = {
    onrename: () => setRename(!isRename),
    onupdatestatuses: (data) => {
      store.dispatch(
        openModal({ id: 'update-status-modal', data: { ...data, list: data, type: 'spaceId' } }),
      )
    },
    oncreatefolder: (data: spaceType) => {
      store.dispatch(openModal({ id: 'folder-modal', data: { space: data } }))
    },
    oncreatelist: (data: spaceType) => {
      store.dispatch(openModal({ id: 'list-modal', data: { space: data } }))
    },
    onshare: (data: spaceType) => {
      store.dispatch(openModal({ id: 'share-modal', data: { ...data, type: 'spaceId' } }))
    },
    onspacesetting: (data: spaceType) => {
      store.dispatch(
        openModal({
          id: 'space-modal',
          data: { ...data, activeStep: 2, isEdit: true, modalTitle: t('space.edit_space') },
        }),
      )
    },
    oncreatedocument: (data: spaceType, navigate) => {
      store
        .dispatch(createDocument({ spaceId: data?.id, name: 'Docs' }))
        .unwrap()
        .then((res) => {
          store.dispatch(
            setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: res.payload }),
          )
          navigate(`/spaces/document/${res?.payload?.id}`)
        })
    },

    onchangeiconcolor: (data: spaceType) => {
      store.dispatch(
        openModal({
          id: 'space-modal',
          data: { ...data, isEdit: true, modalTitle: t('space.Colors and Icon') },
        }),
      )
    },
    ondelete: (data: spaceType) =>
      store.dispatch(openModal({ id: 'confirm-modal', data: { source: 'space', ...data } })),
  }

  const [isRename, setRename] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState(null)

  useEffect(() => {
    if (isActive && !isExpanded) toggleExpand()
  }, [isActive])

  useEffect(() => {
    const fetchImageUrl = async () => {
      const validUrl = await isValidImageUrl(data.icon)
      validUrl && setImageUrl(data.icon)
    }

    fetchImageUrl()
  }, [])

  return (
    <div className={`space-item space-row-tree ${isActive ? 'target-space' : ''}`}>
      <div
        className="col1"
        onClick={() => {
          toggleExpand()
          setActiveSpace(data?.id)
        }}
      >
        <div className="space-row-tree-arrow">{arrow}</div>
        <div className="space-row-tree-icon" onClick={isImport || select}>
          <Avatar
            src={imageUrl}
            shape="square"
            style={{ backgroundColor: imageUrl ? 'transparent' : data?.color || '' }}
          >
            {data.name && data.name[0].toUpperCase()}
          </Avatar>
        </div>
        <div className="space-row-tree-name" onClick={isImport || select}>
          {!isRename ? (
            <OverflowTooltip children={data.name} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Input
                type="text"
                name="name"
                disabled={false}
                variant="label-inline"
                onClick={(e) => e.stopPropagation()}
                onBlur={() => formik.handleSubmit()}
                className="sidebar-rename-input"
                size="xxs"
                formik={formik}
                autoFocus
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formik.handleSubmit()
                  } else if (e.key === 'Escape') {
                    setRename(false)
                  }
                }}
              />
            </form>
          )}
        </div>
      </div>

      {data.isPrivate ? <LockIcon className="space-lock-icon" /> : null}

      {!readOnly && (
        <div className="space-item-settings">
          <Dropdown
            overlayClassName="space-dropdown"
            trigger={['click']}
            menu={{
              items: items({ ...data, resource: 'spaces' }, [PermissionesAction.FULL], actions),
            }}
          >
            <div>
              <SettingsIcon />
            </div>
          </Dropdown>

          <Dropdown
            trigger={['click']}
            menu={{
              items: AddListItems(
                { ...data, resource: 'spaces' },
                [PermissionesAction.FULL],
                actions,
              ),
            }}
          >
            <div>
              <PlusIcon />
            </div>
          </Dropdown>
        </div>
      )}

      {(isImport || multiple) && (
        <Toggle select={select} isSelected={isSelected} halfSelect={halfSelect} />
      )}
    </div>
  )
}

export default SpaceRow
