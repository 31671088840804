import { useState } from 'react'
import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { ReactComponent as DotsSVG } from '../../../assets/icons/customFields/dots.svg'
import { Dropdown, message } from 'antd'
import { CopyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import Input from '@src/modules/shared/components/Input/Input'

export function getOriginFromUrl(url: string): string {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.origin
  } catch (error) {
    console.error('Invalid URL:', error)
    return ''
  }
}

export const fetchFavicon = (url: string) => {
  const domain = getOriginFromUrl(url)
  return `https://www.google.com/s2/favicons?domain=${domain}`
}

const WebsiteField = ({ task, saveCustomFieldValue, field, customFieldValue }: BaseFieldProps) => {
  const [isInput, setIsInput] = useState(false)
  const [error, setError] = useState(false)

  const value = field?.custom ? customFieldValue : task?.[field?.value]
  let website = null
  const fieldClickHandler = (e) => {
    if (!field.custom) return
    e.stopPropagation()
    if (!customFieldValue) setIsInput(true)
  }

  const focusOut = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setError(false)
      setIsInput(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value

      if (isValidUrl(value)) {
        saveCustomFieldValue(value)
        setIsInput(false)
      } else message.error('Invalid Website URL')
    } else if (e.key === 'Escape') setIsInput(false)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    if (isValidUrl(value)) setError(false)
    else setError(true)
  }

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i',
    )
    return !!urlPattern.test(urlString)
  }

  if (value)
    website = { url: value, domain: getOriginFromUrl(value), faviconUrl: fetchFavicon(value) }

  const menuItems = [
    {
      key: 'copy',
      icon: <CopyOutlined />,
      label: 'Copy URL',
      onClick: () => navigator.clipboard.writeText(value),
    },
    { key: 'edit', icon: <EditOutlined />, label: 'Edit', onClick: () => setIsInput(true) },
    {
      key: 'remove',
      icon: <DeleteOutlined />,
      label: 'Remove URL',
      onClick: () => saveCustomFieldValue(''),
    },
  ]

  return (
    <div
      className="list-string-field list-website-field"
      onClick={fieldClickHandler}
      tabIndex={0}
      onBlur={focusOut}
      onKeyDown={handleKeyDown}
    >
      {isInput ? (
        <Input
          size="xs"
          variant="label-inline"
          autoFocus
          type="text"
          defaultValue={website?.url}
          onChange={handleInputChange}
          style={error ? { borderColor: '#ed4141', color: '#ed4141' } : null}
        />
      ) : value ? (
        <a href={website?.url} target="__blank">
          <div className="domain-field">
            <img src={website?.faviconUrl} alt="favicon" />
            <OverflowTooltip>{website?.domain}</OverflowTooltip>
          </div>
        </a>
      ) : (
        '-'
      )}
      {value && !isInput && (
        <Dropdown trigger={['click']} menu={{ items: menuItems }}>
          <div className="domain-field-setting">
            <DotsSVG width={15} height={15} />
          </div>
        </Dropdown>
      )}
    </div>
  )
}

export default WebsiteField
