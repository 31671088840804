import { Modal, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import AsanaHighlightedSVG from '../icons/AsanaHighlightedSVG'
import CloseIcon from '../icons/CloseIcon'
import Button from '@src/modules/shared/components/Button/Button'

type Props = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
}

function ImportModalIsLoading({ isModalOpen, handleOk, handleCancel }: Props) {
  const { t } = useTranslation(['importExport'])

  const ModalFooter = () => {
    return (
      <div className="import-modal-footer">
        <Button className="cancel" onClick={handleCancel}>
          {t('Cancel import')}
        </Button>

        <Button className="ok" onClick={handleOk}>
          {t('Submit')}
        </Button>
      </div>
    )
  }

  return (
    <div className="import-modal import-modal-is-loading">
      <Modal
        centered
        title={<h1 className="import-modal-title">{t('import_data')}</h1>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<ModalFooter />}
        closeIcon={<CloseIcon style={{ marginTop: '20px' }} />}
      >
        <div className="import-modal-container import-modal-container-is-loading">
          <p className="paragraph">
            {t(`We're importing your data from`)} <span className="highlighted">Asana</span>!{' '}
            {t('Please wait')}
            ..
          </p>

          <div className="icons-is-loading">
            <AsanaHighlightedSVG className="icon" />
            <Progress className="progress-bar" percent={30} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ImportModalIsLoading
