import { DatePicker, DatePickerProps } from 'antd'
import { ReactComponent as CloseIcon } from '@src/modules/shared/assets/icons/components/close-icon.svg'
import dayjs from 'dayjs'

// import { useAppDispatch } from '@src/modules/shared/store'
import documentTextIcon from '@src/modules/shared/assets/icons/components/document-text.svg'
import saveIcon from '@src/modules/shared/assets/icons/components/save.svg'
import clockIcon from '@src/modules/shared/assets/icons/components/timer.svg'

import React, { MutableRefObject, useEffect, useState } from 'react'

// import CommentInput from '../CommentInput'
import { TimerType } from '@src/modules/tasks/data/timerData/types'
import { convertToDayjs } from '@src/modules/spaces/utils/ConvertStringTotime'
import { useAppDispatch } from '@src/modules/shared/store'
import { editTimer } from '@src/modules/tasks/data/timerData/thunks'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useGetTaskQuery } from '@src/modules/tasks/services/tasksApi'
import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'
// import axiosInstance from '@src/modules/shared/utils/axios'

const EditTimerModal = ({
  timer,
  setEditOpen,
  task,
}: {
  timer: TimerType
  setEditOpen: Function
  task?: TaskType
}) => {
  //   const dispatch = useAppDispatch()
  //   const [loading, setLoading] = useState<boolean>()

  const [date, setDate] = useState<any>('')
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [note, setNote] = useState<string>('')
  const dispatch: Function = useAppDispatch()
  const editTimerModalRef = React.useRef() as MutableRefObject<any>
  const { refetch } = useGetTaskQuery({ id: task?.id as string }, { skip: !task?.id })
  useEffect(() => {
    if (timer) {
      setDate(timer.startTime)
      if (!timer.endTime) {
        setHours(0)
        setMinutes(0)
      } else {
        const duration = timer.duration
        const hours = Math.floor(+duration / 3600)
        const minutes =
          Math.floor(+duration / 60 - hours * 60) > 0 ? Math.floor(+duration / 60 - hours * 60) : 0

        setHours(hours)
        setMinutes(minutes)
      }
      setNote(timer.description)
    }
  }, [timer])

  const onChange: DatePickerProps['onChange'] = (date, dateString: any) => {
    setDate(date)
    if (dateString) {
      try {
        const dayjsDate = convertToDayjs(dateString)
        setDate(dayjsDate.format('YYYY-MM-DD'))
      } catch (error) {
        setDate(date.toDate())
      }
    }
  }

  const onSubmit = async () => {
    try {
      // await axiosInstance.put(`/api/timers/${timer.id}`, {
      //   note,
      //   date,
      //   hours,
      //   minutes,
      // })
      //
      const durationInSeconds = Math.floor(hours * 3600 + minutes * 60)
      const newDate = new Date(date)
      newDate.setSeconds(newDate.getSeconds() + durationInSeconds)
      const data = {
        startTime: date || dayjs(new Date()),
        endTime: newDate,
      } as any
      if (note) data['description'] = note
      dispatch(
        editTimer({
          timerId: timer.id as string,
          payload: data,
        }),
      ).then(() => {
        task && refetch()
      })
      setEditOpen((prev: boolean) => !prev)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div ref={editTimerModalRef} className="edit-timer-modal">
      <div className="space-modal-header">
        <p className="modal-title">Edit timer</p>
        <CloseIcon
          onClick={() => {
            setEditOpen((prev: boolean) => !prev)
          }}
          className="close-icon"
        />
      </div>
      <div className="edit-timer-modal-container">
        <div className="add-note-input">
          <Input
            prefix={<img src={documentTextIcon} alt="text icon" />}
            onChange={(e) => setNote(e.target.value)}
            value={note ? note : undefined}
            type="text"
            placeholder="Add a note .."
            size="xxs"
            variant="label-inline"
            containerClassName="add-note-input-input"
          />
        </div>
        {/* <div className="dates-container">
          <div className="date-item">
            <img src={clockIcon} alt="" />
            <span>09:12 am</span>
          </div>
          <div className="date-item">
            <img src={clockIcon} alt="" />
            <span>09:12 am</span>
          </div>
        </div> */}
        <div className="duration-container">
          <img src={clockIcon} alt="" />
          <span>Duration:</span>
          <span>
            <Input
              onChange={(e) => setHours(Math.abs(Number(e.target.value)))}
              value={hours ? hours : 0}
              type="number"
              containerClassName="duration-input"
              size="xxs"
              rounded="sm"
              variant="filled"
            />{' '}
            h{' '}
            <Input
              onChange={(e) => setMinutes(Math.abs(Number(e.target.value)))}
              value={minutes ? minutes : 0}
              type="number"
              containerClassName="duration-input"
              size="xxs"
              variant="filled"
              rounded="sm"
            />{' '}
            m
          </span>
        </div>
        <div className="now-date">
          <span className="date-label">When: </span>
          <DatePicker
            onChange={onChange}
            bordered={false}
            suffixIcon={null}
            placeholder={'Now'}
            value={date ? dayjs(date) : undefined}
          />
        </div>
        <Button onClick={onSubmit} className="submit">
          <img src={saveIcon} alt="" />
          Save changes
        </Button>
      </div>
    </div>
  )
}

export default EditTimerModal
