import duration from 'dayjs/plugin/duration'
import dayjs from 'dayjs'

export const convertToDayjs = (timeString: any) => {
  dayjs.extend(duration)

  const matches = timeString.match(/(\d+)\s*Hours(?:\s*(\d*)\s*Minutes)?/)
  if (matches) {
    const hours = parseInt(matches[1], 10)
    const minutes = matches[2] ? parseInt(matches[2], 10) : 0

    const dur = dayjs.duration({ hours, minutes })

    return dur
  }
  throw new Error('Invalid time string')
}
