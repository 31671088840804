import { useCallback, useEffect, useRef, useState } from 'react'
import { Skeleton, Tabs, Timeline } from 'antd'
import type { TabsProps } from 'antd'
import { getUserActivity } from '../../../data/Profile/ProfileThunk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { handleActivityDescription } from '@src/modules/settings/utils/activityDescription'
import { useTranslation } from 'react-i18next'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeAr from 'dayjs/locale/ar'
import localeEn from 'dayjs/locale/en'
import localeFr from 'dayjs/locale/ar'
import { formatDateToMultiLang } from '@src/modules/tasks/utils/formateDateToMultiLang'
import { getActivityColor } from '@src/modules/settings/utils/getActivityColor'
import { getActivityIcon } from '@src/modules/settings/utils/getActivityIcon'
import UserActivitySkeleton from '@src/modules/settings/components/UserActivitySkeleton/UserActivitySkeleton'

dayjs.extend(utc)

interface UserActivityProps {
  id?: string
}

const UserActivity = ({ id }: UserActivityProps) => {
  const { t } = useTranslation(['profile'])
  const { i18n } = useTranslation()
  const lang = localStorage.getItem('i18nextLng')
  const locale = lang === 'ar' ? localeAr : lang === 'fr' ? localeFr : localeEn
  dayjs.extend(customParseFormat)
  dayjs.extend(localizedFormat)
  dayjs.locale(locale)

  const [localUserActivity, setLocalUserActivity] = useState([])
  const [page, setPage] = useState(1)
  const [dateQuery, setDateQuery] = useState<string>()
  const [activeTabKey, setActiveTabKey] = useState('1')

  const tabRefs = {
    '1': useRef(null),
    '2': useRef(null),
    '3': useRef(null),
    '4': useRef(null),
    '5': useRef(null),
  }

  const { user } = useAppSelector((state) => state.auth)
  const { userActivity, metadata } = useAppSelector((state) => state.profile)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [tabLoader, setTabLoader] = useState(true)

  useEffect(() => {
    const newActivities = userActivity?.filter(
      (activity) => !localUserActivity.some((localActivity) => localActivity?.id === activity?.id),
    )
    setLocalUserActivity((prevActivities) => [...prevActivities, ...newActivities])
  }, [userActivity])

  useEffect(() => {
    dispatch(getUserActivity({ userId: id || user?.user?.id, page: page, date: dateQuery })).then(
      () => setTabLoader(false),
    )
    setLocalUserActivity([])
  }, [dispatch, id, user?.user?.id, dateQuery])

  const loadMoreActivities = useCallback(() => {
    if (!loading && metadata?.next) {
      setLoading(true)
      dispatch(
        getUserActivity({ userId: id || user?.user?.id, page: page + 1, date: dateQuery }),
      ).finally(() => setLoading(false))
      setPage((prevPage) => prevPage + 1)
    }
  }, [loading, metadata?.next, dispatch, user?.user?.id, page, dateQuery])

  const onScroll = useCallback(() => {
    const element = tabRefs[activeTabKey]?.current
    if (element) {
      const currentBottomPosition = element.scrollTop + element.clientHeight
      if (currentBottomPosition >= element.scrollHeight) {
        loadMoreActivities()
      }
    }
  }, [loadMoreActivities, activeTabKey])

  const sourceActivity = (source: string) => {
    switch (source) {
      case 'task':
        return t('Task')

      case 'space':
        return t('Space')

      case 'list':
        return t('List')

      case 'genericFolder':
        return t('Folder')
    }
  }
  const formate = 'dddd MM MMMM HH:mm'
  const actionActivity = (action: string) => {
    switch (action) {
      case 'create':
        return t('Create')

      case 'update':
        return t('Update')

      case 'closeTimer':
        return t('Close Timer')
    }
  }

  const TimeLineComponent = useCallback(
    () => (
      <>
        {tabLoader ? (
          <Skeleton />
        ) : (
          <Timeline
            items={localUserActivity.map((activity) => ({
              color: getActivityColor(activity),
              children: (
                <div>
                  <div className="task-type">
                    <div className="activity-icon">{getActivityIcon(activity)}</div>

                    <span>
                      {actionActivity(activity?.action) || t('comment')}{' '}
                      {sourceActivity(activity?.source)}
                    </span>
                  </div>
                  <p className="activity-description">{handleActivityDescription(t, activity)}</p>
                  <p className="activity-date">
                    {formatDateToMultiLang(activity?.createdAt, i18n.language, formate)}
                  </p>
                </div>
              ),
            }))}
          />
        )}
      </>
    ),
    [localUserActivity],
  )

  const onChange = (key) => {
    setActiveTabKey(key)
    setTabLoader(true)
    switch (key) {
      case '1':
        setDateQuery(null)
        setPage(1)
        setLocalUserActivity([])
        break
      case '2':
        setDateQuery(dayjs().format('YYYY-MM-DD'))
        setPage(1)
        setLocalUserActivity([])

        break
      case '3':
        setDateQuery(dayjs().subtract(2, 'day').format('YYYY-MM-DD'))
        setPage(1)
        setLocalUserActivity([])

        break
      case '4':
        setDateQuery(dayjs().subtract(1, 'week').format('YYYY-MM-DD'))
        setPage(1)
        setLocalUserActivity([])

        break
      case '5':
        setDateQuery(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
        setPage(1)
        setLocalUserActivity([])

        break
      default:
        break
    }
  }

  useEffect(() => {
    const element = tabRefs[activeTabKey]?.current
    if (element) {
      element.addEventListener('scroll', onScroll)
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll)
      }
    }
  }, [onScroll, activeTabKey])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('timeline'),
      children: (
        <div className="timeline" ref={tabRefs['1']}>
          <TimeLineComponent />
        </div>
      ),
    },
    {
      key: '2',
      label: t('today'),
      children: (
        <div className="timeline" ref={tabRefs['2']}>
          <TimeLineComponent />
        </div>
      ),
    },
    {
      key: '3',
      label: t('last_2_days'),
      children: (
        <div className="timeline" ref={tabRefs['3']}>
          <TimeLineComponent />
        </div>
      ),
    },
    {
      key: '4',
      label: t('last_week'),
      children: (
        <div className="timeline" ref={tabRefs['4']}>
          <TimeLineComponent />
        </div>
      ),
    },
    {
      key: '5',
      label: t('last_month'),
      children: (
        <div className="timeline" ref={tabRefs['5']}>
          <TimeLineComponent />
        </div>
      ),
    },
  ]

  return (
    <div className="activity-card-container">
      {!id && <span className="activity-card-title">{t('my_activities')}</span>}

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} tabBarGutter={33} />
      {metadata?.next && loading && <UserActivitySkeleton />}
    </div>
  )
}

export default UserActivity
