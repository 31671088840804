import { Avatar, Dropdown, Spin } from 'antd'
import { MembersList, groupByType } from '../../../Reply/Reply'
import {
  deleteEmojiFromAnswer,
  updateEmojiInAnswer,
} from '@src/modules/dailyQuestions/data/answers/answersSlice'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import {
  useDeleteEmojiReplyMutation,
  useGetRepliesQuery,
  usePostReplyMutation,
  useUpdateEmojiReplyMutation,
} from '@src/modules/dailyQuestions/services/memberAnswerApi'
import { useEffect, useState } from 'react'

import { ActionsResponse } from './answersList.inteface'
import { ReactComponent as AngleSVG } from './../../../../assets/icons/answers/angle.svg'
import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText'
import CustomTimeInput from '../../../CustomTimeInput'
import FooterSection from './FooterSection'
import Picker from '@emoji-mart/react'
import { ReactComponent as ReactionSVG } from '../../../../assets/icons/answers/reaction.svg'
import Reply from '../../../Reply'
import { ReactComponent as ReplySVG } from '../../assets/reply.svg'
import Spinner from '@src/modules/spaces/components/Spinner'
import { ReactComponent as Timer } from '../../assets/timer.svg'
import { calculateTotalDuration } from '../../utils/calculateTotalDuration'
import data from '@emoji-mart/data'
import { getTimeDifferenceFromDate } from '../../utils/getTimeDifferenceFromDate'
import { useAddEmojiAnswerMutation } from '@src/modules/dailyQuestions/services/answersApi'
import { useTranslation } from 'react-i18next'

export default function OneAnswer({
  answer,
  team,
  key,
  AnswersKey,
  questionId,
}: {
  answer: ActionsResponse
  team: any
  key
  AnswersKey: string
  questionId: string
}) {
  const { t, ...rest } = useTranslation(['dailyQuestion'])
  const { user } = useAppSelector((state) => state.auth)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const {
    data: replies,
    isLoading,
    isFetching,
    refetch,
  } = useGetRepliesQuery(
    {
      answerId: answer?.id,
      limit: 10,
      skip: currentPage,
    },
    {
      skip: !answer?.id,
    },
  )
  useEffect(() => {
    refetch()
  }, [answer])
  const [postReply, { isLoading: replyLoading }] = usePostReplyMutation()
  const [newReply, setReply] = useState<string>('')
  const [ShowEmoji, setShowEmoji] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)
  let { metadata } = replies || {}
  const [addEmoji] = useAddEmojiAnswerMutation()
  const [updateEmoji] = useUpdateEmojiReplyMutation()
  const [deleteEmoji] = useDeleteEmojiReplyMutation()
  const dispatch = useAppDispatch()
  const sendReply = () => {
    postReply({ content: newReply, answerId: answer?.id, questionId })
      .unwrap()
      .then(() => {
        setReply('')
      })
  }
  const answerReaction = answer?.reactions.filter((data) => !data.commentId)
  const comments = useAppSelector((state) => state.answers.comments)
  const reactions = groupByType(
    answerReaction,
    user?.user?.id,
    team?.roles?.map((role) => role?.user),
  )

  const manageEmoji = (url: string, emojiType) => {
    const myReaction = reactions?.find((reaction) => reaction?.myReaction?.exist)
    if (!myReaction) {
      addEmoji({ answerId: answer?.id, code: url, type: emojiType, questionId })
    } else if (myReaction?.type === emojiType && checkExist(myReaction.users, user?.user?.id)) {
      deleteEmoji({
        id: myReaction?.myReaction.id,
        replyId: answer?.id,
        questionId,
        answerId: answer?.id,
      })
      dispatch(
        deleteEmojiFromAnswer({
          key: AnswersKey,
          emojiIdToDelete: myReaction?.myReaction.id,
          answerId: answer?.id,
        }),
      )
    } else if (myReaction?.type !== emojiType) {
      updateEmoji({
        id: myReaction?.myReaction.id,
        code: url,
        type: emojiType,
        questionId,
        answerId: answer?.id,
      })
        .unwrap()
        .then((res) => {
          dispatch(
            updateEmojiInAnswer({
              key: AnswersKey,
              answerId: answer?.id,
              updatedEmoji: res?.payload,
            }),
          )
        })
    }
  }

  return (
    <div className="answers-list" key={key}>
      <div className="answers-list_header">
        <div className="answers-list_user">
          <Avatar src={answer?.owner?.avatar}>{answer?.owner?.fullName[0] || ''}</Avatar>
          <div className="answers-list_user answers-list_info">
            <p>{answer?.owner?.fullName || ''}</p>
            <span
              className="answers-list_time"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {hovered
                ? formatCleanDate(new Date(answer?.createdAt))
                : getTimeDifferenceFromDate(answer?.createdAt, rest[1]?.language)}
            </span>
          </div>
          {compareDates(answer?.createdAt, answer?.date) && (
            <div className="answers-list_user-status">Late</div>
          )}
        </div>
        <div className="answers-list-duration">
          <div className="bloc-footer_right">
            <CustomTimeInput
              disabled={true}
              updateEditorTime={() => {}}
              value={calculateTotalDuration(answer?.body)}
            />
            <Timer />
          </div>
        </div>
      </div>
      <div className="answers-list_body">
        {answer?.body?.length > 0 &&
          answer?.body?.map((body, index: number) => {
            return (
              <div className="answers-list_body-element" key={body?.answerId + body?.id}>
                <BlockNoteRichText
                  key={JSON.stringify(body)}
                  onlyView
                  value={body?.content}
                  setValue={() => {}}
                />
                <FooterSection body={body} index={index} attachments={answer?.attachments} />
              </div>
            )
          })}
      </div>
      <div className="reply-infos">
        <div className="reply-reactions">
          <Dropdown
            dropdownRender={() => (
              <Picker
                data={data}
                categories={['frequent', 'people', 'nature', 'foods', 'activity']}
                onEmojiSelect={(emoji) => {
                  manageEmoji(emoji.native, emoji?.unified)
                  setShowEmoji(false)
                }}
              />
            )}
            trigger={['click']}
            open={ShowEmoji}
            onOpenChange={(visible) => setShowEmoji(visible)}
          >
            <ReactionSVG />
          </Dropdown>
          {reactions?.map((reaction, i) => {
            return (
              <Dropdown key={i} dropdownRender={() => <MembersList members={reaction?.users} />}>
                <div
                  className={`reply-reaction ${
                    !reaction?.myReaction?.exist ? 'active-reaction' : ''
                  }`}
                  key={i}
                  onClick={() => manageEmoji(reaction?.emoji, reaction.type)}
                  style={!reaction?.myReaction?.exist ? { backgroundColor: 'transparent' } : {}}
                >
                  <span>{reaction?.emoji}</span>
                  <span>{reaction?.count}</span>
                </div>
              </Dropdown>
            )
          })}
        </div>
        <div className="reply-replies">
          <p>
            {comments[answer?.id]?.length} {t('answers_tab.reply')}
          </p>
          <ReplySVG />
        </div>
      </div>
      <div className="answer-replies">
        {metadata?.total_items > comments[answer?.id]?.length && answer?.nbreplies > 0 ? (
          <div
            className="load-more-comments"
            onClick={() => !isFetching && setCurrentPage((prev) => prev + 1)}
          >
            {isFetching ? <Spin size={'small'} /> : <AngleSVG />} read previous replies
          </div>
        ) : null}
        {isLoading ? (
          <Spinner />
        ) : (
          comments[answer?.id]?.map((reply) => {
            if (answer?.id == reply?.answerId)
              return (
                <Reply
                  reply={reply}
                  users={team?.roles?.map((role) => role?.user)}
                  key={reply?.updatedAt}
                  questionId={questionId}
                />
              )
          })
        )}
      </div>
      <div className="answer-modal-footer">
        <div className="current-user">
          <Avatar src={user?.user?.avatar}>
            {user?.user?.fullName && user?.user?.fullName[0].toUpperCase()}
          </Avatar>
        </div>
        <div className="reply-input">
          <div className="block-note hovered-scrollbar">
            <BlockNoteRichText
              value={newReply}
              setValue={(value: string) => setReply(value)}
              onUplaod={() => setUploading(false)}
              onDrop={() => setUploading(true)}
              onPaste={() => setUploading(true)}
              // insert
            />
          </div>
          <div className="reply-input-controll-panel">
            {!uploading ? (
              <div
                className="submit-button"
                onClick={() => {
                  if (!replyLoading) sendReply()
                }}
              >
                {replyLoading ? t('answers_tab.loading') : t('answers_tab.send')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

function compareDates(createdAt: string, date: string): boolean {
  const createdAtDate = new Date(createdAt)
  const dateDate = new Date(date)
  createdAtDate.setHours(0, 0, 0, 0)
  dateDate.setHours(0, 0, 0, 0)
  if (createdAtDate > dateDate) {
    return true
  } else {
    return false
  }
}

export function formatCleanDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}

export function OneAnswerDashboard({ answer }: { answer: ActionsResponse }) {
  const [hovered, setHovered] = useState<boolean>(false)
  const { t, ...rest } = useTranslation(['dailyQuestion'])

  return (
    <>
      <div className="answers-list_header" key={answer?.id}>
        <div className="answers-list_user">
          <Avatar src={answer?.owner?.avatar}>{answer?.owner?.fullName[0] || ''}</Avatar>
          <div
            className="answers-list_user answers-list_info"
            style={{
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            <p>{answer?.owner?.fullName || ''}</p>
            <span
              className="answers-list_time"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {hovered
                ? formatCleanDate(new Date(answer?.createdAt))
                : getTimeDifferenceFromDate(answer?.createdAt, rest[1]?.language)}
            </span>
          </div>
          {compareDates(answer?.createdAt, answer?.date) && (
            <div className="answers-list_user-status">Late</div>
          )}
        </div>
        <div className="answers-list-duration">
          <div className="bloc-footer_right">
            <CustomTimeInput
              disabled={true}
              updateEditorTime={() => {}}
              value={calculateTotalDuration(answer?.body)}
            />
            <Timer />
          </div>
        </div>
      </div>
      <div className="answers-list_body" style={{ width: '100%' }}>
        {answer?.body?.length > 0 &&
          answer?.body?.map((body, index: number) => {
            return (
              <div className="answers-list_body-element" key={body?.answerId + body?.id}>
                <BlockNoteRichText key={index} onlyView value={body?.content} />
                <FooterSection
                  body={body}
                  index={index}
                  attachments={answer?.attachments}
                  disabled
                />
              </div>
            )
          })}
      </div>
    </>
  )
}

function checkExist(arrayOfObjects, id) {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    if (arrayOfObjects[i].id === id) {
      return true
    }
  }
  return false
}
