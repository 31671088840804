import { toggleIntegrationStatus } from '@src/modules/settings/data/Integrations/IntegrationsThunk'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { message, Switch } from 'antd'
import { useEffect, useState } from 'react'
import avaibale_soon from '../../../assets/icons/integrations/avaibale_soon.svg'
import lock from '../../../assets/icons/integrations/lock.svg'
import { useTranslation } from 'react-i18next'

export interface Iintegration {
  id: string
  name: string
  available: boolean | null
  integrationId: string
  description: string
  isEnabled: boolean
  icon: string
}

interface IntegrationCardProps {
  item: Iintegration
  onStatusChange: () => void
  loadingIntegrationId: string | null
}

const IntegrationCard = ({ item, onStatusChange, loadingIntegrationId }: IntegrationCardProps) => {
  const [isChecked, setIsChecked] = useState(item?.isEnabled)
  const { t } = useTranslation(['integrations'])
  const dispatch = useAppDispatch()
  const isLoading = loadingIntegrationId === item.integrationId
  useEffect(() => {
    setIsChecked(item?.isEnabled)
  }, [item?.isEnabled])

  const onChange = (checked: boolean) => {
    dispatch(toggleIntegrationStatus({ integrationId: item?.integrationId, isEnabled: checked }))
      .unwrap()
      .then((res) => {
        setIsChecked(checked)
        onStatusChange()
        if (res?.payload?.RedirectUrl) {
          window.location.href = res?.payload?.RedirectUrl
        } else {
          if (res?.payload?.isEnabled) {
            message.success(item?.name + t('enabled_with_success'))
          } else {
            message.success(item?.name + t('disabled_with_success'))
          }
        }
      })
      .catch((error) => message.error(error.message))
  }

  return (
    <div className="integration_card">
      {!item?.available && <img src={avaibale_soon} className="available_soon" />}

      <div className={`integration_card_content ${!item?.available ? 'integration_soon' : ''}`}>
        <img src={item?.icon} />

        <div className="typo">
          <h2>{item?.name}</h2>
          <p className="category">{t('communication')}</p>
          <p>{item?.description}</p>
        </div>
      </div>

      {item?.available && (
        <div className="integration_card_footer" style={isChecked ? { background: '#E3F3E9' } : {}}>
          <Switch size="small" onChange={onChange} checked={isChecked} loading={isLoading} />
          <span style={isChecked ? { color: '#34a174' } : {}}>{t('connected')}</span>
        </div>
      )}

      <Can I="create" a="integrationId">
        <div className="integration_card_footer not_admin">
          <img src={lock} />
          <span>{t('only_admin_can_connect')}</span>
        </div>
      </Can>
    </div>
  )
}

export default IntegrationCard
