import { SVGProps } from 'react'

type Props = {}

function CloseTimer(props: SVGProps<SVGSVGElement & Props>) {
  return (
    <svg
      {...props}
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 1.25L0.75 8.75M0.75 1.25L8.25 8.75"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseTimer
