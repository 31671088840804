import { SVGProps } from 'react'

type Props = {}

function NoteIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 18.9582H7.50002C2.97502 18.9582 1.04169 17.0248 1.04169 12.4998V7.49984C1.04169 2.97484 2.97502 1.0415 7.50002 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50002C3.65835 2.2915 2.29169 3.65817 2.29169 7.49984V12.4998C2.29169 16.3415 3.65835 17.7082 7.50002 17.7082H12.5C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.675 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.025 18.9582 12.5 18.9582Z"
        fill="#374957"
      />
      <path
        d="M18.3334 8.95841H15C12.15 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.425 1.09174C11.6584 0.991739 11.925 1.05007 12.1084 1.22507L18.775 7.89174C18.95 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.85 7.70841 15 7.70841H16.825L12.2917 3.17507Z"
        fill="#374957"
      />
      <path
        d="M10.8333 11.4585H5.83331C5.49165 11.4585 5.20831 11.1752 5.20831 10.8335C5.20831 10.4918 5.49165 10.2085 5.83331 10.2085H10.8333C11.175 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.175 11.4585 10.8333 11.4585Z"
        fill="#374957"
      />
      <path
        d="M9.16665 14.7915H5.83331C5.49165 14.7915 5.20831 14.5082 5.20831 14.1665C5.20831 13.8248 5.49165 13.5415 5.83331 13.5415H9.16665C9.50831 13.5415 9.79165 13.8248 9.79165 14.1665C9.79165 14.5082 9.50831 14.7915 9.16665 14.7915Z"
        fill="#374957"
      />
    </svg>
  )
}

export default NoteIcon
