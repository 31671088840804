import fr from '../../../../../locales/fr'
import en from '../../../../../locales/en'
import ar from '../../../../../locales/ar'

const lang = localStorage.getItem('i18nextLng')
const data = lang === 'fr' ? fr : lang === 'ar' ? ar : en

const { Timer, Manual } = data.taskDetails

export const items = [
  {
    label: Timer,
    value: '0',
    token: { motion: false },
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99984 15.6667C4.5065 15.6667 1.6665 12.8267 1.6665 9.33333C1.6665 5.84 4.5065 3 7.99984 3C11.4932 3 14.3332 5.84 14.3332 9.33333C14.3332 12.8267 11.4932 15.6667 7.99984 15.6667ZM7.99984 4C5.05984 4 2.6665 6.39333 2.6665 9.33333C2.6665 12.2733 5.05984 14.6667 7.99984 14.6667C10.9398 14.6667 13.3332 12.2733 13.3332 9.33333C13.3332 6.39333 10.9398 4 7.99984 4Z"
          fill="white"
        />
        <path
          d="M8 9.66683C7.72667 9.66683 7.5 9.44016 7.5 9.16683V5.8335C7.5 5.56016 7.72667 5.3335 8 5.3335C8.27333 5.3335 8.5 5.56016 8.5 5.8335V9.16683C8.5 9.44016 8.27333 9.66683 8 9.66683Z"
          fill="white"
        />
        <path
          d="M10 2.3335H6C5.72667 2.3335 5.5 2.10683 5.5 1.8335C5.5 1.56016 5.72667 1.3335 6 1.3335H10C10.2733 1.3335 10.5 1.56016 10.5 1.8335C10.5 2.10683 10.2733 2.3335 10 2.3335Z"
          fill="white"
        />
      </svg>
    ),
  },

  {
    label: Manual,
    value: '1',
    token: { motion: false },
    icon: (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5002 15.6668H6.50016C2.88016 15.6668 1.3335 14.1202 1.3335 10.5002V6.50016C1.3335 2.88016 2.88016 1.3335 6.50016 1.3335H7.8335C8.10683 1.3335 8.3335 1.56016 8.3335 1.8335C8.3335 2.10683 8.10683 2.3335 7.8335 2.3335H6.50016C3.42683 2.3335 2.3335 3.42683 2.3335 6.50016V10.5002C2.3335 13.5735 3.42683 14.6668 6.50016 14.6668H10.5002C13.5735 14.6668 14.6668 13.5735 14.6668 10.5002V9.16683C14.6668 8.8935 14.8935 8.66683 15.1668 8.66683C15.4402 8.66683 15.6668 8.8935 15.6668 9.16683V10.5002C15.6668 14.1202 14.1202 15.6668 10.5002 15.6668Z"
          fill="white"
        />
        <path
          d="M6.16688 12.2934C5.76022 12.2934 5.38688 12.1467 5.11355 11.88C4.78688 11.5534 4.64688 11.08 4.72022 10.58L5.00688 8.57337C5.06022 8.18671 5.31355 7.68671 5.58688 7.41337L10.8402 2.16004C12.1669 0.833372 13.5135 0.833372 14.8402 2.16004C15.5669 2.88671 15.8935 3.62671 15.8269 4.36671C15.7669 4.96671 15.4469 5.55337 14.8402 6.15337L9.58688 11.4067C9.31355 11.68 8.81355 11.9334 8.42688 11.9867L6.42022 12.2734C6.33355 12.2934 6.24688 12.2934 6.16688 12.2934ZM11.5469 2.86671L6.29355 8.12004C6.16688 8.24671 6.02022 8.54004 5.99355 8.71337L5.70688 10.72C5.68022 10.9134 5.72022 11.0734 5.82022 11.1734C5.92022 11.2734 6.08022 11.3134 6.27355 11.2867L8.28022 11C8.45355 10.9734 8.75355 10.8267 8.87355 10.7L14.1269 5.44671C14.5602 5.01337 14.7869 4.62671 14.8202 4.26671C14.8602 3.83337 14.6335 3.37337 14.1269 2.86004C13.0602 1.79337 12.3269 2.09337 11.5469 2.86671Z"
          fill="white"
        />
        <path
          d="M13.7335 7.05319C13.6869 7.05319 13.6402 7.04652 13.6002 7.03319C11.8469 6.53986 10.4535 5.14652 9.9602 3.39319C9.88687 3.12652 10.0402 2.85319 10.3069 2.77319C10.5735 2.69986 10.8469 2.85319 10.9202 3.11986C11.3202 4.53986 12.4469 5.66652 13.8669 6.06652C14.1335 6.13986 14.2869 6.41985 14.2135 6.68652C14.1535 6.91319 13.9535 7.05319 13.7335 7.05319Z"
          fill="white"
        />
      </svg>
    ),
  },
]
