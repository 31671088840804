import React, { ReactElement } from 'react'
import { Select } from 'antd'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import TaskSearchLabel from './TaskSearchLabel'
import TaskSelectedLabel from './TaskSelectedLabel'
import TaskSearchEmpty from './TaskSearchEmpty'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import {
  setCurrentGlobalTask,
  setGlobalTimer,
  setSelectedTaskId,
} from '@src/modules/tasks/data/globalTimerData/slices'
// import { useGetTasksQuery, useGetTasksWithSearchQuery } from '@src/modules/tasks/services/tasksApi'
// import { useParams, useSearchParams } from 'react-router-dom'

interface Props {
  tasks: TaskType[]
}

const GloablTimerSearchTasks: React.FC<Props> = ({ tasks }) => {
  const dispatch: Function = useAppDispatch()
  const { currentGlobalTask, selectedTaskId } = useAppSelector((state) => state.globalTimer)
  // const [searchInput, setSearchInput] = useState('')

  // const { listId } = useParams<{ listId: string }>()
  // const { data, error, isLoading, refetch } = useGetTasksWithSearchQuery(
  //   {
  //     search: searchInput,
  //     fileId: listId,
  //   },
  //   {
  //     skip: !listId || !searchInput,
  //   },
  // )

  const onChange = (taskId: string) => {
    const selectedTask = tasks.find((t) => t.id === taskId)
    dispatch(setCurrentGlobalTask(selectedTask))
    dispatch(setSelectedTaskId(taskId))
    dispatch(setGlobalTimer(selectedTask?.totalTime || 0))
  }

  const onSearch = () => {
    // if (value) {
    //   setSearchInput(value)
    // }
  }

  // useEffect(() => {
  //   if (searchInput) {
  //     refetch()
  //   }
  // }, [searchInput])

  const filterOption = (
    input: string,
    option: {
      children: ReactElement
      value: string
    },
  ) => {
    return option.children.props.task.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <Select
      showSearch
      placeholder={<TaskSearchEmpty />}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={filterOption}
      value={selectedTaskId}
      suffixIcon={null}
      dropdownRender={(menu) => (
        <>
          <TaskSelectedLabel setSelectedTaskId={setSelectedTaskId} task={currentGlobalTask} />

          {menu}
        </>
      )}
      style={{
        minWidth: '270px',
      }}
    >
      {tasks.map((task) => (
        <Select.Option key={`select-${task.id}`} value={task.id}>
          <TaskSearchLabel task={task} />
        </Select.Option>
      ))}
    </Select>
  )
}

export default GloablTimerSearchTasks
