/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'

export const addManualGlobalTimer = createAsyncThunk(
  'spaces/add-timer',
  async ({
    startTime,
    endTime,
    taskId,
    description,
  }: {
    startTime: string
    endTime: string
    taskId: string
    description?: string
  }) => {
    try {
      const response = await axiosInstance.post(`/api/timer`, {
        startTime,
        endTime,
        taskId,
        description,
      })

      if (response.status === 201) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const startGlobalTimer = createAsyncThunk(
  'spaces/start-timer',
  async ({ taskId, description }: { taskId: string; description?: string }) => {
    try {
      const response = await axiosInstance.post(`/api/timer/start`, {
        taskId,
        description,
      })

      if (response.status === 201) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const closeGlobalTimer = createAsyncThunk(
  'spaces/close-timer',
  async ({ timerId }: { timerId: string }) => {
    try {
      const response = await axiosInstance.patch(`/api/timer/close`, {
        timerId,
      })

      if (response.status === 200) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const deleteGlobalTimer = createAsyncThunk(
  'spaces/delete-timer',
  async ({ id }: { id: string }) => {
    try {
      const response = await axiosInstance.delete(`/api/timers/${id}`)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const updateAndRestartGlobalTimer = createAsyncThunk(
  'spaces/update-and-restart-timer',
  async ({
    taskId,
    timerId,
    description,
  }: {
    taskId: string
    timerId: string
    description?: string
  }) => {
    try {
      const stopResponse = await axiosInstance.patch(`/api/timer/close`, {
        timerId,
      })

      if (stopResponse.status !== 200) {
        throw new Error(stopResponse.statusText)
      }

      const startResponse = await axiosInstance.post(`/api/timer/start`, {
        taskId,
        description,
      })

      if (startResponse.status !== 201) {
        throw new Error(startResponse.statusText)
      }
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getGlobalTimers = createAsyncThunk(
  'spaces/get-timers',
  async ({ taskId, limit = 5 }: { taskId: string; limit?: number }) => {
    try {
      const response = await axiosInstance.get(`/api/timer/${taskId}?limit=${limit}`)
      if (response.status === 200) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
