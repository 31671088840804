import deepClone from 'deep-clone'
import { convertToTemplateTree } from '../SpacesTree'

export const socketSpacesManagement = {
  updateItem: (tree, updatedObject: any) => {
    const newTree = deepClone(tree)
    newTree[updatedObject?.id] = { ...newTree[updatedObject?.id], content: updatedObject }
    return newTree
  },

  deleteItem: (tree, deletedId: string) => {
    if (!deletedId) return tree
    const newTree = deepClone(tree)

    const deleteItem = newTree[deletedId]
    delete newTree[deletedId]
    if (deleteItem?.parentId) {
      newTree[deleteItem?.parentId] = {
        ...newTree[deleteItem?.parentId],
        children: newTree[deleteItem?.parentId]?.children?.filter((id) => id !== deletedId),
      }
    }
    return newTree
  },

  createItem: (tree, createdObject: any) => {
    if (!createdObject) return tree
    let newTree = deepClone(tree)
    const { spaceId, folderId, path, id, cover } = createdObject || {}
    let type = null
    let parentId = null
    if (spaceId === undefined) {
      type = 'space'
      parentId = 'root'
    } else if ((spaceId && (folderId || folderId === null)) || path) {
      if (cover !== undefined) {
        type = 'document'
        parentId = folderId || spaceId
      } else {
        type = 'list'
        parentId = folderId || spaceId
      }
    } else if (spaceId && folderId === undefined) {
      type = 'folder'
      parentId = spaceId
    }
    if (!parent || !type) {
      console.error('There was a problem , parent or type not found or type')
      return newTree
    }
    newTree = { ...newTree, ...convertToTemplateTree([{ ...createdObject }], type) }
    newTree[parentId] = {
      ...newTree[parentId],
      children: [...(newTree[parentId]?.children || []), id],
    }
    return newTree
  },
}
