import { Dropdown, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ClearIcon } from '@src/modules/tasks/assets/icons/filter/clear.svg'
import { ReactComponent as CollapseIcon } from '@src/modules/tasks/assets/icons/filter/collapse.svg'
import { ReactComponent as DeleteIcon } from '@src/modules/tasks/assets/icons/filter/delete.svg'
import { ReactComponent as FilterIcon } from '@src/modules/tasks/assets/icons/tasks/filter.svg'
import { parser } from '@src/modules/tasks/components/TasksFilter/helpers/parser.ts'
import { filterFields } from '@src/modules/tasks/components/TasksFilter/helpers/filterFields.ts'

import { getOrmOptions } from '@src/modules/tasks/components/TasksFilter/helpers/getOrmOptions.ts'
import { getFieldsFilter } from '@src/modules/tasks/components/TasksFilter/helpers/getFieldsFilter.ts'
import { getOperationFilter } from '@src/modules/tasks/components/TasksFilter/helpers/getOperationFilter.ts'
import CreateByFilter from './components/CreateByFilter'
import FieldTypeFilter from './components/FieldTypeFilter'
import LocationFilter from './components/LocationFilter'
import { getFilterCount } from '@src/modules/tasks/components/TasksFilter/helpers/getFilterCount'

const emptyFilterSchema = (lastIndex: number) => ({
  orm: lastIndex ? 'AND' : 'where',
  field: null,
  type: '',
  operation: null,
  targetFilter: null,
  id: lastIndex + 1,
})

const TargetFilterComponents = {
  Location: LocationFilter,
  createdBy: CreateByFilter,
  fieldType: FieldTypeFilter,
}

interface FilterObject {
  orm: string
  field: string
  type: string
  operation: string
  targetFilter: any
  id: number
}

const FilterItem = ({ data, setFilters, filter, onDelete, t }: any) => {
  const TargetFilterComponent = TargetFilterComponents[filter.type]
  const setFilter = (filter) => {
    setFilters((prev) => {
      return prev.map((savedFilter) =>
        savedFilter?.id === filter?.id ? { ...filter, error: null } : savedFilter,
      )
    })
  }
  const title = !filter?.field
    ? t('pleas_select_field')
    : !filter?.operation
      ? t('pleas_select_operation')
      : ''
  return (
    <div className={`filter-item`}>
      <Dropdown
        menu={{
          items: getOrmOptions(setFilter, filter),
        }}
        trigger={['click']}
        disabled={filter?.orm === 'where'}
      >
        <div className="filter-item-orm">
          {filter?.orm} {filter?.orm !== 'where' && <CollapseIcon />}
        </div>
      </Dropdown>

      <div className="filter-item-query">
        <Dropdown menu={{ items: getFieldsFilter(data, setFilter, filter) }} trigger={['click']}>
          <div className="filter-item-field">
            {filter?.field || t('select_filter')} <CollapseIcon />
          </div>
        </Dropdown>

        <Dropdown
          menu={{ items: getOperationFilter(data, setFilter, filter?.field, filter) }}
          trigger={['click']}
          disabled={!filter?.field}
        >
          <div className="filter-item-operation">
            {filter?.operation === 'ilike' ? 'is like' : filter?.operation || t('select')}
            <CollapseIcon />
          </div>
        </Dropdown>
        <Tooltip title={title}>
          <div
            className="filter-item-data"
            style={{ cursor: (!filter?.field || !filter?.operation) && 'not-allowed' }}
          >
            {TargetFilterComponent ? (
              <TargetFilterComponent
                disabel={!filter?.field || !filter?.operation}
                onData={(data) => setFilter({ ...filter, targetFilter: data })}
                onSelect={(data) =>
                  setFilter({ ...filter, targetFilter: data?.toString() || null })
                }
                showLabel
                activePriorityCode={+filter?.targetFilter}
              />
            ) : (
              t('select_filter')
            )}
          </div>
        </Tooltip>

        <span className="delete-filter-icon" onClick={() => onDelete(filter?.id)}>
          <DeleteIcon />
        </span>
      </div>
    </div>
  )
}
const FormatLocation = (filter) => {
  const { field, operation, targetFilter } = filter[0] || {}
  let parsedFilter = []
  if (targetFilter?.files?.length > 0) {
    parsedFilter.push({
      files: { id: { operation: '$' + operation, value: targetFilter?.files } },
    })
  }
  if (targetFilter?.spaces?.length > 0) {
    parsedFilter.push({
      spaces: { id: { operation: '$' + operation, value: targetFilter?.spaces } },
    })
  }
  if (targetFilter?.folders?.length > 0) {
    parsedFilter.push({
      folders: { id: { operation: '$' + operation, value: targetFilter?.folders } },
    })
  }
  if (!field && !operation && !targetFilter) return

  const result = JSON.stringify({ where: parsedFilter })
  return result.replace(/\\/g, '')
}
const Filter = ({ filter, setFilter, setPage }) => {
  const { t } = useTranslation(['tasks'])
  const data = {
    fieldType: {
      type: 'string',
      operation: ['$Equal', '$not'],
      example: {
        operation: '$Equal',
        value: 'text',
      },
    },
    createdBy: {
      type: 'many2many',
      operation: ['$in', '$notIn'],
      example: {
        operation: '$in',
        value: ['uuid1', 'uuid2', 'uuid3'],
      },
    },
    files: {
      type: 'many2many',
      operation: ['$in', '$notIn'],
      example: {
        operation: '$in',
        value: ['uuid1', 'uuid2', 'uuid3'],
      },
    },
    Location: {
      type: 'many2many',
      operation: ['$in', '$not'],
      example: {
        operation: '$in',
        value: ['uuid1', 'uuid2', 'uuid3'],
      },
    },
    folders: {
      type: 'many2many',
      operation: ['$in', '$notIn'],
      example: {
        operation: '$in',
        value: ['uuid1', 'uuid2', 'uuid3'],
      },
    },
    spaces: {
      type: 'many2many',
      operation: ['$in', '$notIn'],
      example: {
        operation: '$in',
        value: ['uuid1', 'uuid2', 'uuid3'],
      },
    },
    isPublic: {
      type: 'boolean',
      operation: ['$Equal', '$not'],
      example: {
        operation: '$Equal',
        value: true,
      },
    },
  }
  const [filters, setFilters] = useState<FilterObject[] | null>([emptyFilterSchema(0)])
  const createNewFilterSchema = () =>
    setFilters([...(filters || []), emptyFilterSchema(filters?.length || 0)])

  const deleteFilter = (id: number) => {
    const DeletedfilterIndex = filters?.findIndex((filter) => filter.id === id)
    if (DeletedfilterIndex === -1) return
    const Deletedfilter = { ...filters[DeletedfilterIndex], index: DeletedfilterIndex }
    if (Deletedfilter?.index !== 0 && Deletedfilter?.orm !== 'where') {
      setFilters(filters?.filter((filter) => filter?.id !== id))
    } else if (Deletedfilter?.index === 0 && Deletedfilter?.orm === 'where') {
      setFilters((prev) => {
        const newFilters = [...prev]
        newFilters.splice(0, 1, emptyFilterSchema(0))
        return newFilters
      })
    }
  }

  useEffect(() => {
    if (!filter) setFilters([emptyFilterSchema(0)])
  }, [filter])
  useEffect(() => {
    if (filters[0]?.field) {
      //   if (!isValidFilter(filters)) return
      const filterObject =
        filters[0]?.field == 'Location' ? FormatLocation(filters) : parser.formatFilter(filters)
      setFilter({ filter: filterObject })
      setPage(1)
    } else {
      setFilter({ filter: null })
      setPage(1)
    }
  }, [filters])
  return (
    <div className="ant-dropdown-menu tasks-filter" id="tasks-filter">
      <div className="tasks-filter-header">
        <p>{t('filter')}</p>
      </div>

      <div className="tasks-filter-body">
        {filters?.map((filter, i) => (
          <FilterItem
            data={filterFields(data, TargetFilterComponents)}
            filter={filter}
            setFilters={setFilters}
            onDelete={deleteFilter}
            t={t}
            key={i}
          />
        ))}
      </div>

      <div className="tasks-filter-footer" onClick={createNewFilterSchema}>
        <span>+</span>

        <p>{t('add_filter')}</p>
      </div>
    </div>
  )
}

const CustomFieldsFilter = ({ filter, setFilter, setPage }) => {
  const { t } = useTranslation(['tasks'])
  const filterCount = getFilterCount(filter?.filter)

  const clearFilter = (e) => {
    e.stopPropagation()
    setFilter({ filter: '' })
    setPage(1)
  }

  return (
    <Dropdown
      dropdownRender={() => <Filter filter={filter} setFilter={setFilter} setPage={setPage} />}
      placement={'bottomCenter'}
      trigger={['click']}
    >
      <div className="dashboard-tasks-button filter-btn custom-fields-filter">
        <FilterIcon />
        <p>{t('filter')}</p>{' '}
        {filterCount ? (
          <>
            <span className={'custom-fields-filter-count'}>{filterCount}</span>{' '}
            <ClearIcon className="filter-btn-clear" onClick={clearFilter} />
          </>
        ) : null}
      </div>
    </Dropdown>
  )
}

export default CustomFieldsFilter
