import { SVGProps } from 'react'

type Props = {
  fill?: string
}

function EditIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99992 15.6673H5.99992C2.37992 15.6673 0.833252 14.1207 0.833252 10.5007V6.50065C0.833252 2.88065 2.37992 1.33398 5.99992 1.33398H7.33325C7.60659 1.33398 7.83325 1.56065 7.83325 1.83398C7.83325 2.10732 7.60659 2.33398 7.33325 2.33398H5.99992C2.92659 2.33398 1.83325 3.42732 1.83325 6.50065V10.5007C1.83325 13.574 2.92659 14.6673 5.99992 14.6673H9.99992C13.0733 14.6673 14.1666 13.574 14.1666 10.5007V9.16732C14.1666 8.89398 14.3933 8.66732 14.6666 8.66732C14.9399 8.66732 15.1666 8.89398 15.1666 9.16732V10.5007C15.1666 14.1207 13.6199 15.6673 9.99992 15.6673Z"
        fill={props?.fill || '#374957'}
      />
      <path
        d="M5.66664 12.2924C5.25997 12.2924 4.88664 12.1457 4.61331 11.8791C4.28664 11.5524 4.14664 11.0791 4.21997 10.5791L4.50664 8.5724C4.55997 8.18573 4.81331 7.68573 5.08664 7.4124L10.34 2.15906C11.6666 0.832396 13.0133 0.832396 14.34 2.15906C15.0666 2.88573 15.3933 3.62573 15.3266 4.36573C15.2666 4.96573 14.9466 5.5524 14.34 6.1524L9.08664 11.4057C8.8133 11.6791 8.3133 11.9324 7.92664 11.9857L5.91997 12.2724C5.83331 12.2924 5.74664 12.2924 5.66664 12.2924ZM11.0466 2.86573L5.7933 8.11906C5.66664 8.24573 5.51997 8.53906 5.4933 8.7124L5.20664 10.7191C5.17997 10.9124 5.21997 11.0724 5.31997 11.1724C5.41997 11.2724 5.57997 11.3124 5.7733 11.2857L7.77997 10.9991C7.9533 10.9724 8.2533 10.8257 8.3733 10.6991L13.6266 5.44573C14.06 5.0124 14.2866 4.62573 14.32 4.26573C14.36 3.8324 14.1333 3.3724 13.6266 2.85906C12.56 1.7924 11.8266 2.0924 11.0466 2.86573Z"
        fill={props?.fill || '#374957'}
      />
      <path
        d="M13.2333 7.05416C13.1866 7.05416 13.14 7.0475 13.1 7.03417C11.3466 6.54083 9.95329 5.1475 9.45996 3.39417C9.38662 3.1275 9.53996 2.85416 9.80662 2.77416C10.0733 2.70083 10.3466 2.85416 10.42 3.12083C10.82 4.54083 11.9466 5.6675 13.3666 6.0675C13.6333 6.14083 13.7866 6.42083 13.7133 6.6875C13.6533 6.91417 13.4533 7.05416 13.2333 7.05416Z"
        fill={props?.fill || '#374957'}
      />
    </svg>
  )
}

export default EditIcon
