import { useEffect, useState, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import StopTimer from '../../icons/StopTimer'
import StartTimer from '../../icons/StartTimer'
import {
  setGlobalTimer,
  setIsRunning as setIsGlobalRunning,
  setCurrentGlobalTimer,
  setCurrentGlobalTask,
  setGlobalNote,
  setSelectedTaskId,
} from '@src/modules/tasks/data/globalTimerData/slices'
import { Tooltip } from 'antd'
import { closeGlobalTimer, startGlobalTimer } from '@src/modules/tasks/data/globalTimerData/thunks'
import { useGetTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { useParams } from 'react-router-dom'
import { handleCounterProcess, stopTimerThunk } from '@src/modules/tasks/data/timerData/slices'
import { closeTimer, getTimers } from '@src/modules/tasks/data/timerData/thunks'
import Button from '@src/modules/shared/components/Button/Button'

const GlobalTimerCounter = () => {
  const {
    isRunning: isGlobalRunning,
    globalNote,
    globalTimer,
    currentGlobalTimer,
    activeGlobalTimer,
    currentGlobalTask,
    selectedTaskId,
  } = useAppSelector((state) => state.globalTimer)
  const dispatch = useAppDispatch()
  const [localTimer, setLocalTimer] = useState(globalTimer)
  const { listId } = useParams<{ listId: string }>()
  const [currentLocalTimer, setCurrentLocalTimer] = useState(currentGlobalTimer)
  const { refetch } = useGetTasksQuery(
    {
      fileId: listId,
      limit: 10,
    },
    {
      skip: !listId,
    },
  )
  const { isRunning, timer, currentTimer, currentTask, activeTimer } = useAppSelector(
    (state) => state.timer,
  )
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = timeInSeconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  const incrementTimer = useCallback(() => {
    setLocalTimer((prev) => prev + 1)
    setCurrentLocalTimer((prev) => prev + 1)
  }, [])

  useEffect(() => {
    let intervalId: NodeJS.Timer

    if (isGlobalRunning) {
      intervalId = setInterval(() => {
        incrementTimer()
      }, 1000)
    }

    return () => {
      clearInterval(intervalId as any)
      if (isGlobalRunning) {
        dispatch(setGlobalTimer(localTimer))
        dispatch(setCurrentGlobalTimer(currentLocalTimer))
      }
    }
  }, [isGlobalRunning, incrementTimer, dispatch, localTimer, currentLocalTimer])

  const handleTimer = () => {
    dispatch(setIsGlobalRunning(!isGlobalRunning))
    const data = { taskId: currentGlobalTask?.id }
    if (globalNote) data['description'] = globalNote
    if (!isGlobalRunning) {
      dispatch(setGlobalTimer(localTimer))
      dispatch(setCurrentGlobalTimer(0))
      setCurrentLocalTimer(0)

      dispatch(startGlobalTimer(data))
    } else {
      dispatch(
        closeGlobalTimer({
          timerId: activeGlobalTimer?.id,
        }),
      ).then(() => {
        const counter = document.getElementById(`task-timer-${currentGlobalTask.id}`)
        const formatedTimeSeconds = document.getElementById(`format-time-${currentGlobalTask.id}`)
        if (formatedTimeSeconds) {
          dispatch(
            setCurrentGlobalTask({
              ...currentGlobalTask,
              timers: [...currentGlobalTask.timers, activeGlobalTimer],
              totalTime: currentGlobalTask.totalTime + currentGlobalTimer,
            }),
          )
          counter.innerHTML = `${currentGlobalTask.timers.length + 1}`
          formatedTimeSeconds.innerHTML = formatTime(
            currentGlobalTask.totalTime + currentGlobalTimer,
          )
          dispatch(setIsGlobalRunning(false))
          dispatch(setCurrentGlobalTimer(0))
          dispatch(setGlobalTimer(0))
          dispatch(setGlobalNote(''))
          dispatch(setSelectedTaskId(null))
          refetch()
        }
      }),
        dispatch(setCurrentGlobalTimer(0))
    }
  }

  const handleStopTaskDetailTimer = () => {
    dispatch(handleCounterProcess())
    // sync with global time

    dispatch(setIsGlobalRunning(false))
    dispatch(setCurrentGlobalTimer(0))
    dispatch(setCurrentGlobalTask(null))
    dispatch(setGlobalTimer(0))
    dispatch(setGlobalNote(''))
    dispatch(setSelectedTaskId(null))

    // sync with global time
    if (activeTimer) {
      dispatch(closeTimer({ timerId: activeTimer.id })).then(() => {
        dispatch(getTimers({ taskId: currentTask.id }))
      })
    }
    dispatch(stopTimerThunk())
  }

  const handleStartStopClick = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true)
      if (timer && isRunning) {
        handleStopTaskDetailTimer()
      } else {
        handleTimer()
      }

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 2000)
    }
  }

  return (
    <div className="global-timer-counter-container">
      <Button
        disabled={currentGlobalTask === null}
        style={
          !currentGlobalTask || !selectedTaskId || isButtonDisabled
            ? { cursor: 'not-allowed' }
            : { cursor: 'pointer' }
        }
        onClick={handleStartStopClick}
        className="global-timer-counter-button"
      >
        <Tooltip
          title={!currentGlobalTask || !selectedTaskId ? 'Select a task to start timer' : ''}
        >
          {isGlobalRunning && !activeGlobalTimer?.endTime ? <StopTimer /> : <StartTimer />}
        </Tooltip>
      </Button>

      <span>
        {isRunning && isRunning
          ? formatTime(isRunning ? currentTimer : timer)
          : formatTime(isGlobalRunning ? currentLocalTimer : globalTimer)}
      </span>
    </div>
  )
}

export default GlobalTimerCounter
