import { Empty } from 'antd'
import TimerListItem from './TimerListItem'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useGetTaskQuery } from '@src/modules/tasks/services/tasksApi'

import OneTaskTimersListSkeleton from './OneTaskTimersListSkeleton'
import { Tooltip } from 'antd'

interface Props {
  task: TaskType
}

function OnePostTimersList({ task }: Props) {
  const { data: t, isLoading } = useGetTaskQuery({ id: task.id } as any, {
    skip: !task,
  })
  if (isLoading) return <OneTaskTimersListSkeleton />
  return (
    <div className="one-post-timers-list" onClick={(e) => e.stopPropagation()}>
      <div className="timer-list-header">
        <div className="status" style={{ backgroundColor: t.status.color }}></div>

        <Tooltip title={t.name} placement="topLeft">
          <div className="task-title">
            {t.name.length > 25 ? t.name.slice(0, 25) + '...' : t.name}{' '}
          </div>
        </Tooltip>
        <span id={`task-timer-2-${task.id}`}>({t.timers.length})</span>
      </div>
      <div className="timer-list-items">
        {t.timers.length > 0 ? (
          t.timers?.map((timer) => <TimerListItem key={timer.id} timer={timer} task={t} />)
        ) : (
          <Empty />
        )}
      </div>
    </div>
  )
}

export default OnePostTimersList
