import { Tabs, Dropdown } from 'antd'
import filterIcon from '../../assets/icons/filter.svg'
import bell from '../../assets/icons/bell.svg'
import { useEffect, useRef, useState } from 'react'
import CommentInput from '@src/modules/tasks/components/EditTaskPopup/components/CommentInput'
import Open from '../Open/Open'
import AssignedToMe from '../AssignedToMe/AssignedToMe'
import Resolved from '../Resolved/Resolved'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { createComment, getAllComments } from '../../data/commentSlice/commentThunk'
import { useTranslation } from 'react-i18next'
import Spinner from '@src/modules/spaces/components/Spinner/Spinner'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import { ReactComponent as CommentIcon } from '../../assets/icons/darkComment.svg'
import { Tooltip } from 'antd'

type props = {
  isOpenComments: boolean
  setIsOpenComments: Function
  documentId: string
}

const CommentsSection = ({ isOpenComments, setIsOpenComments, documentId }: props) => {
  const { t } = useTranslation(['document'])
  const dispatch = useAppDispatch()
  const newRef = useRef(null)
  const { commentsList, deletedComment, status } = useAppSelector((state) => state.documentComments)

  const [comments, setComments] = useState(commentsList || [])
  const [activeTab, setActiveTab] = useState<string>('1')
  const [comment, setComment] = useState<string>('')
  const [filter, setFilter] = useState({})

  const items: any = [
    {
      key: '1',
      label: t('Open'),
      children: status === 'loading' ? <Spinner /> : <Open comments={comments} />,
    },
    {
      key: '2',
      label: t('Assigned To Me'),
      children: status === 'loading' ? <Spinner /> : <AssignedToMe comments={comments} />,
    },
    {
      key: '3',
      label: t('Resolved'),
      children: status === 'loading' ? <Spinner /> : <Resolved comments={comments} />,
    },
  ]

  useEffect(() => {
    isOpenComments &&
      dispatch(getAllComments({ documentId, filter }))
        .unwrap()
        .then((res) => {
          setComments(res?.payload)
        })
  }, [isOpenComments, filter, activeTab])

  useEffect(() => {
    if (deletedComment) {
      const newComments = comments.filter((comment) => comment.commentId !== deletedComment)
      setComments(newComments)
    }
  }, [deletedComment])

  const onChange = (key: string) => {
    setActiveTab(key)

    switch (key) {
      case '2':
        setFilter(
          JSON.stringify({
            where: {
              comment: {
                mentions: {
                  user_id: {
                    operation: '$in',
                    value: ['fcc93c20-c33c-4dc1-a803-d8866996344e'],
                  },
                },
              },
            },
          }),
        )
        break

      case '3':
        setFilter(
          JSON.stringify({
            where: {
              comment: {
                resolved: {
                  operation: '$Equal',
                  value: true,
                },
              },
            },
          }),
        )
        break

      default:
        setFilter({})
    }
  }

  const handleSubmitComment = () => {
    dispatch(createComment({ documentId, content: comment, resolved: true }))
      .unwrap()
      .then(() => {
        setComment('')
        dispatch(getAllComments({ documentId, filter }))
          .unwrap()
          .then((res) => {
            setComments(res?.payload)
          })
      })
  }

  return (
    <RTLStyleDecorator>
      <div
        className={`comment_section_conatiner ${
          isOpenComments ? 'visible-comment_section_conatiner' : ''
        }`}
        ref={newRef}
      >
        <div className="comments_header">
          <RTLStyleDecorator>
            <div className="close" onClick={() => setIsOpenComments(false)}>
              <Tooltip title="Close Comments">
                <CommentIcon />
              </Tooltip>
            </div>
          </RTLStyleDecorator>

          <span>{t('Comments')}</span>

          <div className="icons">
            <Dropdown
              dropdownRender={() => (
                <div className="filter">
                  <span>{t('Sort by date')}</span>

                  <span>{t('Sort by unread')}</span>
                </div>
              )}
              trigger={['click']}
            >
              <img src={filterIcon} />
            </Dropdown>

            <img src={bell} />
          </div>
        </div>

        <Tabs defaultActiveKey="1" className="comments_tabs" items={items} onChange={onChange} />

        <CommentInput
          comment={comment}
          holderId="comment-input"
          className="document_input_comment"
          setComment={setComment}
          onSubmit={handleSubmitComment}
          disabled={false}
        />
      </div>
    </RTLStyleDecorator>
  )
}

export default CommentsSection
