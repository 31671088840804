import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { ReactComponent as MemberIcon } from '../../assets/icons/sidebar/member-role.svg'
import { ReactComponent as ManagerIcon } from '../../assets/icons/manager/manager.svg'
import { ReactComponent as BackIcon } from './../../../settings/assets/icons/sidebar/Back.svg'
import { Avatar } from 'antd'
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { getMyTeams } from '@src/modules/settings/data/teams/TeamsThunk'
import Spinner from '@src/modules/spaces/components/Spinner'
import WorkspacePicker from '@src/modules/shared/components/WorkspacePicker'
import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import { useTranslation } from 'react-i18next'

export const isManagerCheck = (team, userId: string) => {
  return team?.managers?.find((manager) => manager?.userId === userId)
}

const Sidebar = () => {
  const { t } = useTranslation(['dailyQuestion'])
  const { teamId: selectedTeamId } = useParams()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const { myTeams, status } = useAppSelector((state) => state.teams)
  const userId = user?.user?.id
  const navigate = useNavigate()
  const { sidebar } = useAppSelector((state) => state.settings)

  useEffect(() => {
    dispatch(getMyTeams())
      .unwrap()
      .then(
        (res) =>
          res?.payload?.payload?.length > 0 &&
          !selectedTeamId &&
          navigate(`/daily-questions/${res?.payload?.payload[0]?.id}?tab=0`),
      )
  }, [])

  return (
    <div className="daily-question-sidebar">
      <div className="daily-question-sidebar-links">
        <Link to={'/dashboard'} className="daily-question-back-btn">
          <BackIcon /> {sidebar['daily-question-sidebar'] ? t('daily_question_title') : ''}
        </Link>

        {status === 'loading' ? (
          <Spinner />
        ) : (
          <div className="daily-question-teams hovered-scrollbar">
            {myTeams?.map((team) => {
              const isManager = !!isManagerCheck(team, userId)
              return (
                <NavLink
                  key={team?.id}
                  to={`/daily-questions/${team?.id}?tab=0`}
                  className={({ isActive }) =>
                    `daily-question-team ${isActive ? 'active-team' : ''}`
                  }
                >
                  <div className="team-icon">
                    <Avatar src={team.icon} style={{ background: !team.icon && team?.color }}>
                      {team?.name ? team?.name[0]?.toUpperCase() : '-'}
                    </Avatar>
                  </div>

                  <div className="team-info">
                    <ArabicElement tooltip tag="span" content={team?.name} />
                    <span>
                      ({team?.roles?.length} {team?.roles?.length > 1 ? t('members') : t('member')})
                    </span>
                  </div>
                  <div className="team-role">{isManager ? <ManagerIcon /> : <MemberIcon />}</div>
                </NavLink>
              )
            })}
          </div>
        )}
      </div>
      <WorkspacePicker />
    </div>
  )
}

export default Sidebar
