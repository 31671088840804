import React from 'react'

import AutoTimer from '../AutoTimer'

type Props = {
  showButtonLabel: boolean
  handleStop?: () => void
  handleStart?: () => void
}

const Auto: React.FC<Props> = ({ showButtonLabel, handleStop, handleStart }) => {
  return (
    <div>
      <AutoTimer
        showButtonLabel={showButtonLabel}
        handleStart={handleStart}
        handleStop={handleStop}
      />
    </div>
  )
}

export default Auto
