import { Avatar } from 'antd'

import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'

type Props = {
  task: TaskType
}

function TaskSearchLabel({ task }: Props) {
  return (
    <div key={`search-label-${task?.id}`} className="task-search-label">
      <div className="left-side">
        <div className="status" style={{ backgroundColor: task?.status?.color }}></div>
        <div className="task-title">{task?.name}</div>
      </div>
      <div className="users">
        {task?.users?.slice(0, 1).map((user) => (
          <Avatar key={user?.id} size="small" src={user?.avatar} className="task-search-label-user">
            {!user?.avatar && user?.fullName?.[0]}
          </Avatar>
        ))}
        {task?.users?.length > 1 && (
          <Avatar size="small" className="task-search-label-user">
            +{task?.users?.length - 1}
          </Avatar>
        )}
      </div>
    </div>
  )
}

export default TaskSearchLabel
