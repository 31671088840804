import { Avatar } from '@src/modules/shared/components/Avatar'
import TaskUsers from '@src/modules/tasks/components/TaskUsers'
import TeamActionMenu from '../TeamActionMenu/TeamActionMenu'
import { useNavigate } from 'react-router-dom'
import { Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const TeamList = ({ data, status }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['teams'])

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }
    return new Intl.DateTimeFormat('en-US', options).format(date)
  }

  const columns = [
    {
      title: t('name'),
      key: 'name',
      render: (data: any) => (
        <div className="col1">
          <Avatar text={data?.name || 'Team'} image={data?.icon} color={data?.color || '#A6A6A6'} />

          <Tooltip title={data?.name} placement="topLeft">
            <span className="team-name">
              {data?.name?.length > 15 ? data?.name.slice(0, 15) + '...' : data?.name || 'Team'}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('members'),
      key: 'members',
      render: (data: any) => (
        <TaskUsers
          displayAddButton={false}
          userNumber={3}
          allowRemoveUser={false}
          members={data?.roles?.map((role) => role.user)}
        />
      ),
    },
    {
      title: t('manager'),
      key: 'manager',
      render: (data: any) => {
        const manager = data?.managers?.[0]?.user
        const displayName = manager?.fullName || manager?.email
        const shortDisplayName =
          displayName?.length > 20 ? `${displayName?.slice(0, 20)}...` : displayName
        return (
          <div className="team-manager">
            {t('managed_by')}{' '}
            <span className="manager-name">
              <Tooltip title={displayName}>{shortDisplayName}</Tooltip>
            </span>
          </div>
        )
      },
    },
    {
      title: t('creation'),
      key: 'creation',
      render: (data: any) => (
        <div className="col4">
          <span className="creation-date">
            {t('created_on')} {formatDate(data?.createdAt || '2022-12-21T07:09:56.613Z')}
          </span>
        </div>
      ),
    },
    {
      title: t('settings'),
      key: 'settings',
      render: (data: any) => <TeamActionMenu data={data} />,
    },
  ]

  return (
    <Table
      dataSource={data}
      columns={columns}
      sticky={true}
      pagination={false}
      scroll={{ y: '65vh' }}
      loading={status === 'loading'}
      className="teams-table"
      locale={{ emptyText: t('no_data') }}
      onRow={(record) => {
        return {
          onClick: () => navigate(`/settings/teams/${record?.id}`),
        }
      }}
    />
  )
}

export default TeamList
