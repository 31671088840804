import { ReactComponent as SortSVG } from '../../../assets/icons/tasks/sort.svg'
// import { ReactComponent as SortEntireSVG } from '../../../assets/icons/tasks/sort-entire.svg'
// import { ReactComponent as GroupBySVG } from '../../../assets/icons/tasks/groupby.svg'
import { ReactComponent as ArrowUpSVG } from '../../../assets/icons/tasks/arrow-up.svg'
import { ReactComponent as ArrowDownSVG } from '../../../assets/icons/tasks/arrow-down.svg'
import { ReactComponent as MoveToStartSVG } from '../../../assets/icons/tasks/to-start.svg'
import { ReactComponent as MoveToEndSVG } from '../../../assets/icons/tasks/to-end.svg'
import { ReactComponent as DragSVG } from '../../../assets/icons/tasks/drag.svg'
import { ReactComponent as CloseSVG } from '../../../assets/icons/tasks/close-sort.svg'
import { ReactComponent as HideSVG } from '../../../assets/icons/tasks/hide.svg'
import { ReactComponent as ClearSortSVG } from '../../../assets/icons/tasks/clear-sort.svg'
import { column } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { getSort } from '../../Board/StatusCard/StatusCard'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useAppDispatch } from '@src/modules/shared/store'
import { setHeaders } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'

export const fieldItems = (_, field: column, actions, headers?: column[]) => {
  const sortedItems = getSort(headers)
  const dispatch = useAppDispatch()
  const handleOnDragEnd = (result) => {
    if (!result.destination) return

    const newItems = [...sortedItems]
    const [removed] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, removed)
    const newHeaders = headers.map((header) => {
      const existIndex = newItems?.findIndex((item) => item.id === header.id)

      return existIndex !== -1 ? { ...header, sortBy: existIndex + 1 } : header
    })
    dispatch(setHeaders(newHeaders))
  }

  return [
    {
      key: '22',
      className: 'sorting-fields',
      label: (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sorting-fields">
            {(provided) => (
              <div
                className="sorting-fields-content"
                {...provided.droppableProps}
                ref={provided.innerRef}
                onClick={(e) => e.stopPropagation()}
              >
                <p className="sorting-fields-title">Sorting Order</p>
                {sortedItems.map((sort, index) => (
                  <Draggable key={sort.fieldName} draggableId={sort.fieldName} index={index}>
                    {(provided) => (
                      <div
                        className="sorting-fields-item"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        onClick={() => actions.sort(sort)}
                      >
                        <span {...provided.dragHandleProps}>
                          <DragSVG className="drag-icon" />
                        </span>
                        <OverflowTooltip>{sort.fieldName}</OverflowTooltip>
                        <span className="sorting-fields-order">
                          {sort.sortOrder === 'ASC' ? <ArrowUpSVG /> : <ArrowDownSVG />}{' '}
                        </span>
                        <CloseSVG
                          className="close-icon"
                          onClick={(e) => {
                            e.stopPropagation()
                            actions.clearSort(sort)
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ),
      onClick: () => {
        // e?.stopPropaggation()
      },
    },
    {
      type: 'divider',
    },
    {
      key: '0',
      label: 'Sort',
      icon: <SortSVG />,
      onClick: () => actions.sort(field),
    },
    // {
    //   key: '1',
    //   label: 'Sort entire column',
    //   icon: <SortEntireSVG />,
    //   onClick: () => actions.entireSort(field),
    // },
    field.sortBy !== 0
      ? {
          key: '2',
          label: 'Clear sort',
          icon: <ClearSortSVG />,
          onClick: () => actions.clearSort(field),
          disabled: field.sortBy === 0,
        }
      : null,

    // {
    //   key: '3',
    //   label: 'Group',
    //   icon: <GroupBySVG />,
    //   onClick: () => actions.group(field),
    // },
    // {
    //   type: 'divider',
    // },
    {
      key: '4',
      label: 'Move to start',
      icon: <MoveToStartSVG />,
      onClick: () => actions.moveToStart(field),
    },
    {
      key: '5',
      label: 'Move to end',
      icon: <MoveToEndSVG />,
      onClick: () => actions.moveToEnd(field),
    },
    {
      type: 'divider',
    },
    {
      key: '6',
      label: 'Hide',
      icon: <HideSVG />,
      onClick: () => actions.hide(field),
    },
    // field.custom
    //   ? {
    //       key: '7',
    //       label: 'Edit Field',
    //       icon: <ClearSortSVG />,
    //       onClick: () => actions.updateField(field),
    //     }
    //   : null,

    field.custom
      ? {
          key: '9',
          label: 'Delete Field',
          icon: <ClearSortSVG />,
          onClick: () => actions.deleteField(field),
          danger: true,
        }
      : null,
  ] as any
}
