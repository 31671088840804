import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { setGlobalNote } from '@src/modules/tasks/data/globalTimerData/slices'
import NoteIcon from '../../assets/icons/NoteIcon'
import Input from '@src/modules/shared/components/Input/Input'
import { useTranslation } from 'react-i18next'
function NoteInput() {
  const dispatch: Function = useAppDispatch()
  const { globalNote } = useAppSelector((state) => state.globalTimer)
  const { t } = useTranslation(['tasks'])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGlobalNote(e.target.value))
  }

  return (
    <div className="global-timer-note-input">
      <NoteIcon />
      <Input
        value={globalNote}
        onChange={handleChange}
        type="text"
        placeholder={t('enter_a_note')}
        variant="label-inline"
      />
    </div>
  )
}

export default NoteInput
