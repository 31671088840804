import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { ReactComponent as CustomFieldSVG } from '@src/modules/tasks/assets/icons/tasks/customField.svg'
import { ReactComponent as AscSortSVG } from '../../../assets/asc-scort.svg'
import { ReactComponent as DescSortSVG } from '../../../assets/desc-scort.svg'

import { setCustomFields } from '@src/modules/tasks/data/tasksSlice/tasksSlice.ts'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Resizable } from 're-resizable'
import { Dropdown } from 'antd'
import CustomFieldsManagmentSidebar from '@src/modules/customFields/components/CustomFieldsMangmentSidebar'
import { useTranslation } from 'react-i18next'
// const formatItems = (items, field) => {
//   return items.map((item) =>
//     (item?.key === '0' || item?.key === '3') && field?.sortBy
//       ? {
//           ...item,
//           label: <div className="list-header-dropdown-item">{item?.label}</div>,
//         }
//       : item,
//   )
// }
const CustomFieldsTableHeader = ({ sort, setSort }) => {
  const { t } = useTranslation(['customFields'])
  const { customFields } = useAppSelector((state) => state.tasks)
  //   const { t } = useTranslation()
  const dispatch = useAppDispatch()
  //   const navigate = useNavigate()

  const onDragEnd = (result) => {
    if (!result.destination) return
    const updatedColumns = Array.from(customFields)
    const [movedColumn] = updatedColumns.splice(result.source.index, 1)
    updatedColumns.splice(result?.destination?.index, 0, movedColumn)
    dispatch(setCustomFields(updatedColumns))
  }

  const onResizeStop = (ref, index) => {
    const newWidth = Math.max(parseInt(ref.style.width.replace('px', ''), 10), 100)
    const updatedColumns = [...customFields]
    updatedColumns[index] = { ...updatedColumns[index], width: newWidth }
    dispatch(setCustomFields(updatedColumns))
  }

  return (
    <div className="list-veiw-header task-list custom-fields-header-table">
      <div className="col1">
        <div className="task-row-wrapper list-view-column">
          <div className="task-row-wrapper-content ">
            <div
              className="task-list-inner custom-fields-header-table-name-field"
              onClick={() => {
                if (sort?.element == 'name')
                  setSort({
                    element: 'name',
                    order: sort?.order === 'ASC' ? 'DESC' : 'ASC',
                  })
                else {
                  setSort({
                    element: 'name',
                    order: 'ASC',
                  })
                }
              }}
            >
              <div className="task-list-inner-content custom-fields-header-table-name-field ">
                <p>{t('Name')}</p>
                {sort.element === 'name' ? (
                  sort.order === 'ASC' ? (
                    <AscSortSVG />
                  ) : (
                    <DescSortSVG />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-columns" direction="horizontal">
          {(provided) => {
            return (
              <div
                className="list-view"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex' }}
              >
                {customFields?.map((field, index) => {
                  const customStyle = {
                    width: field?.width,
                    minWidth: field?.minWidth,
                    maxWidth: field?.maxWidth,
                    height: '25px',
                    x: 0,
                    y: 0,
                  }

                  if (field?.disable) return null

                  return (
                    <Draggable key={field.id} draggableId={field.id?.toString()} index={index}>
                      {(draggableProvided) => {
                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            className="list-view-column"
                            onClick={() => {
                              if (sort.element === field?.fieldName) {
                                setSort({
                                  element: field?.fieldName,
                                  order: sort.order === 'DESC' ? 'ASC' : 'DESC',
                                })
                              } else {
                                setSort({ element: field?.fieldName, order: 'ASC' })
                              }
                            }}
                          >
                            <Resizable
                              size={{
                                width: field?.width,
                                height: 'auto',
                              }}
                              enable={{
                                right: false,
                                left: true,
                                top: false,
                                bottom: false,
                                topRight: false,
                                bottomRight: false,
                                bottomLeft: false,
                                topLeft: false,
                              }}
                              handleClasses={{ left: 'resize-handle' }}
                              minWidth={field.minWidth || 100}
                              maxWidth={field.maxWidth}
                              onResizeStop={(_1, _, ref) => onResizeStop(ref, index)}
                            >
                              <div className="list-view-column">
                                <div
                                  {...draggableProvided.dragHandleProps}
                                  className="list-view-column"
                                  style={customStyle}
                                  key={field.id}
                                >
                                  <div className="custom-field-column">
                                    <p>{t(field?.fieldName)}</p>
                                    {sort.element === field?.fieldName ? (
                                      sort.order === 'ASC' ? (
                                        <AscSortSVG />
                                      ) : (
                                        <DescSortSVG />
                                      )
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </Resizable>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>

      <Dropdown
        dropdownRender={() => <CustomFieldsManagmentSidebar />}
        trigger={['click']}
        placement={'bottomCenter'}
        rootClassName="custom-fields"
      >
        <div className="list-view-column list-view-more-column">
          <span>
            <CustomFieldSVG />
          </span>
        </div>
      </Dropdown>
    </div>
  )
}

export default CustomFieldsTableHeader
