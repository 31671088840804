import { SVGProps } from 'react'

type Props = {}

function StopTimer(props: SVGProps<SVGSVGElement & Props>) {
  return (
    <svg
      {...props}
      width="20"
      height="19"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="29.57" height="26.9987" rx="10.9557" fill="white" />
      <rect x="8.69928" y="7.41357" width="12.1715" height="12.1715" rx="3.04288" fill="#9747FF" />
    </svg>
  )
}

export default StopTimer
