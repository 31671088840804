import Input from '@src/modules/shared/components/Input/Input'
import { convertToDayjs } from '@src/modules/spaces/utils/ConvertStringTotime'
import type { DatePickerProps } from 'antd'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import React, { BaseSyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type props = {
  setTimeInSeconds: (timeInSeconds: number) => void
  setStartDate: (startDate: string | Date) => void
  timerInputRef: React.MutableRefObject<HTMLInputElement | null>
  clearInput?: boolean
}

function TimeInput({ setTimeInSeconds, setStartDate, timerInputRef, clearInput }: props) {
  const { t } = useTranslation(['taskDetails'])
  const [value, setValue] = useState('')
  const [date, setDate] = useState<any>()
  const datePickerRef = React.useRef<any>(null)

  const convertToSeconds = (inputValue: string) => {
    const hoursRegex = /(\d+)\s*Hours/g
    const minutesRegex = /(\d+)\s*Minutes/g
    const secondsRegex = /(\d+)\s*Seconds/g
    let totalHours = 0
    let totalMinutes = 0
    let totalSeconds = 0

    let match
    while ((match = hoursRegex?.exec(inputValue)) !== null) {
      totalHours += parseInt(match[1])
    }

    // Match minutes
    while ((match = minutesRegex?.exec(inputValue)) !== null) {
      totalMinutes += parseInt(match[1])
    }

    // Match seconds
    while ((match = secondsRegex?.exec(inputValue)) !== null) {
      totalSeconds += parseInt(match[1])
    }

    const totalSecondsFromHours = totalHours * 3600
    const totalSecondsFromMinutes = totalMinutes * 60

    return totalSecondsFromHours + totalSecondsFromMinutes + totalSeconds
  }

  useEffect(() => {
    setStartDate(new Date())
  }, [])

  // Handle date change in date picker
  const onChange: DatePickerProps['onChange'] = (date, dateString: any) => {
    setDate(date)

    if (dateString) {
      try {
        const dayjsDate = convertToDayjs(dateString)
        setStartDate(dayjsDate.format('YYYY-MM-DD'))
      } catch (error) {
        setStartDate(date.toDate())
      }
    }
  }

  const handleInputChange = (e: BaseSyntheticEvent) => {
    let inputValue = e.target.value
    const lastChar = inputValue[inputValue.length - 1]

    const isUnitAlreadyPresent = (unit: string) => {
      return inputValue.includes(unit)
    }

    if (['h', 'H'].includes(lastChar) && !isUnitAlreadyPresent('Hours')) {
      inputValue = inputValue.slice(0, -1) + ' Hours '
    } else if (['m', 'M'].includes(lastChar) && !isUnitAlreadyPresent('Minutes')) {
      inputValue = inputValue.slice(0, -1) + ' Minutes '
    } else if (['s', 'S'].includes(lastChar) && !isUnitAlreadyPresent('Seconds')) {
      inputValue = inputValue.slice(0, -1) + ' Seconds '
    }

    if (/^(\d+\s*(Hours|Minutes|Seconds)?\s*)*$/.test(inputValue)) {
      setValue(inputValue)
      const durationInSeconds = convertToSeconds(inputValue)
      setTimeInSeconds(durationInSeconds)
    }
  }

  useEffect(() => {
    if (clearInput) {
      setValue('')
      datePickerRef.current.blur()
    }
  }, [clearInput])

  return (
    <>
      <div className="time-input-container">
        <Input
          inputRef={timerInputRef}
          type="text"
          value={value}
          onChange={handleInputChange}
          placeholder={t('Enter the time ex 3 hours 20 min 15 sec')}
          variant="label-inline"
          size="xs"
        />
      </div>

      <span className="date-label">{t('When:')} </span>

      <DatePicker
        ref={datePickerRef}
        onChange={onChange}
        bordered={false}
        suffixIcon={null}
        placeholder={t('Now')}
        value={date ? dayjs(date) : undefined}
      />
    </>
  )
}

export default TimeInput
