import { Segmented } from 'antd'
import { SegmentedValue } from 'antd/es/segmented'
import React, { useEffect, useState } from 'react'

import Button from '@src/modules/shared/components/Button/Button'
import Loading from '@src/modules/shared/components/Loading/Loading'
import { useAppDispatch } from '@src/modules/shared/store'
import { useGetTaskQuery } from '@src/modules/tasks/services/tasksApi'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../../shared/store/index'
import { setStartDate, setTimer } from '../../data/timerData/slices'
import { addManualTimer, getTimers } from '../../data/timerData/thunks'
import { ReactComponent as Clock2 } from './../../assets/icons/task/Clock.svg'
import { ReactComponent as SaveIcon } from './../../assets/icons/task/save-01.svg'
import { ReactComponent as ShieldIcon } from './../../assets/icons/task/shield-cross.svg'
import { formatTime } from './AutoTimer/AutoTimer'
import Auto from './components/Auto'
import Manual from './components/Manual'
import { items } from './constants/timer'
import ListTimers from './ListTimers'

interface Props {
  startIcon: any
  stopIcon: any
  showButtonLabel: boolean
  handleStop?: () => void
  handleStart?: () => void
}

const Timer: React.FC<Props> = ({ showButtonLabel, handleStop, handleStart }) => {
  const { t } = useTranslation(['tasks'])
  const dispatch: Function = useAppDispatch()
  const [searchparams, _] = useSearchParams()
  const taskId = searchparams.get('taskId')

  const timerInputRef = React.useRef<HTMLInputElement>(null)
  const [activeType, setActiveItem] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [clearInput, setClearInput] = useState<boolean>(false)

  const { startDate, timeInSeconds, timersMeta } = useAppSelector((state) => state.timer)
  const { data: task, refetch } = useGetTaskQuery({
    id: taskId as string,
  })

  useEffect(() => {
    taskId && dispatch(getTimers({ taskId }))
  }, [taskId])

  function renderView(activeType: number) {
    switch (Number(activeType)) {
      case 0:
        return (
          <Auto
            showButtonLabel={showButtonLabel}
            handleStart={handleStart}
            handleStop={handleStop}
          />
        )
      case 1:
        return <Manual timerInputRef={timerInputRef} clearInput={clearInput} />
    }
  }

  return (
    <div className="timer-container">
      <div className="timer-header">
        <div className="timer-button">
          <Segmented options={items} onChange={(e: SegmentedValue) => setActiveItem(e as number)} />
        </div>
        <div className="view-container">{renderView(activeType)}</div>
      </div>

      <div className="timer-footer">
        <div className="totale-time-container">
          <span className="totale-time-container-part-1">
            <Clock2 />
            {t('Total for this task')} ({timersMeta.total_items})
          </span>
          <span className="totale-time-container-part-2">{formatTime(task?.totalTime || 0)}</span>
        </div>

        <ListTimers taskId={taskId} />

        {Number(activeType) === 1 && (
          <div className="manuel-buttons-container">
            <Button onClick={() => setClearInput(!clearInput)} className="cancel-button">
              <ShieldIcon />
              {t('Cancel')}
            </Button>

            <Button
              style={
                loading || !startDate || !timeInSeconds
                  ? { cursor: 'not-allowed', pointerEvents: 'none' }
                  : {}
              }
              onClick={() => {
                setLoading(true)
                const newDate = new Date(startDate)
                newDate.setSeconds(newDate.getSeconds() + timeInSeconds)
                const endDate = newDate
                dispatch(
                  addManualTimer({
                    taskId: taskId,
                    startTime: startDate,
                    endTime: endDate.toISOString(),
                  }),
                ).then(() => {
                  taskId && dispatch(getTimers({ taskId }))
                  refetch().then(() => {
                    dispatch(setTimer(task.totalTime))
                    if (timerInputRef?.current) timerInputRef.current.value = ''
                  })
                  setClearInput(!clearInput)
                  dispatch(setStartDate(null))
                  setLoading(false)
                })
              }}
              className="save-button"
              disabled={loading || !startDate || !timeInSeconds}
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  <SaveIcon />
                  {t('Save')}
                </>
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Timer
