/* eslint-disable no-unsafe-optional-chaining */
import { api } from '@src/modules/shared/services/api'
// // import { CustomFieldType } from '../data/CustomFieldsSlice/CustomFieldsTypes'
// interface getMyCustomFieldsPayload {
//   skip?: number
//   limit?: number
//   options?: string
//   groupBy?: string
// }
interface getCustomFieldsPayload {
  statusId?: string
  search?: string
  options?: string
  groupBy?: string
  skip?: number
  sort?: any
  limit?: number
}

// const defaultLimit = 10

export const CustomFieldsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCustomFields: build.query<any, getCustomFieldsPayload>({
      query: (params) => {
        params = { ...params }
        return {
          url: `api/custom-fields`,
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, otherArgs) => {
        const withFilter = otherArgs?.arg?.search
        const shouldCache = !withFilter
        if (newItems?.payload?.length > 0) {
          currentCache.payload.push(...newItems.payload)
        }
        currentCache.metadata = newItems.metadata
        if (shouldCache) {
          currentCache.payload = otherArgs.arg.skip > 1 ? currentCache.payload : newItems.payload
        } else {
          currentCache.payload = newItems.payload
        }
        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      providesTags: (result = []) => {
        if (result?.payload?.payload) {
          return [
            ...result.payload.payload.map(({ id }: { id: string }) => ({
              type: 'CustomFields',
              id,
            })),
            { type: 'CustomFields', id: 'FIELDS' },
          ]
        } else {
          return [{ type: 'CustomFields', id: 'FIELDS' }]
        }
      },
    }),
    addCustomField: build.mutation<any, any>({
      query: ({ ...body }) => ({
        url: `api/custom-fields`,
        method: 'POST',
        body,
      }),
      //   invalidatesTags: [{ type: 'CustomFields', id: 'FIELDS' }] as any,
    }),

    updateCustomField: build.mutation<
      any,
      Partial<
        any & {
          tagsId: string[]
          previousParentId?: string
        }
      >
    >({
      query(data) {
        const { id, ...body } = data
        return {
          url: `api/custom-fields/${id}`,
          method: 'PATCH',
          body,
        }
      },
      // async onQueryStarted(_, { dispatch, queryFulfilled }) {
      //   try {
      //     const {
      //       data: { payload: data },
      //     } = (await queryFulfilled) || {}
      //     dispatch(
      //       CustomFieldsApi.util.updateQueryData(
      //         'getSubCustomFields',
      //         {
      //           parentId: data?.parentId || data?.id,
      //           fileId: data?.fileId,
      //           limit: 200,
      //           skip: 1,
      //         } as Partial<{
      //           parentId: string
      //         }> &
      //           getCustomFieldsPayload,
      //         (draft) => {
      //           if (!draft?.payload?.payload) return
      //           draft.payload.payload = draft.payload.payload?.map((CustomField) =>
      //             CustomField?.id === data?.id ? data : CustomField,
      //           )
      //         },
      //       ),
      //     )
      //   } catch {
      //     // patchResult.undo()
      //   }
      // },
      // invalidatesTags: (CustomField) => [{ type: 'SUBCustomFields', id: CustomField?.id }] as any,
    }),
    moveCustomField: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/custom-fields`,
          method: 'PATCH',
          body: data,
        }
      },

      // invalidatesTags: (CustomField) => [{ type: 'CustomFields', id: CustomField?.id }] as any,
    }),

    deleteCustomFields: build.mutation<any, Partial<any & { fileId: string }>>({
      query(data) {
        const { fileId } = data
        return {
          url: `api/custom-fields/${fileId}`,
          method: 'DELETE',
        }
      },
      //   invalidatesTags: (CustomField) => [{ type: 'CustomFields', id: CustomField?.id }] as any,
    }),

    getCustomFieldsWithSearch: build.query<any, getCustomFieldsPayload>({
      query: (params) => {
        params = { ...params }
        return {
          url: `api/custom-fields`,
          params,
        }
      },
      providesTags: (result = []) => {
        return result
          ? [
              result?.payload?.payload.map(
                ({ id }: { id: string }) => ({ type: 'CustomFields', id }) as const,
              ),
              { type: 'CustomFields', id: 'FIELDS' },
            ]
          : []
      },
    }),
  }),
})

// export const updateCustomField = (CustomField: any, query: getCustomFieldsPayload) => {
//   return CustomFieldsApi.util.updateQueryData('getCustomFields', query, (draftCustomFields) => {
//     return {
//       new: CustomField,
//       ...draftCustomFields,
//     }
//   })
// }

export const {
  useGetAllCustomFieldsQuery,
  useAddCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useDeleteCustomFieldsMutation,
  useGetCustomFieldsWithSearchQuery,
} = CustomFieldsApi
