import Input from '@src/modules/shared/components/Input/Input'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import {
  setGlobalTimeHrs,
  setGlobalTimeInSeconds,
  setGlobalTimeMins,
} from '@src/modules/tasks/data/globalTimerData/slices'

function Index() {
  const dispatch: Function = useAppDispatch()
  const { globalTimeHrs, globalTimeMins } = useAppSelector((state) => state.globalTimer)

  const appendMinutes = (e) => {
    const minutes = Math.max(0, parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0)
    dispatch(setGlobalTimeMins(minutes))
    dispatch(setGlobalTimeInSeconds(minutes * 60 + globalTimeHrs * 3600))
  }

  const appendHours = (e) => {
    const hours = Math.max(0, parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0)
    dispatch(setGlobalTimeHrs(hours))
    dispatch(setGlobalTimeInSeconds(hours * 3600 + globalTimeMins * 60))
  }

  return (
    <div className="manual-timer-inputs">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18.9582C5.05835 18.9582 1.04169 14.9415 1.04169 9.99984C1.04169 5.05817 5.05835 1.0415 10 1.0415C14.9417 1.0415 18.9584 5.05817 18.9584 9.99984C18.9584 14.9415 14.9417 18.9582 10 18.9582ZM10 2.2915C5.75002 2.2915 2.29169 5.74984 2.29169 9.99984C2.29169 14.2498 5.75002 17.7082 10 17.7082C14.25 17.7082 17.7084 14.2498 17.7084 9.99984C17.7084 5.74984 14.25 2.2915 10 2.2915Z"
          fill="#374957"
        />
        <path
          d="M13.0917 13.275C12.9833 13.275 12.875 13.25 12.775 13.1833L10.1917 11.6416C9.55001 11.2583 9.07501 10.4166 9.07501 9.67497V6.2583C9.07501 5.91663 9.35835 5.6333 9.70001 5.6333C10.0417 5.6333 10.325 5.91663 10.325 6.2583V9.67497C10.325 9.97497 10.575 10.4166 10.8333 10.5666L13.4167 12.1083C13.7167 12.2833 13.8083 12.6666 13.6333 12.9666C13.5083 13.1666 13.3 13.275 13.0917 13.275Z"
          fill="#374957"
        />
      </svg>

      <Input
        containerClassName="manual-timer-inputs-input"
        maxLength={3}
        accept="number"
        value={globalTimeHrs === 0 ? '' : globalTimeHrs}
        onChange={appendHours}
        type="number"
        placeholder="4 hours"
        variant="label-inline"
        onInput={(e: any) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
      />
      <Input
        containerClassName="manual-timer-inputs-input"
        maxLength={3}
        accept="number"
        value={globalTimeMins === 0 ? '' : globalTimeMins}
        onChange={appendMinutes}
        type="number"
        placeholder="20 mins"
        variant="label-inline"
        onInput={(e: any) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
      />
    </div>
  )
}

export default Index
