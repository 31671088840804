import { useAppDispatch } from '../../../../shared/store/index'
import { setStartDate, setTimeInSeconds } from '../../../data/timerData/slices'
import TimeInput from '../TimeInput/TimeInput'

const Manual = ({
  timerInputRef,
  clearInput,
}: {
  timerInputRef: React.MutableRefObject<HTMLInputElement | null>
  clearInput?: boolean
}) => {
  const dispatch: Function = useAppDispatch()
  const handleSetStartDate = (startDate: string) => {
    dispatch(setStartDate(startDate))
  }
  const handleSetTimeInSeconds = (timeInSeconds: number) => {
    dispatch(setTimeInSeconds(timeInSeconds))
  }
  return (
    <div>
      <TimeInput
        timerInputRef={timerInputRef}
        setTimeInSeconds={handleSetTimeInSeconds}
        setStartDate={handleSetStartDate}
        clearInput={clearInput}
      />
    </div>
  )
}

export default Manual
