function TaskSearchEmpty() {
  return (
    <div className="task-search-label-empty">
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.33337 14.3335C3.74671 14.3335 0.833374 11.4202 0.833374 7.8335C0.833374 4.24683 3.74671 1.3335 7.33337 1.3335C10.92 1.3335 13.8334 4.24683 13.8334 7.8335C13.8334 11.4202 10.92 14.3335 7.33337 14.3335ZM7.33337 2.3335C4.30004 2.3335 1.83337 4.80016 1.83337 7.8335C1.83337 10.8668 4.30004 13.3335 7.33337 13.3335C10.3667 13.3335 12.8334 10.8668 12.8334 7.8335C12.8334 4.80016 10.3667 2.3335 7.33337 2.3335Z"
          fill="#374957"
        />
        <path
          d="M13.4401 15.6933C13.3867 15.6933 13.3334 15.6867 13.2867 15.68C12.9734 15.64 12.4067 15.4267 12.0867 14.4733C11.9201 13.9733 11.9801 13.4733 12.2534 13.0933C12.5267 12.7133 12.9867 12.5 13.5134 12.5C14.1934 12.5 14.7267 12.76 14.9667 13.22C15.2067 13.68 15.1401 14.2667 14.7601 14.8333C14.2867 15.5467 13.7734 15.6933 13.4401 15.6933ZM13.0401 14.16C13.1534 14.5067 13.3134 14.68 13.4201 14.6933C13.5267 14.7067 13.7267 14.58 13.9334 14.28C14.1267 13.9933 14.1401 13.7867 14.0934 13.6933C14.0467 13.6 13.8601 13.5 13.5134 13.5C13.3067 13.5 13.1534 13.5667 13.0667 13.68C12.9867 13.7933 12.9734 13.9667 13.0401 14.16Z"
          fill="#374957"
        />
      </svg>
      <div className="task-title-search-empty">Search for task name, ID, or URL...</div>
    </div>
  )
}

export default TaskSearchEmpty
