import React, { useEffect, useState } from 'react'
import { isValidImageUrl } from '../../utils/isValidURL'
import PropTypes from 'prop-types'

interface ColorSelectProps {
  text: string
  color: string
  image: any
  className?: string
}
const capitalizeFirstLetter = (str: string): string => {
  return str?.charAt(0)?.toUpperCase()
}

const Avatar: React.FC<ColorSelectProps> = ({ image, text, color, className }) => {
  const capitalizedText = capitalizeFirstLetter(text)
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      setIsValid((await isValidImageUrl(image)) as boolean)
    })()
  }, [image])

  return (
    <div
      className={`avatar-container ${className}`}
      style={{
        backgroundColor: color,
      }}
    >
      {isValid && image !== null ? (
        <img src={image} alt="avatar" loading="lazy" />
      ) : (
        <p>{capitalizedText}</p>
      )}
    </div>
  )
}

export default Avatar

Avatar.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.any,
}
