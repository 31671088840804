import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { resetFiles } from '@src/modules/shared/store/slices/settings/settingsSlice'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { useGetTaskQuery, useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { message, Modal } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { setTimer } from '../../data/timerData/slices'
import { getTimers } from '../../data/timerData/thunks'
import { TaskStatus } from '../TaskStatus'
import TimerDropDown from '../Timer/components/TimerDropdown'
import Comments from './components/Comments/index'
import Estimation from './components/Estimation'
import ModalTitle from './components/ModalTitle'
import ParentNavigation from './components/ParentNavigation/ParentNavigation'
import StickyTaskName from './components/StickyTaskName'
import Tabs from './components/Tabs/index'
import TaskDetailSkeleton from './components/TaskDetailSkeleton'
import TaskDetailTitleSkeleton from './components/TaskDetailSkeleton/TaskDetailTitleSkeleton'
import TaskModelSideBar from './components/TaskModelSideBar/TaskModelSideBar'
import Description from './views/Description'
import SetTaskAttachements from './views/SetTaskAttachements'
import SetTaskDate from './views/setTaskDate'
import SetTaskPriority from './views/SetTaskPriority'

import TaskName from './views/TaskName'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import AssignUsers from './views/AssignUsers'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import Tags from '@src/modules/shared/components/Tags/Tags'
import NotFound from '@src/modules/shared/features/NotFound/NotFound'
import { useTranslation } from 'react-i18next'
import { concatStatusFilter } from '../Board/StatusCard/StatusCard'
import { GroupByFields } from '../../features/Tasks/Tasks'
import { getSortFieldByGroupBy } from '../../utils/getSortFieldByGroupBy'

function EditTaskPopup() {
  const { width } = useWindowSize()
  const {
    screenRecording: { isRecording, taskId: RecordedTaskId },
  } = useAppSelector((state) => state.settings)
  const { deletedTask } = useAppSelector((state) => state.tasks)
  const [searchParams, setSearchparams] = useSearchParams()
  const { t } = useTranslation(['taskDetails'])
  const taskId = searchParams.get('taskId')
  const { listId } = useParams()
  const { user } = useAppSelector((state) => state.auth)
  const { tasks: tasksFilter } = useAppSelector((state) => state.filter)
  const { headers } = useAppSelector((state) => state.tasks)
  const search = searchParams.get('search')
  const filterByMe = searchParams.get('byMe')
  const groupByIndex = searchParams.get('groupBy') || '0'
  const groupBy = Object.values(GroupByFields)[groupByIndex]
  const { data: list } = useGetListQuery({ id: listId }, { skip: !listId })

  const filter = concatStatusFilter(
    {
      ...(search ? { name: { operation: '$contains', value: search } } : {}),

      ...(filterByMe && user?.user?.id
        ? { assigned_to: { operation: '$in', value: [user.user.id] } }
        : {}),
    },
    tasksFilter,
    listId,
    headers,
    groupBy,
  )

  const {
    data: task,
    isLoading,
    error,
  } = useGetTaskQuery(
    {
      id: taskId,
      options: filter,
      orderField: getSortFieldByGroupBy(groupBy),
    },
    {
      skip: !taskId,
      refetchOnMountOrArgChange: true,
    },
  )
  const activityRef = useRef(null)
  const commentRef = useRef(null)
  const containerRef = useRef(null)
  const [updateTask] = useUpdateTaskMutation()
  const [initialStatus, setInitialStatus] = useState<statusType | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [modalView, setModalView] = useState<number>(0)
  const dispatch: Function = useAppDispatch()
  const [widthWindow, setWidthWindow] = useState<number>(65)
  const canNotCancel = isRecording && RecordedTaskId === taskId

  const handleScroll = (e) => {
    const scrollTop = e.currentTarget.scrollTop
    setHasScrolled(scrollTop > 200)
  }

  useEffect(() => setWidthWindow(width > 1255 ? 68 : 65), [width])

  useEffect(() => {
    const el1 = activityRef?.current?.querySelector('.task-estimation')
    if (activityRef?.current?.clientWidth < 530 && el1) {
      el1.style.flex = 'auto'
    } else if (activityRef?.current?.clientWidth > 530 && el1) {
      el1.style.flex = 'unset'
    }
  }, [activityRef?.current?.clientWidth])

  const showModal = () => {
    if (!isModalOpen) setIsModalOpen(true)
  }

  const handleOk = () => {
    if (canNotCancel) return
    setIsModalOpen(false)
    setSearchparams({})
  }

  const handleCancel = () => {
    if (canNotCancel) return
    if (task?.description?.includes('{"type":"spinner"')) {
      message.warning(t('cannot_close_modal_because_an_upload_is_in_progress'))
      return
    }
    setIsModalOpen(false)
    searchParams.delete('taskId')
    setSearchparams(searchParams)
  }

  const dragHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      const w =
        ((e.clientX - e.currentTarget.parentElement.parentElement.getBoundingClientRect().left) /
          e.currentTarget.parentElement.parentElement.getBoundingClientRect().width) *
        100
      if (w > 0 && w !== widthWindow && w > 43 && w < 78) {
        setWidthWindow(w)
      }
    },
    [widthWindow],
  )

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'none'
    event.dataTransfer.effectAllowed = 'none'
  }

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', '')
    event.target.style.cursor = 'col-resize'
    const ghostElement = document.createElement('div')
    ghostElement.className = 'ghost-element'
    ghostElement.style.position = 'absolute'
    ghostElement.style.top = '0'
    ghostElement.style.left = '0'
    ghostElement.style.width = '1px'
    ghostElement.style.height = '1px'
    ghostElement.textContent = ''
    document.body.appendChild(ghostElement)
    event.dataTransfer.setDragImage(ghostElement, 0, 0)
  }

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const ghostElement = document.querySelector('.ghost-element')
    if (ghostElement) {
      ghostElement.remove()
    }
  }

  useEffect(() => {
    RecordedTaskId === taskId && dispatch(dispatch(resetFiles()))
    if (taskId && !isModalOpen) showModal()
    else if (!taskId) setIsModalOpen(false)
  }, [taskId])

  useEffect(() => {
    if (task) {
      useTitle(task?.name)
      setInitialStatus(task?.status as unknown as statusType)
      dispatch(setTimer(task.totalTime || 0))
      dispatch(
        getTimers({
          taskId,
        }),
      )
    }
  }, [task])

  useEffect(() => {
    if (deletedTask && deletedTask.includes(taskId)) handleCancel()
  }, [deletedTask])

  return (!error && task) || isLoading ? (
    <Modal
      closeIcon={null}
      footer={null}
      title={
        !isLoading ? (
          <ModalTitle task={task} isLoading={isLoading} handleCancel={handleCancel} />
        ) : (
          <TaskDetailTitleSkeleton />
        )
      }
      open={canNotCancel || isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      className="-edit-task-modal"
    >
      {isLoading || task?.id !== taskId ? (
        <TaskDetailSkeleton />
      ) : (
        <div className="-edit-task-popup" key={taskId}>
          <div className="-edit-task-popup-container resizable-x" ref={containerRef}>
            {modalView !== 2 && (
              <RTLStyleDecorator>
                <div
                  style={{
                    width: `${widthWindow}%`,
                    height: '80vh',
                    flex: modalView === 1 && 1,
                  }}
                  className={`form-side`}
                  onScroll={handleScroll}
                  ref={activityRef}
                >
                  {task?.parentId && (
                    <div style={{ paddingBottom: '10px' }}>
                      <ParentNavigation id={task.parentId} title={task?.parent?.name} />
                    </div>
                  )}

                  <StickyTaskName task={task} hasScrolled={hasScrolled} />

                  <div className="form-side-header">
                    <TaskStatus
                      onSelect={(value) => {
                        setInitialStatus(value)
                        updateTask({ id: taskId, statusId: value.id }).then(
                          (res: { data: { payload: { status: string } } }) => {
                            const status = res?.data?.payload?.status
                            if (status) setInitialStatus(status as unknown as statusType)
                          },
                        )
                      }}
                      listId={listId}
                      activeStatus={initialStatus as unknown as statusType}
                    />

                    <AssignUsers task={task} />

                    <SetTaskDate
                      taskId={taskId}
                      task={task}
                      taskStartDate={task?.startDate}
                      taskDueDate={task?.dueDate}
                      isEditTaskPopup={true}
                    />

                    <SetTaskPriority task={task} taskId={taskId} defaultValue={task?.priority} />
                    <TimerDropDown task={task} taskId={taskId} />
                    <Estimation task={task} />
                  </div>

                  <TaskName taskId={taskId} defaultTaskName={task?.name} />

                  <Description taskDescription={task?.description} taskId={taskId} task={task} />

                  <Tags
                    tags={task?.tags}
                    spaceId={list?.spaceId}
                    taskId={task?.id}
                    CountOfTags={4}
                    maxOfTextLength={10}
                  />

                  <Tabs task={task} />

                  <SetTaskAttachements taskAttachments={task?.attachements} />
                </div>
              </RTLStyleDecorator>
            )}

            {modalView === 0 && (
              <div
                draggable
                onDrag={dragHandler}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                className="resizer-x"
              ></div>
            )}

            {modalView !== 1 && (
              <div
                className="comments"
                style={{
                  width: `${100 - widthWindow}%`,
                  height: '80vh',
                  flex: modalView === 2 && 1,
                }}
                ref={commentRef}
              >
                <Comments />
              </div>
            )}

            {width < 1140 ? (
              <TaskModelSideBar setModalView={setModalView} current={modalView} />
            ) : (
              modalView !== 0 && <>{setModalView(0)}</>
            )}
          </div>
        </div>
      )}
    </Modal>
  ) : (
    <div>
      <NotFound
        statusCode={404}
        message="List Not Found"
        underMessage="The list you're looking for doesn't seem to exist."
        buttonName="Take Me Back"
        navigateTo="/"
        takeMeBack={false}
      />
    </div>
  )
}

export default EditTaskPopup
