import { ListIcon, listActions } from '@src/modules/spaces/components/List/List'
import { PermissionesAction, items } from '@src/modules/spaces/utils/spaceActions'
import { ReactComponent as SettingsIcon } from './../../../../spaces/assets/icons/list/settings.svg'
import { ReactComponent as LockIcon } from './../../../../spaces/assets/icons/space/lock.svg'
import { Dropdown, message } from 'antd'
import { useAppDispatch } from '@src/modules/shared/store'
import { useState } from 'react'
import { updateList } from '@src/modules/spaces/data/fileSlice/fileThunk'
import { setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import RTLStyleDecorator from '../../RTLStyleDecorator'
import Toggle from '../Toggle'
import OverflowTooltip from '../../OverflowTooltip'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '@src/modules/shared/components/Input/Input'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ListRow = ({ isSelected, data, select, readOnly, isImport, multiple }) => {
  const dispatch = useAppDispatch()
  const [isRename, setRename] = useState<boolean>(false)
  const { t } = useTranslation(['modals'])
  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t('list.invalid_name')),
  })

  const formik = useFormik({
    initialValues: { name: data.name || '' },
    validationSchema,
    onSubmit: (values) => {
      if (values.name !== data.name) {
        dispatch(updateList({ id: data.id || '', name: values.name }))
          .unwrap()
          .then(() =>
            dispatch(
              setSpacesSocketEvent({
                updateType: 'updatedItem',
                updateEvent: { ...data, name: values.name },
              }),
            ),
          )
          .catch((err) => {
            message.error(err.message || t('list.Error_while_updating_list_name'))
          })
          .finally(() => setRename(false))
      } else {
        setRename(false)
      }
    },
  })

  const actions = { ...listActions, onrename: () => setRename(!isRename) }
  const location = useLocation()
  const pathName = ['/documents', '/dashboard', '/notifications']
  const isNotSelected = pathName?.includes(location?.pathname)
  return (
    <RTLStyleDecorator>
      <div
        className={`list-item list-row-tree ${isSelected && !isNotSelected ? 'active-list-tree' : ''}`}
      >
        <div className="col1" onClick={isImport || select}>
          <div className="list-row-tree-icon">
            <ListIcon color={data?.color} />
          </div>

          <div className="list-item-name list-row-tree-name">
            {!isRename ? (
              <OverflowTooltip children={data.name} />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Input
                  type="text"
                  name="name"
                  disabled={false}
                  variant="label-inline"
                  onClick={(e) => e.stopPropagation()}
                  onBlur={() => formik.handleSubmit()}
                  className="sidebar-rename-input"
                  size="xxs"
                  formik={formik}
                  autoFocus
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      formik.handleSubmit()
                    } else if (e.key === 'Escape') {
                      setRename(false)
                    }
                  }}
                />
              </form>
            )}
          </div>
        </div>

        {data.isPrivate ? <LockIcon className="space-lock-icon" /> : null}

        {!readOnly && (
          <Dropdown
            menu={{
              items: items(
                { ...data, resource: 'list', parent: data.parent },
                [
                  PermissionesAction.NO_CREATE,
                  PermissionesAction.NO_OUT_FROM_SPACE,
                  PermissionesAction.NO_CREATE_FOLDER,
                  PermissionesAction.NO_CREATE_LIST,
                  PermissionesAction.NO_CREATE_DOCUMENT,
                ],
                actions,
              ),
            }}
            trigger={['click']}
          >
            <div className="list-item-settings">
              <SettingsIcon />
            </div>
          </Dropdown>
        )}

        {isImport ||
          (multiple && <Toggle select={select} isSelected={isSelected} isMargin={true} />)}
      </div>
    </RTLStyleDecorator>
  )
}

export default ListRow
