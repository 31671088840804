import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import { message, Popover, Spin } from 'antd'
import EmojiPicker, { Theme } from 'emoji-picker-react'
import { forwardRef, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmojiIcon from '../assets/icons/EmojiIcon'
import ImageIcon from '../assets/icons/ImageIcon'
import AttachmentIcon from '../assets/icons/AttachmentIcon'
import Button from '@src/modules/shared/components/Button/Button'

type CommentInputProps = {
  comment: string
  setComment: Function
  onSubmit: () => void
  holderId: string
  disabled?: boolean
  hideSendButton?: boolean
  returnValue?: (value: string) => void
  className?: string
  reset?: boolean
  setReset?: (value: boolean) => void
}

const CommentInput = memo(
  forwardRef<HTMLDivElement, CommentInputProps>(
    ({ comment, setComment, onSubmit, hideSendButton, className, reset, setReset }, ref) => {
      const { t } = useTranslation(['taskDetails'])
      const isEmpty = false

      const [emojiPopoverVisible, setEmojiPopoverVisible] = useState(false)
      const [isUploading, setUploading] = useState<boolean>(false)
      const [isIconAttachementActive, setIsIconAttachementActive] = useState(undefined)
      const [isIconImageSelected, setIsIconImageSelected] = useState(undefined)

      const [em, setEmoji] = useState('')

      const typeAtOnBlock = () => setIsIconAttachementActive(!isIconAttachementActive)

      const handleImageUploadClick = () => setIsIconImageSelected(!isIconImageSelected)
      const theme = localStorage.getItem('theme')
      const handleSubmit = () => {
        if (isEmpty) return message.info(t('Comment must be not empty'))
        onSubmit()
        setUploading(false)
      }
      function isContentEmpty(nodes) {
        const data = nodes && JSON.parse(nodes)
        if (!data) return true
        return data.every((node) => {
          if (node?.type == 'mention_tam') return false
          if (node?.type == 'table') return false
          if (node?.type == 'img') return false
          if (node?.type == 'mention_tam') return false
          if (node?.type == 'code_block') return false
          if (node?.type == 'insert-math') return false

          if (!node.children) return true
          return node?.children?.every((child) => {
            if (child?.type == 'mention_tam') return false

            if (child?.type == 'a') return false
            if (!child.text) return true
            return child.text.trim() === ''
          })
        })
      }
      const isDisabled =
        comment.includes('"type":"upload-image"') ||
        comment.includes('"type":"upload-video"') ||
        comment.includes('"type":"upload-file"') ||
        isContentEmpty(comment)
      return (
        <div className={`comment-input ${className}`} ref={ref} id="comment-input">
          <div style={{ width: '100%' }} className="blockNoteContainer">
            <BlockNoteRichText
              value={comment}
              setValue={setComment}
              focus={true}
              reset={reset}
              setReset={setReset}
              emoji={em}
              setEmoji={setEmoji}
              isIconAttachementActive={isIconAttachementActive}
              isIconImageSelected={isIconImageSelected}
            />
          </div>

          <div className="comment-input-actions">
            <Button
              className="comment-input-actions-icon"
              size="xs"
              onClick={() => typeAtOnBlock()}
            >
              <AttachmentIcon />
            </Button>

            <Popover
              content={
                <EmojiPicker
                  theme={theme == 'dark' ? Theme.DARK : theme == 'light' ? Theme.LIGHT : Theme.AUTO}
                  onEmojiClick={(emoji) => {
                    setEmoji(emoji?.emoji)
                    setEmojiPopoverVisible(false)
                  }}
                />
              }
              trigger="click"
              open={emojiPopoverVisible}
              onOpenChange={(visible) => setEmojiPopoverVisible(visible)}
            >
              <Button className="comment-input-actions-icon" shape="circle" size="xs">
                <EmojiIcon />
              </Button>
            </Popover>

            <Button
              onClick={() => handleImageUploadClick()}
              size="xs"
              className="comment-input-actions-icon"
            >
              {/* <Button className="comment-input-actions-icon"> */}
              <ImageIcon />
            </Button>

            {!hideSendButton ? (
              <Button
                htmlType="submit"
                onClick={() => handleSubmit()}
                disabled={isDisabled}
                style={isEmpty ? { cursor: 'not-allowed' } : {}}
                className="comment-input-submit"
              >
                {isUploading ? <Spin /> : t('send')}
              </Button>
            ) : null}
          </div>
        </div>
      )
    },
  ),
)

export default CommentInput
