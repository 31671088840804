import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'

import AutoTimer, { formatTime } from '../AutoTimer/AutoTimer'
import { ReactComponent as StartTime } from '../../../assets/icons/task/timer-start.svg'
import { ReactComponent as StopTime } from '../../../assets/icons/task/timer-pause.svg'
import { ReactComponent as Clock } from '../../../assets/icons/task/timer.svg'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { startTimer, closeTimer, getTimers } from '../../../data/timerData/thunks'
import Timer from '..'
import {
  setCurrentTimer,
  setIsActiveTaskTimer,
  setTimer,
  startTimerThunk,
} from '@src/modules/tasks/data/timerData/slices'
import {
  setCurrentGlobalTask,
  setCurrentGlobalTimer,
  setIsRunning as setGlobalIsRunning,
  setGlobalNote,
  setGlobalTimer,
  setSelectedTaskId,
} from '@src/modules/tasks/data/globalTimerData/slices'
import Button from '@src/modules/shared/components/Button/Button'

type Props = {
  task: TaskType
  taskId: string
}

const TimerDropDown = ({ task, taskId }: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const { activeTimer } = useAppSelector((state) => state.timer)
  const dispatch: Function = useAppDispatch()
  const { timers, currentTimer, timer } = useAppSelector((state) => state.timer)
  const isFirstRenderRef = React.useRef(true)

  const handleDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible)
  }

  const handleTimerClose = () => {
    // sync with global time

    dispatch(setGlobalIsRunning(false))
    dispatch(setCurrentGlobalTimer(0))
    dispatch(setCurrentGlobalTask(null))
    dispatch(setGlobalTimer(0))
    dispatch(setGlobalNote(''))
    dispatch(setSelectedTaskId(null))

    // sync with global time
    if (activeTimer) {
      dispatch(closeTimer({ timerId: activeTimer.id })).then(() => {
        taskId && dispatch(getTimers({ taskId }))
        const totalTimeContainer = document.querySelector('.totale-time-container-part-2')
        if (totalTimeContainer) {
          totalTimeContainer.innerHTML = formatTime(currentTimer + task.totalTime)
        }
      })
    }
  }
  const handleTimerStart = () => {
    // sync with global time

    dispatch(setGlobalIsRunning(true))
    dispatch(setCurrentGlobalTimer(currentTimer))
    dispatch(setCurrentGlobalTask(task))
    dispatch(setGlobalTimer(timer))
    //  dispatch(setGlobalNote(''))
    dispatch(setSelectedTaskId(taskId))

    // sync with global time
    dispatch(
      startTimer({
        taskId: taskId,
      }),
    )
    dispatch(setIsActiveTaskTimer(taskId))
  }

  useEffect(() => {
    if (task && timers && currentTimer !== 0) {
      const activeTaskTimer = timers?.find((timer) => timer?.task?.id === taskId)

      if (!activeTaskTimer?.endTime && activeTaskTimer) {
        dispatch(setTimer(timer + currentTimer || 0))
        dispatch(setCurrentTimer(currentTimer))
        dispatch(startTimerThunk(taskId))
      }
    }
    isFirstRenderRef.current = false
  }, [task, timers, taskId, dispatch])

  return (
    <div className="timer-dropdown-container">
      <Dropdown
        open={dropdownVisible}
        onOpenChange={handleDropdownVisibleChange}
        visible={dropdownVisible}
        dropdownRender={() => (
          <Timer
            showButtonLabel={true}
            startIcon={<StartTime />}
            stopIcon={<StopTime />}
            handleStart={handleTimerStart}
            handleStop={handleTimerClose}
          />
        )}
        trigger={['click']}
      >
        <>
          <div className="timer-button-container">
            <Button
              className="timer-button"
              onClick={() => {
                setDropdownVisible(!dropdownVisible)
              }}
            >
              <Clock />
            </Button>
          </div>

          <AutoTimer
            showButtonLabel={false}
            handleStart={handleTimerStart}
            handleStop={handleTimerClose}
          />
        </>
      </Dropdown>
    </div>
  )
}

export default TimerDropDown
