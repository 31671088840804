import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { memo, useCallback, useEffect, useState } from 'react'
import { ReactComponent as AddSubtaskIcon } from './../../../assets/icons/task/add-subtask.svg'
import { ReactComponent as SubtaskIcon } from './../../../assets/icons/task/hierarchy.svg'
// import { ReactComponent as TaskSkeletonIcon } from './../../../assets/icons/skeleton/task/task-list.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TaskStatus } from '../../TaskStatus'
import TaskName from '../../TaskName'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { maxLevel } from '../ListView'
import TaskDescriptionPreview from '../../TaskDescriptionPreview'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import ListColumns from '../ListColumns'
import Tags from '@src/modules/shared/components/Tags/Tags'
import {
  useCreateCustomFieldValueMutation,
  useDeleteCustomFieldValueMutation,
  useUpdateCustomFieldValueMutation,
} from '@src/modules/tasks/services/customFieldsApi'
import { message } from 'antd'
import { useAppDispatch } from '@src/modules/shared/store'
import { setSocketEvent } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { useTranslation } from 'react-i18next'

const TaskRow = ({
  depth,
  addFakeTask,
  canOnlyView,
  data,
  list,
  toogleExpand,
  expand,
  arrow,
}: any) => {
  const [task, setTask] = useState<TaskType & { type?: string }>(data || {})
  const [updateTask] = useUpdateTaskMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const { t } = useTranslation(['tasks'])
  const [isOpenTask, setOpenTask] = useState<boolean>(false)
  const [createCustomFieldValue] = useCreateCustomFieldValueMutation()
  const [updateCustomFieldValue] = useUpdateCustomFieldValueMutation()
  const [deleteCustomFieldValue] = useDeleteCustomFieldValueMutation()
  const allowNested = depth < maxLevel
  const localUpdateTask = (label: string, value) => {
    if (canOnlyView) return
    setTask((prev) => ({ ...prev, [label]: value }))
    dispatch(
      setSocketEvent({
        updateType: 'updatedTask',
        updateEvent: { ...task, [label]: value },
      }),
    )
  }

  const openTaskDetails = useCallback(
    () => task?.id && navigate(updateURL({ label: 'taskId', value: task.id })),
    [navigate, task],
  )

  useEffect(() => setTask(data), [data])

  useEffect(() => {
    if (taskId === task?.id) setOpenTask(true)
    else setTimeout(() => setOpenTask(false), 450)
  }, [taskId])

  const handleTaskClick = useCallback(
    (e) => e.currentTarget === e.target && openTaskDetails(),
    [openTaskDetails],
  )

  const createCustomFieldValueHandler = async (fieldId: string, value: any) => {
    const newFieldValue = {
      value,
      customFieldId: fieldId,
    }

    const originalFieldsValues = task?.customFieldsValues || []

    localUpdateTask('customFieldsValues', [...originalFieldsValues, newFieldValue])

    try {
      const res = await createCustomFieldValue({
        value,
        task: {
          id: task.id,
        },
        customField: {
          id: fieldId,
        },
      }).unwrap()

      const { payload } = res || {}
      const updatedFieldsValues = [
        ...originalFieldsValues,
        {
          customFieldId: payload.customField?.id,
          value: payload.value,
          id: payload.id,
        },
      ]

      localUpdateTask('customFieldsValues', updatedFieldsValues)
    } catch (err) {
      localUpdateTask('customFieldsValues', originalFieldsValues)
      message.error(t('error-while-create-custom-field-value'))
    }
  }

  const updateCustomFieldValueHandler = async (fieldId: string, value: any) => {
    const originalFieldsValues = task?.customFieldsValues

    localUpdateTask(
      'customFieldsValues',
      originalFieldsValues.map((field) => (field.id === fieldId ? { ...field, value } : field)),
    )

    try {
      await updateCustomFieldValue({
        id: fieldId,
        value,
      }).unwrap()
    } catch (err) {
      localUpdateTask('customFieldsValues', originalFieldsValues)
      message.error(t('error-while-update-custom-field-value'))
    }
  }

  const deleteCustomFieldValueHandler = async (fieldId: string) => {
    const originalFieldsValues = task?.customFieldsValues

    localUpdateTask(
      'customFieldsValues',
      originalFieldsValues.filter((field) => field.id !== fieldId),
    )

    try {
      await deleteCustomFieldValue({
        id: fieldId,
      }).unwrap()
    } catch (err) {
      localUpdateTask('customFieldsValues', originalFieldsValues)
      message.error(t('error-while-delete-custom-field-value'))
    }
  }

  return (
    <RTLStyleDecorator>
      <div
        className={`task-list  ${isOpenTask ? 'opened-task' : ''}`}
        onClick={handleTaskClick}
        style={{ paddingLeft: depth * 29 + 'px' }}
      >
        <RTLStyleDecorator>
          <div className="col1" onClick={() => {}}>
            <span
              onClick={() => {
                if (allowNested) {
                  if (task?.totalSubtask === 0) addFakeTask(false, true)
                  toogleExpand()
                }
              }}
              className={`colapse-button ${task?.totalSubtask === 0 ? 'disable-arrow' : ''} ${
                allowNested ? '' : 'disable-icon'
              }`}
            >
              {arrow}
            </span>

            <TaskStatus
              disable={canOnlyView}
              activeStatus={task?.status}
              listId={list?.id}
              onSelect={(s) => {
                updateTask({
                  id: task.id,
                  statusId: s?.id,
                })
                dispatch(
                  setSocketEvent({
                    updateType: 'updatedTask',
                    updateEvent: { ...task, status: s },
                  }),
                )
                localUpdateTask('status', s)
              }}
              displayIcon={false}
              displayLabel={false}
            />
            <div className="task-row-wrapper">
              <div className="task-row-wrapper-content">
                <div className="task-list-inner">
                  <div onClick={openTaskDetails} className="task-list-inner-content">
                    <div className="task-name">
                      <TaskName data={task} disable={canOnlyView} />
                    </div>
                    <TaskDescriptionPreview task={task} />

                    <div className="subtask-container" onClick={(e) => e.stopPropagation()}>
                      {task?.totalSubtask > 0 ? (
                        <div className="subtask-icon-number">
                          <SubtaskIcon />
                          <span className="subtask-number">{task?.totalSubtask}</span>
                        </div>
                      ) : (
                        <div className="subtask-icon-number-hover">
                          <SubtaskIcon />
                        </div>
                      )}

                      {allowNested && (
                        <div
                          className="add-subtask-button"
                          onClick={() => {
                            addFakeTask(false, true)
                            expand()
                          }}
                        >
                          <AddSubtaskIcon />
                        </div>
                      )}
                    </div>

                    <div className="list-tags-container">
                      <Tags
                        tags={task?.tags}
                        spaceId={list?.spaceId}
                        listView={true}
                        taskId={task?.id}
                        CountOfTags={2}
                        maxOfTextLength={12}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RTLStyleDecorator>
        <ListColumns
          task={task}
          createCustomFieldValue={createCustomFieldValueHandler}
          updateCustomFieldValue={updateCustomFieldValueHandler}
          deleteCustomFieldValue={deleteCustomFieldValueHandler}
          openTaskDetails={openTaskDetails}
          canOnlyView={!!canOnlyView}
          localUpdateTask={localUpdateTask}
          list={list}
        />
      </div>
    </RTLStyleDecorator>
  )
}

export default memo(TaskRow)
