import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { setDeletedComment } from '@src/modules/document/data/commentSlice/commentSlice'
import {
  addReaction,
  deleteComment,
  getComment,
  updateComment,
} from '@src/modules/document/data/commentSlice/commentThunk'
import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import { Dropdown, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import check from '../../assets/icons/comment/check.svg'
import dots from '../../assets/icons/comment/dots.svg'
import edit from '../../assets/icons/comment/edit.svg'
import like from '../../assets/icons/comment/like.svg'
import likedIcon from '../../assets/icons/comment/liked.svg'
import share from '../../assets/icons/comment/share.svg'
import smile from '../../assets/icons/comment/smilee.svg'
import { useAppDispatch } from '../../store/index'
import BlockNoteRichText from '../BlockNoteRichText/BlockNoteRichText'
import { Avatar } from '../Avatar'

const Comment = ({ comment }) => {
  const { owner, commentId } = comment || {}
  const { t } = useTranslation(['document'])
  const dispatch = useAppDispatch()

  const [commentContent, setCommentContent] = useState(comment?.comment?.content || '')
  const [isLiked, setIsLiked] = useState<boolean>(false)
  const [emojis, setEmojis] = useState<any[]>([])
  const [isReplying, setIsReplying] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const editCommentHandler = () => {
    dispatch(
      updateComment({
        commentId: commentId,
        content: commentContent,
        resolved: comment?.comment?.resolved,
      }),
    )
      .unwrap()
      .then(() => {
        setIsEditing(false)
        dispatch(getComment({ commentId }))
          .unwrap()
          .then((res) => {
            setCommentContent(res?.payload?.content)
          })
      })
      .catch((error) => message.error(error.message))
  }

  const deleteCommentHandler = () => {
    dispatch(deleteComment({ commentId: comment?.commentId }))
      .unwrap()
      .then(() => {
        dispatch(setDeletedComment(comment?.comment?.id))
      })
      .catch((err) => message.error(err.message))
  }
  return (
    <div className="comment-card">
      <div className="comment-card_avatar">
        <Avatar
          text={owner?.name?.charAt(0) || owner?.fullName?.charAt(0) || owner?.email?.charAt(0)}
          image={owner?.avatar}
          color="#a9b6c0"
        />
      </div>

      <div className={`comment-card_content ${isEditing ? 'comment-content-edit' : ''}`}>
        <div className="comment-card_content-header">
          <p>
            {owner?.fullName} <span>{t('Commented')}</span>
          </p>

          <div className="icons">
            <Dropdown
              dropdownRender={() => (
                <div className="filter">
                  <span>{t('Mark as read')}</span>
                  <span>{t('Copy link')}</span>
                  <span onClick={() => deleteCommentHandler()}>{t('Delete')}</span>
                </div>
              )}
              trigger={['click']}
            >
              <img src={dots} />
            </Dropdown>

            <img src={edit} onClick={() => setIsEditing(true)} />

            <Dropdown
              dropdownRender={() => (
                <div className="filter">
                  <span>{t('Mark as read')}</span>
                  <span>{t('Copy link')}</span>
                </div>
              )}
              trigger={['click']}
            >
              <img src={check} />
            </Dropdown>
          </div>
        </div>

        <div className="comment-card_content-time">
          {reformulateDate(comment?.comment?.createdAt)}
        </div>

        <div className="comment-card_content-comment">
          <BlockNoteRichText
            value={commentContent}
            setValue={setCommentContent}
            onlyView={!isEditing}
          />
        </div>

        {isReplying && (
          <div className="comment-reply">
            <BlockNoteRichText value={''} />
            <div className="btns">
              <div className="send">{t('Cancel')}</div>
              <div className="send">{t('Send')}</div>
            </div>
          </div>
        )}

        <div className="comment-card_content-icons">
          <div className="interactions">
            {isLiked ? (
              <div className="liked" onClick={() => setIsLiked(false)}>
                <img src={likedIcon} />
                <span>{1}</span>
              </div>
            ) : (
              <img src={like} onClick={() => setIsLiked(true)} />
            )}

            {emojis.map((emoji) => (
              <span
                className="liked"
                onClick={() => {
                  const newEmojis = emojis.filter((em) => emoji.native !== em.native)
                  setEmojis(newEmojis)
                }}
              >
                {emoji?.native}
              </span>
            ))}

            <Dropdown
              dropdownRender={() => (
                <Picker
                  data={data}
                  categories={['frequent', 'people', 'nature', 'foods', 'activity']}
                  onEmojiSelect={(emoji) => {
                    setEmojis((prev) => [...prev, emoji])
                    dispatch(addReaction({ code: emoji.native, commentId }))
                  }}
                />
              )}
              trigger={['click']}
            >
              <img src={smile} />
            </Dropdown>
          </div>

          {isEditing ? (
            <div className="btns">
              <div className="btn cancel" onClick={() => setIsEditing(false)}>
                {t('Cancel')}
              </div>
              <div className="btn save" onClick={() => editCommentHandler()}>
                {t('Save')}
              </div>
            </div>
          ) : (
            <div className="share">
              <img src={share} className="share" onClick={() => setIsReplying(true)} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Comment
