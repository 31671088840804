import { ReactComponent as Plus2Icon } from './../assets/icons/spaceDropdown/plus.svg'
import { ReactComponent as RenameIcon } from './../assets/icons/spaceDropdown/update.svg'
import { ReactComponent as DeleteIcon } from './../assets/icons/spaceDropdown/delete.svg'
import { ReactComponent as ColorsIcon } from './../assets/icons/spaceDropdown/color&icons.svg'
import { ReactComponent as DocumentIcon } from '../../shared/assets/icons/document.svg'
// import { ReactComponent as DuplicateIcon } from './../assets/icons/spaceDropdown/duplicate.svg'
// import { ReactComponent as StartIcon } from './../assets/icons/spaceDropdown/start.svg'
// import { ReactComponent as OutIcon } from './../assets/icons/spaceDropdown/out.svg'
import { ReactComponent as ShareIcon } from './../assets/icons/spaceDropdown/share.svg'
import { ReactComponent as ManageStatusSVG } from './../assets/icons/spaceDropdown/manage-status.svg'
// import { ReactComponent as ArchiveIcon } from './../assets/icons/spaceDropdown/archive.svg'
import { ReactComponent as ListIcon } from './../assets/icons/list/list-icon.svg'
import { ReactComponent as FolderIcon } from './../assets/icons/list/folder-icon.svg'
import { MenuProps } from 'antd'
import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import { colors } from '@src/modules/auth/features/CreateWorkspace/CreateWorkspace'
import { store } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export enum PermissionesAction {
  FULL = 'all',
  NO_CREATE = 'C',
  NO_RENAME = 'R',
  NO_CHNAGE_COLOR = 'CHC',
  NO_CREATE_FOLDER = 'CF',
  NO_OUT_FROM_SPACE = 'OG',
  NO_CREATE_TASK = 'CT',
  NO_CREATE_LIST = 'CL',
  NO_CREATE_DOCUMENT = 'CD',
}

export const AddListItems = (data: any, permissions: string[], actions?: any) => {
  const { t } = useTranslation(['sidebar'])
  const navigate = useNavigate()
  const full = permissions && permissions[0] === PermissionesAction.FULL
  const createFolderPer = !permissions?.includes(PermissionesAction.NO_CREATE_FOLDER)
  const createTaskPer = !permissions.includes(PermissionesAction.NO_CREATE_TASK)
  const createListPer = !permissions.includes(PermissionesAction.NO_CREATE_LIST)
  const createDocumentPer = !permissions.includes(PermissionesAction.NO_CREATE_DOCUMENT)

  const taskRow =
    data?.resource === 'list' && !!createTaskPer
      ? {
          key: '1-4',
          label: t('space_settings.task'),
          icon: <ListIcon />,
          access: data?.resource === 'list' && !!createTaskPer,
          onClick: () =>
            store.dispatch(openModal({ id: 'create-task-modal', data: { list: data } })),
          requiredPermission: { action: 'create', resource: 'list' },
        }
      : {
          type: 'divider',
          access: true,
        }

  return manageDropdownPermissions(
    [
      {
        key: '1-1',
        label: t('space_settings.list'),
        icon: <ListIcon />,
        access: !!createListPer,
        onClick: () => actions.oncreatelist(data),
        requiredPermission: { action: 'create', resource: 'list' },
      },

      {
        key: '1-3',
        label: t('space_settings.document'),
        icon: <DocumentIcon />,
        access: !!createDocumentPer,
        onClick: () => actions.oncreatedocument(data, navigate),
        requiredPermission: { action: 'create', resource: 'document' },
      },

      taskRow,

      {
        key: '1-2',
        label: t('space_settings.folder'),
        icon: <FolderIcon />,
        access: !!createFolderPer,
        onClick: () => actions.oncreatefolder(data),
        requiredPermission: { action: 'create', resource: 'folders' },
      },
    ],
    full,
    data,
  )
}

export const items = (data: any, permissionses: string[], actions?: any) => {
  const { t } = useTranslation(['sidebar'])
  const fullAccess = permissionses[0] === PermissionesAction.FULL
  // const createPer = !permissionses?.includes(PermissionesAction.NO_CREATE)
  // const outFromSpacePer = !permissionses?.includes(PermissionesAction.NO_OUT_FROM_SPACE)
  const isSpace = !data?.folderId && !data?.spaceId
  const isDocument = data.resource === 'document'

  return manageDropdownPermissions(
    [
      {
        key: '1',
        label: t('space_settings.create'),
        icon: <Plus2Icon />,
        access: !isDocument,
        children: AddListItems(data, permissionses, actions),
        requiredPermission: { action: 'create', resource: data.resource },
      },
      {
        key: '2',
        label: t('space_settings.rename'),
        access: true,
        icon: <RenameIcon />,
        onClick: () => actions.onrename(data),
        requiredPermission: { action: 'update', resource: data.resource },
      },
      {
        key: '3',
        label: t('space_settings.color_icons'),
        access: !isDocument,
        icon: <ColorsIcon />,
        onClick: () => isSpace && actions.onchangeiconcolor(data),
        children: !isSpace && [
          {
            key: '3-1',
            style: { width: '180px', overflow: 'hidden' },
            label: (
              <div className="color-peacker-dropdown" onClick={(e) => e.stopPropagation()}>
                <ColorSelect
                  colors={colors}
                  selectedColor={data.color}
                  setSelectedColor={(c) => actions.onchangecolor(data, c)}
                />
              </div>
            ),
          },
        ],
        requiredPermission: { action: 'update', resource: data.resource },
      },
      {
        key: '12',
        label: t('space_settings.Space settings'),
        access: isSpace,
        icon: <ManageStatusSVG />,
        onClick: () => isSpace && actions.onspacesetting(data),
        // requiredPermission: { action: 'update', resource: data.resource },
      },
      // {
      //   key: '4',
      //   icon: <DocumentIcon />,
      //   access: true,
      //   label: 'Copy link',
      // },
      // {
      //   key: '5',
      //   icon: <DuplicateIcon />,
      //   access: true,
      //   label: 'Duplicate',
      //   // requiredPermission: { action: 'deplicate', resource: data.resource },
      // },
      // {
      //   type: 'divider',
      //   access: true,
      // },
      // {
      //   key: '6',
      //   icon: <StartIcon />,
      //   access: true,
      //   label: 'Add favoris',
      //   // requiredPermission: { action: 'favoris', resource: data.resource },
      // },
      // {
      //   key: '7',
      //   icon: <OutIcon />,
      //   label: 'Quitter l`espace',
      //   access: !!outFromSpacePer,
      //   // requiredPermission: { action: 'update', resource: data.resource },
      // },
      {
        type: 'divider',
        access: true,
      },
      {
        key: '8',
        icon: <ManageStatusSVG />,
        label: t('space_settings.update_status'),
        access: !isDocument,
        onClick: () => actions.onupdatestatuses && actions.onupdatestatuses(data),
        // requiredPermission: { action: 'share', resource: data.resource },
      },
      {
        key: '9',
        icon: <ShareIcon />,
        label: t('space_settings.sharing_permissions'),
        access: true,
        onClick: () => actions.onshare && actions.onshare(data),
        // requiredPermission: { action: 'share', resource: data.resource },
      },
      // {
      //   key: '10',
      //   icon: <ArchiveIcon />,
      //   access: true,
      //   label: 'Archive',
      //   // requiredPermission: { action: 'archive', resource: data.resource },
      // },
      {
        key: '11',
        icon: <DeleteIcon />,
        danger: true,
        access: true,
        label: t('space_settings.delete'),
        onClick: () => actions.ondelete(data),
        requiredPermission: { action: 'delete', resource: data.resource },
      },
    ],
    fullAccess,
    data,
  )
}
interface MenuItemType {
  access?: boolean
  key?: string
  type?: string
  disabled?: boolean
  icon?: JSX.Element
  label?: string
  danger?: boolean
  onClick?: any
  children?: any
  requiredPermission?: { action: string; resource: string }
}

// this function we wil use it in all the dropdowns if we need to manage permissions
export const manageDropdownPermissions = (
  allItems: MenuItemType[],
  fullAccess: boolean,
  data?: any,
) => {
  const { permissions: userPermissions } = store.getState().permissions
  let { user } = store.getState().auth
  user = user?.user || user

  return allItems?.filter((item: MenuItemType) => {
    const globalAccess = item.requiredPermission
      ? userPermissions.can(item.requiredPermission.action, item.requiredPermission.resource)
      : true
    const localAccess = item.access || fullAccess
    const itemPermissions = getItemPermissions(data)
    if (itemPermissions?.length > 0 && item?.requiredPermission) {
      const [fisrtPermission] = itemPermissions
      if (fisrtPermission?.userId === user?.id) {
        const shareActions = fisrtPermission?.role?.permissions?.find(
          (per: { resourceName: string }) =>
            per?.resourceName === item?.requiredPermission?.resource,
        )
        const haveAction = shareActions?.actions?.find(
          (action: string) => action.toLowerCase() === item.requiredPermission.action,
        )
        if (!haveAction) return false
        return (
          (localAccess && data?.ownerId === user?.id) ||
          userPermissions.can(item.requiredPermission.action, item.requiredPermission.resource)
        )
      }
    }

    return !localAccess ? false : item.requiredPermission ? globalAccess : localAccess
  }) as MenuProps['items']
}

const getItemPermissions = (item: {
  permissions: string | any[]
  parent: { permissions: string | any[] }
}) => {
  if (item?.permissions?.length > 0) {
    return item.permissions
  } else if (item?.parent?.permissions?.length > 0) {
    return item?.parent?.permissions
  } else return null
}
