import { Dropdown, Input, Tooltip, message } from 'antd'
import { ReactComponent as SettingsIcon } from './../../assets/icons/list/settings.svg'
import { PermissionesAction, items } from '../../utils/spaceActions'
import { store, useAppDispatch } from '@src/modules/shared/store'
import { updateList } from '../../data/fileSlice/fileThunk'
import { manageFiles, setSpacesSocketEvent, spaceType } from '../../data/spaceSlice/spacesSlice'
import { useState } from 'react'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { NavLink, useParams } from 'react-router-dom'
import { statusType } from '../../data/statusSlice/statusSlice'
import { ReactComponent as LockIcon } from './../../assets/icons/space/lock.svg'
import { FolderType } from '../Folder/Folder'
import { detectLanguage } from '../../utils/detectLanguage'
import { IList } from '@softylines/tam-types'

export let listActions = {
  onrename: () => {},
  onupdatestatuses: (data) => {
    store.dispatch(
      openModal({ id: 'update-status-modal', data: { ...data, list: data, type: 'fileId' } }),
    )
  },

  onchangecolor: (data: ListType & { destination: string }, color: string) => {
    store.dispatch(
      setSpacesSocketEvent({ updateType: 'updatedItem', updateEvent: { ...data, color } }),
    )
    store
      .dispatch(
        updateList({
          id: data?.id,
          color,
          name: data?.name,
        }),
      )
      .unwrap()
      .then(() => {
        store.dispatch(
          manageFiles({
            targetId: data?.id,
            id: data?.folderId ? data.folderId : data.spaceId,
            newObj: { ...data, color },
            type: 'update',
            parent: data?.folderId ? 'folder' : 'space',
            targetData: 'lists',
            group: data?.destination,
          }),
        )
      })
      .catch((err) => {
        store.dispatch(setSpacesSocketEvent({ updateType: 'updatedItem', updateEvent: data }))
        message.error(err.message || 'Error while update list color')
      })
  },
  onshare: (data: ListType & { destination: string }) => {
    store.dispatch(openModal({ id: 'share-modal', data: { ...data, type: 'fileId' } }))
  },
  ondelete: (data: ListType & { destination: string }) => {
    store.dispatch(
      openModal({
        id: 'confirm-modal',
        data: { source: 'list', ...data, group: data.destination },
      }),
    )
  },
}
export interface ListType extends IList {
  id: string
  refetch?: () => void
  status: statusType[]
  genericView: any
}

interface ListProps {
  data: ListType & { destination?: string }
  level: number
  parent: FolderType | spaceType
}

const List: React.FC<ListProps> = ({ data, level, parent }) => {
  const { listId = '' } = useParams()
  const [isRename, setRename] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const saveUpdate = (e: any) => {
    const value = e.target.value
    if (!value.trim()) {
      message.error('Invalid name')
      setRename(false)
    } else if (value !== data.name) {
      dispatch(updateList({ id: data.id, name: value }))
        .unwrap()
        .then(() => {
          dispatch(
            manageFiles({
              targetId: data?.id,
              id: data?.folderId ? data.folderId : data?.spaceId,
              newObj: { ...data, name: value },
              type: 'update',
              parent: data?.folderId ? 'folder' : 'space',
              targetData: 'lists',
              group: data.destination,
            }),
          )
        })
        .catch((err) => {
          message.error(err.message || 'Error while update list name')
        })
        .finally(() => setRename(false))
    } else setRename(false)
  }
  const isActive = listId === data.id
  listActions = { ...listActions, onrename: () => setRename(!isRename) }

  return (
    <div className={`list-item level-${level} ${isActive ? 'active-list' : ''} shared-list`}>
      <NavLink to={`/spaces/list/${data.id}`} state={data}>
        <div className="list-item-icon">
          <ListIcon color={data?.color} />
        </div>

        <div
          className="list-item-name"
          style={
            detectLanguage(data?.name) === 'arabic' ? { direction: 'rtl' } : { direction: 'ltr' }
          }
        >
          {!isRename ? (
            <Tooltip title={data.name}>{data.name}</Tooltip>
          ) : (
            <Input
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className="sidebar-rename-input"
              defaultValue={data?.name || ''}
              onBlur={saveUpdate}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  saveUpdate(e)
                } else if (e.key === 'Escape') {
                  setRename(false)
                }
              }}
              autoFocus
            />
          )}
        </div>

        {data.isPrivate ? <LockIcon className="space-lock-icon" /> : null}
        {/* <div className="list-item-tasks-nb">19</div> */}
      </NavLink>

      <Dropdown
        menu={{
          items: items(
            { ...data, resource: 'list', parent },
            [
              PermissionesAction.NO_CREATE,
              PermissionesAction.NO_OUT_FROM_SPACE,
              PermissionesAction.NO_CREATE_FOLDER,
              PermissionesAction.NO_CREATE_LIST,
              PermissionesAction.NO_CREATE_DOCUMENT,
            ],
            listActions,
          ),
        }}
        trigger={['click']}
      >
        <div className="list-item-settings">
          <SettingsIcon />
        </div>
      </Dropdown>
    </div>
  )
}

export default List

export const ListIcon = ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
      <path
        d="M5.95829 9.33333H1.37496C1.2534 9.33333 1.13682 9.39479 1.05087 9.50419C0.964915 9.61358 0.916626 9.76196 0.916626 9.91667C0.916626 10.0714 0.964915 10.2197 1.05087 10.3291C1.13682 10.4385 1.2534 10.5 1.37496 10.5H5.95829C6.07985 10.5 6.19643 10.4385 6.28238 10.3291C6.36834 10.2197 6.41663 10.0714 6.41663 9.91667C6.41663 9.76196 6.36834 9.61358 6.28238 9.50419C6.19643 9.39479 6.07985 9.33333 5.95829 9.33333ZM1.37496 4.66667H9.62496C9.74652 4.66667 9.8631 4.60521 9.94905 4.49581C10.035 4.38642 10.0833 4.23804 10.0833 4.08333C10.0833 3.92862 10.035 3.78025 9.94905 3.67085C9.8631 3.56146 9.74652 3.5 9.62496 3.5H1.37496C1.2534 3.5 1.13682 3.56146 1.05087 3.67085C0.964915 3.78025 0.916626 3.92862 0.916626 4.08333C0.916626 4.23804 0.964915 4.38642 1.05087 4.49581C1.13682 4.60521 1.2534 4.66667 1.37496 4.66667ZM9.62496 6.41667H1.37496C1.2534 6.41667 1.13682 6.47812 1.05087 6.58752C0.964915 6.69692 0.916626 6.84529 0.916626 7C0.916626 7.15471 0.964915 7.30308 1.05087 7.41248C1.13682 7.52187 1.2534 7.58333 1.37496 7.58333H9.62496C9.74652 7.58333 9.8631 7.52187 9.94905 7.41248C10.035 7.30308 10.0833 7.15471 10.0833 7C10.0833 6.84529 10.035 6.69692 9.94905 6.58752C9.8631 6.47812 9.74652 6.41667 9.62496 6.41667Z"
        fill={color || '#4D4D4D'}
      />
    </svg>
  )
}
