import { SVGProps } from 'react'

type Props = {
  fill: string
}

function TimerClockSVG(props: SVGProps<Props & SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7822 22.0685C6.87425 22.0685 2.88419 18.0785 2.88419 13.1705C2.88419 8.26253 6.87425 4.27246 11.7822 4.27246C16.6902 4.27246 20.6803 8.26253 20.6803 13.1705C20.6803 18.0785 16.6902 22.0685 11.7822 22.0685ZM11.7822 5.67741C7.65166 5.67741 4.28914 9.03994 4.28914 13.1705C4.28914 17.3011 7.65166 20.6636 11.7822 20.6636C15.9128 20.6636 19.2753 17.3011 19.2753 13.1705C19.2753 9.03994 15.9128 5.67741 11.7822 5.67741Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M11.7822 13.6389C11.3982 13.6389 11.0797 13.3205 11.0797 12.9364V8.25326C11.0797 7.86924 11.3982 7.55078 11.7822 7.55078C12.1662 7.55078 12.4847 7.86924 12.4847 8.25326V12.9364C12.4847 13.3205 12.1662 13.6389 11.7822 13.6389Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M14.5921 3.33611H8.97231C8.58829 3.33611 8.26984 3.01765 8.26984 2.63363C8.26984 2.24961 8.58829 1.93115 8.97231 1.93115H14.5921C14.9761 1.93115 15.2946 2.24961 15.2946 2.63363C15.2946 3.01765 14.9761 3.33611 14.5921 3.33611Z"
        fill={props.fill || 'white'}
      />
    </svg>
  )
}

export default TimerClockSVG
