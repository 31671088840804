import Button from '@src/modules/shared/components/Button/Button'
import { ReactComponent as CloseIcon } from '../../../assets/icons/task/close-add-task.svg'
import { useRef, useState } from 'react'
import { useAddTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { useParams } from 'react-router-dom'
import { message } from 'antd'
import { useAppDispatch } from '@src/modules/shared/store'
import { setSocketEvent } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { useTranslation } from 'react-i18next'
import TaskUsers from '../../TaskUsers'
import Calendar from '../../Calendar'
import TaskPriority from '../../TaskPriority'
import { useGetStatusesByListQuery } from '@src/modules/spaces/services/fileApi'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import { getDefaultValue } from '../../Board/AddTask/AddTask'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import Input from '@src/modules/shared/components/Input/Input'

export const getTaskfield = (groupBy) => {
  if (groupBy?.type === GroupByFields.STATUS) {
    return 'statusId'
  } else if (groupBy?.custom) {
    return 'customFieldsValues'
  } else return groupBy?.type
}

const defaultGroupBy = {
  type: GroupByFields.STATUS,
}

const AddSubTaskInput = ({
  parentId,
  removeFakeTask,
  depth,
  data,
  isBottom,
  depthPadding = 61,
  groupBy = defaultGroupBy,
}) => {
  const divRef = useRef(null)
  const { t } = useTranslation(['tasks'])
  const draftTask = JSON.parse(localStorage.getItem('draftTask') || '{}') as TaskType
  groupBy = groupBy || defaultGroupBy

  const defaultTaskData = {
    [getTaskfield(groupBy)]: getDefaultValue(data, groupBy),
  }
  const [newTask, setTask] = useState<any>(defaultTaskData)
  const [addTask, { isLoading }] = useAddTaskMutation()
  const handleInputChange = (event) => {
    setValueTask('name', event.target.value)
    localStorage.setItem('draftTask', JSON.stringify({ name: event.target.value }))
  }
  const params = useParams()
  const fileId = params?.listId
  const setValueTask = (label: string, value) => {
    setTask((prev) => ({ ...prev, [label]: value }))
  }
  const { data: statuses } = useGetStatusesByListQuery({ listId: fileId }, { skip: !fileId })

  const defaultStatus = statuses?.[0]
  const dispatch = useAppDispatch()

  const removeFakeTaskHandler = () => {
    localStorage.removeItem('draftTask')
    removeFakeTask()
  }

  const addTaskHandler = () => {
    if (newTask?.name?.trim() && !isLoading) {
      const { users } = newTask
      const teams = users?.filter((user: any) => user?.name !== undefined) || null

      addTask({
        statusId: defaultStatus?.id,
        ...newTask,
        users: users?.map((user) => user?.id).filter((userId) => !!userId),
        teams: teams,
        fileId,
        parentId,
        isBottom,
      } as any)
        .unwrap()
        .then((res) => {
          setTask(defaultTaskData)
          localStorage.removeItem('draftTask')
          dispatch(setSocketEvent({ updateType: 'createdTask', updateEvent: res?.payload }))
        })
        .catch((error) => {
          message.error(error?.message || 'Error while creating a task')
        })
    } else {
      message.error(t('list_view.whitespace_error'))
    }
  }

  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') addTaskHandler()
    else if (e.key === 'Escape' && !isLoading) removeFakeTaskHandler()
  }

  const focusOut = () => {
    if (newTask?.name?.trim() && !isLoading) addTaskHandler()
    else !isLoading && removeFakeTaskHandler()
  }

  return (
    <>
      <div className="overlay-dropdown" onClick={focusOut}></div>

      <RTLStyleDecorator>
        <div>
          <div
            className="subTask-input-container"
            style={{ paddingLeft: depth * 29 + depthPadding + 'px' }}
            onKeyDown={keyDownHandler}
            ref={divRef}
            tabIndex={0}
          >
            <div className="col1" style={{ left: depth * 29 + depthPadding + 'px' }}>
              {
                <div
                  className="status-color"
                  style={{
                    background: data?.color || defaultStatus?.color,
                    width: '12px',
                    height: '12px',
                    borderRadius: '50px',
                  }}
                ></div>
              }

              <Input
                placeholder={t('list_view.type_task_name')}
                containerClassName="subtask-input"
                value={newTask?.name || draftTask?.name || ''}
                autoFocus
                onChange={handleInputChange}
                variant="label-inline"
              />
            </div>

            <div className="col2">
              <div className="col2-task-options">
                <TaskUsers
                  displayAddButton={!newTask?.users?.length}
                  userNumber={3}
                  members={newTask?.users || []}
                  onClick={(e) => e.stopPropagation()}
                  setAssignedMembersList={(m) => {
                    setValueTask('users', m)
                  }}
                />

                <Calendar
                  defEnd={newTask?.dueDate}
                  defStart={newTask?.dueDate}
                  setStart={(date) => {
                    setValueTask('startDate', date)
                  }}
                  setEnd={(date) => {
                    setValueTask('dueDate', date)
                  }}
                  onClick={(e) => e.stopPropagation()}
                  isInTaskList={true}
                />

                <TaskPriority
                  showLabel={false}
                  activePriorityCode={newTask?.priority}
                  onSelect={(p) => setValueTask('priority', p)}
                />
              </div>

              <div className="save-subtask-btn">
                <Button
                  children={isLoading ? t('list_view.loading') : t('list_view.save')}
                  className="add-subtask-btn"
                  onClick={addTaskHandler}
                />
              </div>
              <div className="close-icon" onClick={removeFakeTaskHandler}>
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      </RTLStyleDecorator>
    </>
  )
}

export default AddSubTaskInput
